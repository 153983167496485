import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ItemService} from '../services/items.service';
import {IComic} from '../interfaces/IComic';
import {CarouselsService} from '../services/carousels.service';
import {ICarousel} from '../interfaces/ICarousel';

@Injectable({
  providedIn: 'root'
})
export class HomeResolver {

  HOOK_ID = '59f78159-f1b4-410f-8a49-38e4bd5f18dd'; // home hook id

  constructor(
    private itemsService: ItemService,
    private carouselsService: CarouselsService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<IComic | ICarousel[]> {
    if (route.queryParams?.comic) {
      return this.itemsService.comic(route.queryParams?.comic);
    } else {
      return this.carouselsService.carouselsByHook(this.HOOK_ID);
    }
  }
}
