<div class="bg-hero-box" *ngIf="comic">
  <div class="overlay" [ngStyle]="{'background-image': 'url(' + (comic | featureHorizontalCover) + ')'}"></div>
  <div class="radial"></div>
  <div class="container-fluid container-large">
    <div class="hero-box row">
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 position-relative">
        <div class="hero-info">
          <img class="hero-logo  animate__animated animate__fadeIn"
               *ngIf="comic.logo" alt="" [src]="ui.mediaLogoUrl(comic)"/>
          <h2 class="text-34 bolder white-text" *ngIf="!comic.logo">{{comic.title}}</h2>
          <p class="text-truncate mt-2 mb-2 fw-300">
            <span *ngFor="let chip of comic.genres; let i = index;">
                <span *ngIf="i > 0" class="yellow-text">&nbsp;&nbsp;•&nbsp;&nbsp;</span>
                <span class="white-text">{{chip.label}}</span>
            </span>
          </p>
          <p class="text-14 mt-30 pr-15 w-75" *ngIf="comic.introtext"
             [innerHTML]="(convertTag(comic.introtext) | slice:0:210) + '...'"></p>
          <div class="row mt-30" style="max-width: 480px">
            <div class="col-6" *ngIf="deviceService.isDesktop()">
              <button class="btn btn-jundo text-18 w-100" style="height: 48px;" (click)="continueReading()">
                Inizia a leggere
              </button>
            </div>
            <div class="col-3 text-center">
              <div class="pointer" (click)="toggleFavorite(comic.id)">
                <img alt="" [src]="isFavorite ? 'assets/icon/v2/bookmark-on.svg' : 'assets/icon/v2/bookmark-off.svg'"/>
                <p class="mt-6 fw-400 text-14 pointer">Collezione</p>
              </div>
            </div>
            <div class="col-3 text-center" *ngIf="comic">
              <div class="pointer" (click)="ui.openModalComic(comic.id)">
                <img alt="" [src]="'assets/icon/jundo/info.svg'">
                <p class="mt-6 fw-400 text-14 pointer">Info</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

