import {Component, Input, OnInit} from '@angular/core';
import {IProduct} from '../../interfaces/IProduct';
import {UiService} from '../../services/ui.service';
import {CartService} from '../../services/cart.service';
import {CategoryNameConstant} from '../../constants/category-name.constant';

@Component({
  selector: 'app-featured-product',
  templateUrl: './featured-product.component.html',
  styleUrls: ['./featured-product.component.scss'],
})
export class FeaturedProductComponent implements OnInit {

  @Input() product: IProduct;
  @Input() title: string;

  backgroundUrl = '/assets/images/sfondo.jpg';
  categoryNameConstants = CategoryNameConstant;

  constructor(
    public ui: UiService,
    private cartService: CartService
  ) {
  }

  ngOnInit() {
    if (this.product && this.product.images && this.product.images.length) {
      this.backgroundUrl = this.product.images[0].src;
    }
  }


  addToCart(product: IProduct) {
    this.cartService.addShoppingItem({product, qty: 1});
    this.ui.toast('Aggiunta 1 copia nella Shopping Bag');
  }
}
