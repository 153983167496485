import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import { Observable } from 'rxjs';
import { IJundoPurchase } from '../interfaces/IJundoPurchase';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

  url: string;

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.url = environment.apiUrl;
  }

  purchaseByCoins(body) {
    return this.http.post(this.url + 'v2/purchase/coins', body, this.getOptions());
  }

  purchaseRequest(body) {
    return this.http.post(this.url + 'v2/purchase-request', body, this.getOptions());
  }

  applyCoupon(body): Observable<IJundoPurchase> {
    return this.http.post<IJundoPurchase>(this.url + 'v2/purchase/coupon', body, this.getOptions());
  }

  validityCoupon(body): Observable<IJundoPurchase> {
    return this.http.post<IJundoPurchase>(this.url + 'v2/purchase/couponValidity', body, this.getOptions());
  }

  getOptions() {
    return {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.access_token.value),
    };
  }
}
