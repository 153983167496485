<div class="row" *ngIf="auth.user.value">
  <div class="col-12 mt-30">
    <h3 class="fw-600 text-18 bolder">Metodo di pagamento</h3>
  </div>
  <div class="col-12 mt-10">
    <p class="grey-text"
       *ngIf="auth.user.value.jundo_user.stripe_customer_default_method === pmConstant.OTHERS">
      Nessun metodo di pagamento salvato.&nbsp;
      <a [routerLink]="editUrl"
         [queryParams]="package ? {product: package.id} : {}"
         class="yellow-text no-decoration">Modifica metodo di pagamento</a>
    </p>
    <app-alert
      *ngIf="auth.user.value.jundo_user.stripe_customer_default_method && auth.user.value.jundo_user.stripe_customer_default_method !== pmConstant.OTHERS"
      [edit]="true"
      (on-edit)="editPayment()"
      [sourceImg]="paymentMethod | pmLogo"
      [title]="paymentMethod | pmTitle"
      [message]="paymentMethod | pmMessage">
    </app-alert>
  </div>
</div>
