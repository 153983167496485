import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {ICarousel} from '../interfaces/ICarousel';


@Injectable({
  providedIn: 'root'
})
export class CarouselsService {


  carouselsData: BehaviorSubject<ICarousel[]> = new BehaviorSubject<ICarousel[]>([]);

  url: string;

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.url = environment.apiUrl + 'admin/';
  }

  hooks(): Observable<any> {
    return this.http.get(this.url + 'hooks', this.getOptions());
  }

  hook(id: string): Observable<any> {
    return this.http.get(this.url + 'hooks/' + id, this.getOptions());
  }

  hooksReorder(orderList: any): Observable<any> {
    return this.http.patch(this.url + 'carouselsOrder', orderList, this.getOptions());
  }

  carousels(id: string): Observable<any> {
    return this.http.get(this.url + 'carousels/' + id);
  }

  carouselsByHook(id: string): Observable<ICarousel[]> {
    return this.http.get<ICarousel[]>(environment.apiUrl + 'v3/carouselsHook/' + id);
  }

  carouselsProfilation(): Observable<ICarousel[]> {
    return this.http.get<ICarousel[]>(environment.apiUrl + 'v3/carouselsProfilation', this.getOptions());
  }

  updateCarousels(id: string, body: ICarousel): Observable<any> {
    return this.http.put(this.url + 'carousels/' + id, body, this.getOptions());
  }

  createCarousels(body: ICarousel): Observable<any> {
    return this.http.post(this.url + 'carousels', body, this.getOptions());
  }

  removeCarousels(id: string): Observable<any> {
    return this.http.delete(this.url + 'carousels/' + id, this.getOptions());
  }

  createCarouselItem(id: string, body): Observable<any> {
    return this.http.put(this.url + 'createCarouselItem/' + id, body, this.getOptions());
  }

  deleteCarouselItem(id: string): Observable<any> {
    return this.http.delete(this.url + 'deleteCarouselItem/' + id, this.getOptions());
  }

  allComics(): Observable<any> {
    return this.http.get(this.url + 'allComics', this.getOptions());
  }

  comics(id: number): Observable<any> {
    return this.http.get(this.url + 'comic/' + id, this.getOptions());
  }

  updateComic(id: number, body): Observable<any> {
    return this.http.put(this.url + 'comic/' + id, body, this.getOptions());
  }

  deleteCoverType(id: number, body): Observable<any> {
    return this.http.put(this.url + 'deleteCoverType/' + id, body, this.getOptions());
  }

  getOptions() {
    return {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.access_token.value),
    };
  }
}
