import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IPackage} from '../../interfaces/IPackage';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-package-card',
  templateUrl: './package-card.component.html',
  styleUrls: ['./package-card.component.scss'],
})
export class PackageCardComponent {

  @Output('on-select') onSelect: EventEmitter<IPackage> = new EventEmitter<IPackage>();

  @Input() package: IPackage;
  @Input() selected = false;

  environment: any = environment;

  constructor() {
  }

  selectPackage() {
    this.onSelect.emit(this.package);
  }

}
