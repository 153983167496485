import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import * as $ from 'jquery';
import {CartService} from '../../services/cart.service';
import {UiService} from '../../services/ui.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {

  @Input() redirect: string = '/';
  @Output() onDrawer: EventEmitter<any> = new EventEmitter();
  @Output() openCart: EventEmitter<any> = new EventEmitter();

  isMenuCollapsed = true;
  isHome: boolean;
  isShop: boolean;
  isAccount: boolean;
  isDashboard: boolean;
  isReader: boolean;
  isComic: boolean;
  isLogin: boolean;
  isSubscription: boolean;
  isPurchase: boolean;
  isCheckout: boolean;

  environment = environment;

  isMobile: boolean;

  menu = [
    {label: 'Lettura digitale', auth: false, path: '/home'},
    {label: 'Shop online', auth: false, path: '/shop'},
    {label: 'Prossime uscite', auth: false, path: '/soon'},
    {label: 'La mia collezione', auth: true, path: '/library'}
  ];

  constructor(
    public auth: AuthService,
    private router: Router,
    public cartService: CartService,
    public ui: UiService
  ) {

    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const url = event.url;
      this.isHome = url.search('home') > -1 || url === '/' || url === '';
      this.isDashboard = url.search('dashboard') > -1;
      this.isReader = url.search('reader') > -1;
      this.isComic = url.search('comic') > -1;
      this.isLogin = url.search('access') > -1;
      this.isAccount =
        url.search('account') > -1 ||
        url.search('overview') > -1;
      this.isCheckout =
        url.search('checkout/shipping') > -1 ||
        url.search('checkout/method') > -1 ||
        url.search('checkout/success') > -1;
      this.isSubscription =
        (url.search('subscription') > -1 && url.search('account/subscription') < 0) ||
        url.search('subscription/summary') > -1 ||
        url.search('subscription/method') > -1 ||
        url.search('subscription/success') > -1;
      this.isPurchase =
        url.search('purchase') > -1 ||
        url.search('purchase/summary') > -1 ||
        url.search('purchase/method') > -1 ||
        url.search('purchase/success') > -1;
      this.isShop =
        url.search('/shop') > -1 &&
        url.search('/cart') < 0 &&
        url.search('/access') < 0 &&
        url.search('/shop/shipping') < 0 &&
        url.search('/shop/checkout') < 0;
      this.isMenuCollapsed = true;
      this.reloadJq();
    });
  }

  reloadJq() {
    if (this.ui.isBrowser()) {
      this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      $(() => {

        const navbar = $('.navbar');
        const main = $('.main');
        const topBarHeight = 0;
        // const navbarHeight = navbar.height();

        let marginTop = 64;

        if (this.isReader || this.isLogin || this.isSubscription || this.isCheckout || this.isPurchase || this.isHome) {
          marginTop = 0;
        }

        main.css('margin-top', marginTop + 'px');
        navbar.css('height', 'inherit');

        $(window).bind('scroll', () => {
          if ($(window).scrollTop() > topBarHeight) {
            // navbar.addClass('fixed-top');
            // navbar.css('box-shadow', '0px 5px 23px -6px rgba(0,0,0,0.33)');
            // main.css('margin-top', (navbarHeight) + 'px');
          } else {
            // navbar.removeClass('fixed-top');
            // navbar.css('box-shadow', 'none');
            // main.css('margin-top', '0px');
          }
        });
      });
    }
  }

  toggleDrawer() {
    this.onDrawer.emit();
  }

  logout() {
    this.auth.logout();
  }

  access(page = 'signin') {
    this.router.navigate(['/access'], {
      queryParams: {
        page,
        // return_url: this.auth.redirectSubjectUrl.value
      }
    });
  }

}
