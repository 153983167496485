<div *ngFor="let carousel of carouselsService.carouselsData.value; trackBy: trackCarousel;"
     [ngClass]="carousel.draft ? 'draft' : ''">

  <app-hero *ngIf="carousel['view_type'] === carouselType.HERO && carousel.comics.length"
            [carousel]="carousel"></app-hero>

  <app-comic-slider
    *ngIf="carousel['view_type'] === carouselType.STANDARD && carousel.comics.length"
    [soon]="carousel.soon"
    [carousel]="carousel"
    [required]="carousel.required"
  ></app-comic-slider>

  <app-comic-slider-bigger
    *ngIf="carousel['view_type'] === carouselType.BIGGER && carousel.comics.length"
    [carousel]="carousel"
    [soon]="carousel.soon"
  ></app-comic-slider-bigger>

  <app-comic-slider-numbered
    *ngIf="carousel['view_type'] === carouselType.NUMBERED && carousel.comics.length"
    [title]="carousel.title"
    [hex]="carousel.hex"
    [data]="carousel.comics"
    [soon]="carousel.soon"
    [carousel]="carousel"
  ></app-comic-slider-numbered>

  <app-comic-slider-horizontal
    *ngIf="carousel['view_type'] === carouselType.HORIZONTAL && carousel.comics.length"
    [title]="carousel.title"
    [hex]="carousel.hex"
    [data]="carousel.comics"
    [soon]="carousel.soon"
    [carousel]="carousel"
  ></app-comic-slider-horizontal>

  <app-comic-slider-numbered
    *ngIf="carousel['view_type'] === carouselType.RANKING && carousel.comics.length"
    [title]="carousel.title"
    [hex]="carousel.hex"
    [data]="carousel.comics"
    [soon]="carousel.soon"
    [carousel]="carousel"
  ></app-comic-slider-numbered>

  <app-comic-slider
    *ngIf="carousel['view_type'] === carouselType.MULTI && carousel.comics.length"
    [carousel]="carousel"
    [soon]="carousel.soon"
    [required]="carousel.required"
  ></app-comic-slider>

  <app-comic-slider-overlay
    *ngIf="carousel['view_type'] === carouselType.OVERLAY && carousel.comics.length"
    [carousel]="carousel"
    [soon]="carousel.soon"
  ></app-comic-slider-overlay>

  <!--<app-ranking *ngIf="carousel['view_type'] === carouselType.RANKING"
               [title]="carousel.title"
               [data]="carousel.comics"></app-ranking>

  <app-multi-ranking *ngIf="carousel['view_type'] === carouselType.MULTI"
                     [title]="carousel.title"
                     [data]="carousel.comics"></app-multi-ranking>-->

</div>
