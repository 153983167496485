import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import {IPaymentMethod} from '../interfaces/IPaymentMethod';
import {Observable} from 'rxjs';
import {PaymentMethod} from '@stripe/stripe-js';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService {

  url: string;

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.url = environment.apiUrl;
  }

  paymentMethods(): Observable<PaymentMethod[]> {
    return this.http.get<PaymentMethod[]>(this.url + 'v2/payment-methods', this.getOptions());
  }

  attachPaymentMethod(pmId: string) {
    return this.http.post(this.url + 'v2/payment-methods/' + pmId, this.getOptions());
  }

  detachPaymentMethod(pmId: string) {
    return this.http.delete(this.url + 'v2/payment-methods/' + pmId, this.getOptions());
  }

  defaultPaymentMethod(): Observable<PaymentMethod> {
    return this.http.get<PaymentMethod>(this.url + 'v2/default-payment-method', this.getOptions());
  }

  updateDefaultPaymentMethod(pmId: string) {
    return this.http.put<PaymentMethod[]>(this.url + 'v2/default-payment-methods/' + pmId, this.getOptions());
  }

  changeDefaultMethod(body) {
    return this.http.post(this.url + 'v2/update-payment-methods', body, this.getOptions());
  }

  getOptions() {
    return {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.access_token.value),
    };
  }
}
