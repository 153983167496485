import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {IChapter} from '../interfaces/IChapter';
import {ChaptersService} from '../services/chapters.service';

@Injectable({
  providedIn: 'root'
})
export class ChapterResolver implements Resolve<IChapter> {

  constructor(
    private chaptersService: ChaptersService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const id = route.paramMap.get('id');
    return this.chaptersService.meta(id);
  }
}
