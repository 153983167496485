<ng-container>
  <h2 class="bolder text-20 ml-60 mt-40 mb-10 no-select" *ngIf="title">{{title}}&nbsp;</h2>
  <swiper
    [navigation]="true"
    [pagination]="false"
    [mousewheel]="false"
    [keyboard]="false"
    [initialSlide]="0"
    [slidesPerGroup]="3"
    [slidesPerView]="'auto'"
    [spaceBetween]="36">
    <ng-template swiperSlide class="spacer-slider"></ng-template>
    <ng-template swiperSlide *ngFor="let product of values;let i = index;" class="slide no-select">

      <a class="no-decoration" [routerLink]="'/shop/product/' + product.slug">

        <div class="shop-card" [ngStyle]="{'background-image': 'url(' + ui.mediaShopFeaturedUrl(product) + ')'}">
          <div class="shop-card-overlay"></div>
          <img *ngIf="ui.mediaCharacterUrl(product)" class="shop-card-character" alt=""
               [src]="ui.mediaCharacterUrl(product)"/>
          <img *ngIf="ui.mediaLogoUrl(product)" class="shop-card-logo" alt="" [src]="ui.mediaLogoUrl(product)"/>
        </div>

        <!--<div class="shop-card flex-column justify-content-start g-8 d-inline-flex"
             [ngClass]="[(product | isMerchandising) ? 'large' : '']">
          <div class="radius4 overflow-hidden position-relative w-100" style="height: 187px;">
            <div class="product-image-box justify-content-center align-items-center d-inline-flex">
              <img alt="" (load)="isImgLoaded = true"
                   [ngClass]="[(product | isMerchandising) ? 'large' : '']"
                   [src]="isImgLoaded ? ui.productCoverUrl(product) : 'assets/images/sfondo.png'"/>
            </div>

            <img alt="badge" *ngIf="(product | isOriginal) && !(product | isPreorder)"
                 class="product-original-icon" src="assets/images/logo-compact.svg"/>

            <div class="featured-label g-10 yellow d-inline-flex p-2 align-items-center w-100 flex-column"
                 *ngIf="product && product.variations.length && !(product | isMerchandising)">
              <div class="align-self-stretch text-center text-12 fw-400 text-break black-text">
                {{product.variations.length}} variant disponile
              </div>
            </div>
          </div>
          <div class="text-left flex-column justify-content-start d-flex w-100" style="align-items: flex-start;">
            <div class="product-title fw-400 text-break overflow-hidden lightgrey-text w-100">{{product.name}}</div>
            <div class="fw-600 text-break lightgrey-text w-100">{{product.price}}€</div>
          </div>
        </div>-->
      </a>

    </ng-template>
    <ng-template swiperSlide class="spacer-slider"></ng-template>
    <ng-template swiperSlide class="spacer-slider"></ng-template>
  </swiper>
</ng-container>
