
export class CarouselTypeConstant {

    public static readonly HERO = 'hero';
    public static readonly STANDARD = 'standard';
    public static readonly RANKING = 'ranking';
    public static readonly MULTI = 'multi';
    public static readonly NUMBERED = 'numbered';
    public static readonly OVERLAY = 'overlay';
    public static readonly BIGGER = 'bigger';
    public static readonly HORIZONTAL = 'horizontal';
    public static readonly SINGLE = 'single';
    public static readonly SQUARE = 'square';
    public static readonly GRID = 'grid';

}
