import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Observable} from "rxjs";
import {ItemService} from '../services/items.service';
import {IDataPaginate} from '../interfaces/IDataPaginate';

@Injectable({
  providedIn: 'root'
})
export class DynamicApiResolver implements Resolve<IDataPaginate> {

  constructor(private itemsService: ItemService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<IDataPaginate> {
    const api = route.paramMap.get('api');
    return this.itemsService.dynamic(api);
  }
}
