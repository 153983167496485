import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';
import {IAttribute} from "../interfaces/IAttribute";

@Pipe({
  name: 'attribute'
})
export class AttributePipe implements PipeTransform {

  transform(values: IAttribute[], args: string): any {
    if (args && values && values.length) {
      const item = values.find(data => _.lowerCase(data.name) === _.lowerCase(args));
      if (item) return item.options.length ? item.options[0] : '';
    }
    return null;
  }
}
