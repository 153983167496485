<div class="mt-40" [ngStyle]="hex ? {'background': (hex | carouselBackground)} : {}">

  <h2 class="fw-600 text-22 pt-20 ml-55 mb-0 no-select">{{title}}</h2>

  <swiper
    class="pb-20"
    [cssMode]="false"
    [navigation]="true"
    [pagination]="false"
    [mousewheel]="false"
    [keyboard]="false"
    [initialSlide]="0"
    [slidesPerView]="'auto'"
    [spaceBetween]="50"
    [freeMode]="true"
  >
    <ng-template swiperSlide class="spacer-slider"></ng-template>
    <ng-template swiperSlide class="slide mt-20 mb-20 no-select" *ngFor="let comic of comics;let i = index;">

      <div class="comic-card text-14"
           [ngClass]="i === 0 ? 'position-left' : (i === comics.length - 1 ? 'position-right' : '')">

        <div class="row">
          <div class="col-12 small">

            <div class="badge-wrapper text-center" *ngIf="comic.originals">
              <img class="badge-icon" src="assets/images/badge-yellow.svg" alt=""/>
            </div>

            <div class="number-wrapper text-center">
              <img class="number-icon" [src]="'assets/images/numbers/' + (i + 1) + '.svg'" alt=""/>
            </div>

            <a class="no-decoration white-text position-relative"
               aria-label="Maggiori informazioni su {{comic.title}}"
               [routerLink]="'/home'"
               [queryParams]="{comic: comic.id}">
              <img class="comic-card-img" alt=""
                   onerror="this.onerror=null;this.src='https://d1dqu2eovq69bv.cloudfront.net/images/background-large.png'"
                   [src]="ui.mediaCoverUrl(comic, true)">
            </a>
            <h3 class="comic-card-title text-truncate text-15 fw-300 mb-0 pt-2">{{comic.title}}</h3>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template swiperSlide class="spacer-slider"></ng-template>
    <ng-template swiperSlide class="spacer-slider"></ng-template>
  </swiper>
</div>
