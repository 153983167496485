import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  private _seal_up: boolean = false;
  private _freePrice: any;
  private _shippingMethod: any;

  constructor() {
  }

  get seal_up(): boolean {
    return this._seal_up;
  }

  set seal_up(value: boolean) {
    this._seal_up = value;
  }

  get freePrice(): any {
    return this._freePrice;
  }

  set freePrice(value: any) {
    this._freePrice = value;
  }

  get shippingMethod(): any {
    return this._shippingMethod;
  }

  set shippingMethod(value: any) {
    this._shippingMethod = value;
  }

}
