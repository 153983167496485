import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BrowserStorageService} from '../../services/storage.service';
import {IChapter} from '../../interfaces/IChapter';
import {IComic} from '../../interfaces/IComic';
import {ItemService} from '../../services/items.service';

export interface DialogMatureData {
  chapter?: IChapter;
  comic?: IComic;
}

@Component({
  selector: 'app-dialog-mature',
  templateUrl: './dialog-mature.component.html',
  styleUrls: ['./dialog-mature.component.scss'],
})
export class DialogMatureComponent {
  allowMatureContent: boolean;

  title: string = null;
  content: string = null;

  constructor(
    private dialog: MatDialogRef<DialogMatureComponent>,
    private storage: BrowserStorageService,
    private itemsService: ItemService,
    @Inject(MAT_DIALOG_DATA) public data: DialogMatureData
  ) {

  }

  ngOnInit() {
    this.populateData();
  }

  populateData() {

    if (this.data) {

      if (this.data.chapter) {
        if (this.data.chapter.mature_title && this.data.chapter.mature_content) {
          this.title = this.data.chapter.mature_title;
          this.content = this.data.chapter.mature_content;
          return;
        }

        if (this.data.chapter.comic.mature_title && this.data.chapter.comic.mature_content) {
          this.title = this.data.chapter.comic.mature_title;
          this.content = this.data.chapter.comic.mature_content;
          return;
        }
      }

      if (this.data.comic) {
        if (this.data.comic.mature_title && this.data.comic.mature_content) {
          this.title = this.data.comic.mature_title;
          this.content = this.data.comic.mature_content;
          return;
        }
      }

    }

    if (!this.title || !this.content) {
      this.getMatureInfo();
    }
  }

  confirm() {
    const allowMatureLabel = 'allow-mature-ids';
    if (this.allowMatureContent) {
      let allowIdsStorage: any = this.storage.get(allowMatureLabel);
      if (!allowIdsStorage) {
        this.storage.set(allowMatureLabel, JSON.stringify([this.data.chapter.comic_id]));
      } else {
        allowIdsStorage = JSON.parse(allowIdsStorage);
        if ((allowIdsStorage.findIndex(elem => elem === this.data.chapter.comic_id)) < 0) {
          allowIdsStorage.push(this.data.chapter.comic_id);
          this.storage.set(allowMatureLabel, JSON.stringify(allowIdsStorage));
        }
      }
    }
    this.dialog.close(true);
  }

  getMatureInfo() {
    this.itemsService.matureInfo().subscribe((data: { title: string, message: string }) => {
      this.title = data.title;
      this.content = data.message;
    });
  }

}
