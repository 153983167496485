import {Pipe, PipeTransform} from '@angular/core';
import {IUser} from '../interfaces/IUser';
import * as _ from 'lodash';
import * as moment from 'moment';

@Pipe({
  name: 'subscribeStatus'
})
export class SubscribeStatusPipe implements PipeTransform {

  transform(user: IUser, args?: any): any {

    if (!user.jundo_user.subscription) {

      if (user.jundo_user.expired_plan || user.jundo_user.subscription_id || user.unsubscriptions.length > 0) {
        return 'Scaduto';
      }

      return;
    }

    let label = 'Attivo ';
    let now = moment();

    if (user.datetimes) {
      let exp_date = moment(user.datetimes.value);
      if (exp_date.isAfter(now)) {
        return 'Supplement';
      }
    }

    if (user.jundo_user.expired_plan && user.jundo_user.subscription_id) {
      let exp_date = moment(user.jundo_user.expired_plan);
      if (exp_date.isAfter(now)) {
        return 'Abbonamento PayPal';
      }
    }

    if (user.subscription) {
      let exp_date = moment(user.subscription.expired_at);
      if (exp_date.isAfter(now)) {
        return 'Abbonamento ' + (user.subscription.method || user.subscription.provider_platform);
      }
    }

    return label;
  }

}
