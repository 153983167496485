<section class="mt-30 p-10 pt-24 pb-24" *ngIf="auth.isAuthenticated.value && badges.length">
  <div class="row text-center m-20">
    <div class="col-12">
      <h3 class="fw-700 text-22 mb-20">Ricerche più popolari su Jundo</h3>
    </div>
    <div class="col-12">
      <div class="badge-box" *ngFor="let item of badges;let i = index;">
        <app-chip [label]="item.label"
                  (click)="utility.manageBadge(item)"
                  [state]="'round dark pt-10 pb-10 pl-15 pr-15 ml-6 mr-6 pointer'"></app-chip>
      </div>
    </div>
  </div>
</section>
