import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';
import {AccessGuard} from './guards/access.guard';
import {PasswordResetComponent} from './pages/password-reset/password-reset.component';
import {DynamicApiResolver} from './resolvers/dynamic-api.resolver';
import {AuthorResolver} from './resolvers/author.resolver';
import {shopRouting} from './shop.routing';
import {ChapterResolver} from './resolvers/chapter.resolver';
import {HomeResolver} from './resolvers/home.resolver';
import {LandingGuard} from './guards/landing.guard';
import {ComicSlugResolver} from './resolvers/comic-slug.resolver';
import {SitemapResolver} from './resolvers/sitemap.resolver';

function getMeta(
  title: string,
  description: string = 'La prima piattaforma italiana per leggere manga, fumetti e webcomics originali e internazionali online su smarthphone o PC. I primi capitoli sono gratis!'
) {

  return {
    breadcrumb: title,
    meta: {title: title, description: description}
  };
}

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home'
  },
  {
    path: 'home',
    canActivate: [LandingGuard],
    resolve: {data: HomeResolver},
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
  },
  {
    path: 'landing',
    data: getMeta('Jundo | Scarica l\'app'),
    loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingPageModule),
  },
  {
    path: 'coupon',
    canActivate: [LandingGuard, AuthGuard],
    data: getMeta('Coupon'),
    loadChildren: () => import('./pages/coupon/coupon.module').then(m => m.CouponPageModule),
  },
  {
    path: 'comic/:id',
    children: [
      {
        path: 'reader/:id',
        resolve: {data: ChapterResolver},
        loadChildren: () => import('./pages/reader/reader.module').then(m => m.ReaderPageModule),
      }
    ]
  },
  {
    path: 'it/:slug',
    children: [
      {
        path: '',
        resolve: {data: ComicSlugResolver},
        loadChildren: () => import('./pages/comic/comic.module').then(m => m.ComicPageModule),
      }
    ]
  },
  {
    path: 'access',
    data: getMeta('Accedi'),
    canActivate: [LandingGuard, AccessGuard],
    loadChildren: () => import('./pages/access/access.module').then(m => m.AccessPageModule),
  },
  {
    path: 'reset',
    data: getMeta('Reset password'),
    canActivate: [AccessGuard],
    loadChildren: () => import('./pages/reset/reset.module').then(m => m.ResetPageModule),
  },
  {
    path: 'onboard',
    canActivate: [AuthGuard],
    data: getMeta('On Board'),
    loadChildren: () => import('./pages/onboard/onboard.module').then(m => m.OnboardPageModule),
  },
  {
    path: 'comics/:api',
    data: getMeta('Comics'),
    canActivate: [AuthGuard],
    resolve: {data: DynamicApiResolver},
    loadChildren: () => import('./pages/comics/comics.module').then(m => m.ComicsPageModule),
  },
  {
    path: 'soon',
    data: getMeta('Coming Soon'),
    loadChildren: () => import('./pages/soon/soon.module').then(m => m.SoonPageModule),
  },
  {
    path: 'library',
    data: getMeta('Libreria'),
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/library/library.module').then(m => m.LibraryPageModule),
  },
  {
    path: 'reader/:id',
    resolve: {data: ChapterResolver},
    loadChildren: () => import('./pages/reader/reader.module').then(m => m.ReaderPageModule),
  },
  {
    path: 'author/:id',
    children: [
      {
        path: '',
        resolve: {data: AuthorResolver},
        loadChildren: () => import('./pages/author/author.module').then(m => m.AuthorPageModule),
      }
    ]
  },
  {
    path: 'about',
    children: [
      {
        path: '',
        data: getMeta('Chi siamo'),
        loadChildren: () => import('./pages/about/about.module').then(m => m.AboutPageModule)
      },
    ]
  },
  {
    path: 'subscription',
    children: [
      {
        path: '',
        data: getMeta('Abbonati a Jundo'),
        loadChildren: () => import('./pages/subscription/subscription-landing/subscription-landing.module').then(m => m.SubscriptionLandingPageModule),
      },
      {
        path: 'method',
        data: getMeta('Metodo di pagamento'),
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/subscription/subscription-method/subscription-method.module').then(m => m.SubscriptionMethodPageModule)
      },
      {
        path: 'summary',
        data: getMeta('Riepilogo'),
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/subscription/subscription-summary/subscription-summary.module').then(m => m.SubscriptionSummaryPageModule)
      },
      {
        path: 'success',
        data: getMeta('Abbonamento confermato'),
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/subscription/subscription-success/subscription-success.module').then(m => m.SubscriptionSuccessPageModule)
      }]
  },
  {
    path: 'checkout',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'shipping',
        data: getMeta('Indirizzo di spedizione'),
        loadChildren: () => import('./pages/checkout/checkout-shipping/checkout-shipping.module').then(m => m.CheckoutShippingPageModule)
      },
      {
        path: 'method',
        data: getMeta('Metodo di pagamento'),
        loadChildren: () => import('./pages/checkout/checkout-method/checkout-method.module').then(m => m.CheckoutMethodPageModule)
      },
      {
        path: 'summary',
        data: getMeta('Riepilogo'),
        loadChildren: () => import('./pages/checkout/checkout-summary/checkout-summary.module').then(m => m.CheckoutSummaryPageModule)
      },
      {
        path: 'success',
        data: getMeta('Ordine confermato'),
        loadChildren: () => import('./pages/checkout/checkout-success/checkout-success.module').then(m => m.CheckoutSuccessPageModule)
      }
    ]
  },
  {
    path: 'purchase',
    children: [
      {
        path: '',
        data: getMeta('Scegli pacchetto Jundo Coins'),
        loadChildren: () => import('./pages/purchase/purchase-landing/purchase-landing.module').then(m => m.PurchaseLandingPageModule)
      },
      {
        path: 'method',
        data: getMeta('Metodo di pagamento'),
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/purchase/purchase-method/purchase-method.module').then(m => m.PurchaseMethodPageModule)
      },
      {
        path: 'summary',
        data: getMeta('Riepilogo'),
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/purchase/purchase-summary/purchase-summary.module').then(m => m.PurchaseSummaryPageModule)
      },
      {
        path: 'success',
        data: getMeta('Acquisto confermato'),
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/purchase/purchase-success/purchase-success.module').then(m => m.PurchaseSuccessPageModule)
      }
    ]
  },
  {
    path: 'account',
    canActivate: [LandingGuard, AuthGuard],
    children: [
      {
        path: 'overview',
        data: getMeta('Overview'),
        loadChildren: () => import('./pages/account/overview/overview.module').then(m => m.OverviewPageModule),
      },
      {
        path: 'edit',
        data: getMeta('Email e password'),
        loadChildren: () => import('./pages/account/edit/edit.module').then(m => m.EditPageModule)
      },
      {
        path: 'avatar',
        data: getMeta('Avatar'),
        loadChildren: () => import('./pages/account/avatar/avatar.module').then(m => m.AvatarPageModule),
      },
      {
        path: 'profilation',
        data: getMeta('Stili e generi'),
        loadChildren: () => import('./pages/account/profilation/profilation.module').then(m => m.ProfilationPageModule),
      },
      {
        path: 'payment',
        data: getMeta('Metodo di pagamento'),
        loadChildren: () => import('./pages/account/payment/payment.module').then(m => m.PaymentPageModule),
      },
      {
        path: 'shipping',
        data: getMeta('Indirizzo di spedizione'),
        loadChildren: () => import('./pages/account/shipping/shipping.module').then(m => m.ShippingPageModule),
      },
      {
        path: 'subscription',
        data: getMeta('Abbonamento'),
        loadChildren: () => import('./pages/account/subscription/subscription.module').then(m => m.SubscriptionPageModule),
      },
      {
        path: 'coins',
        data: getMeta('Jundo Coins'),
        loadChildren: () => import('./pages/account/coins/coins.module').then(m => m.CoinsPageModule),
      },
      {
        path: 'orders',
        children: [
          {
            path: '',
            data: getMeta('Ordini'),
            loadChildren: () => import('./pages/account/orders/orders.module').then(m => m.OrdersPageModule),
          },
          {
            path: ':id',
            data: getMeta('Dettaglio ordine'),
            loadChildren: () => import('./pages/account/order/order.module').then(m => m.OrderPageModule),
          },
        ]
      },
      {
        path: 'support',
        children: [
          {
            path: 'faq',
            data: getMeta('FAQ'),
            loadChildren: () => import('./pages/account/faq/faq.module').then(m => m.FaqPageModule),
          },
          {
            path: 'contact-us',
            data: getMeta('Contattaci'),
            loadChildren: () => import('./pages/account/contact-us/contact-us.module').then(m => m.ContactUsPageModule),
          },
        ]
      },
      {
        path: 'cancellation',
        data: getMeta('Elimina account'),
        loadChildren: () => import('./pages/account/cancellation/cancellation.module').then(m => m.CancellationPageModule),
      },
    ]
  },
  {
    path: 'research',
    children: [
      {
        path: '',
        data: getMeta('Cerca su Jundo'),
        loadChildren: () => import('./pages/research/search-overview/search-overview.module').then(m => m.SearchOverviewPageModule),
      },
      {
        path: 'results',
        data: getMeta('Risultati di ricerca'),
        loadChildren: () => import('./pages/research/search-results/search-results.module').then(m => m.SearchResultsPageModule)
      }
    ]
  },
  {
    path: 'password-reset',
    component: PasswordResetComponent,
    canActivate: [AccessGuard]
  },
  {
    path: 'sitemap',
    resolve: {data: SitemapResolver},
    loadChildren: () => import('./pages/sitemap/sitemap.module').then(m => m.SitemapPageModule),
  },
  {
    path: 'shop',
    children: shopRouting
  },
  {
    path: '404',
    loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundPageModule),
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // scrollPositionRestoration: 'enabled',
    // initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
