import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';
import {IProduct} from "../interfaces/IProduct";

@Pipe({
    name: 'isOriginal'
})
export class IsOriginalPipe implements PipeTransform {
    transform(value: IProduct, args?: any): any {

        if (value['originals']) return true;

        const original = _.find(value.attributes, item => item.name === 'Original');

        if (original && original.options.length) {
            const itemVal = Number(original.options[0]);
            if (typeof itemVal == 'number' && itemVal === 1) return true;
        }

        return false;

    }
}
