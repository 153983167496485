import {Injectable} from '@angular/core';
import {ShopService} from './shop.service';
import * as _ from 'lodash';
import {CartService} from './cart.service';
import {AuthService} from './auth.service';
import {StripeService} from './stripe.service';

@Injectable({
  providedIn: 'root'
})
export class ShippingService {

  taxes: any[] = [];
  taxesFiltered: any[] = [];
  shipping: number = 0;

  constructor(
    private shopService: ShopService,
    private cartService: CartService,
    private auth: AuthService,
    private stripeService: StripeService,
  ) {
  }

  getTaxes() {
    if (this.auth.isSubscribed.value) {
      this.shopService.taxesFree()
        .subscribe((tax: any) => {
          if (tax && tax.enabled && tax.min_amount) {
            this.stripeService.freePrice = {
              id: tax.id,
              name: 'Spedizione Express gratuita',
              message: 'Consegna in 2-4 giorni lavorativi',
              price: 0,
              priceLabel: null,
              minimum: tax.min_amount * 1
            };
            this.taxesList();
          }
        });
    } else {
      this.taxesList();
    }
  }

  taxesList() {
    this.shopService.taxes().subscribe((taxes: any) => {
      this.taxes = taxes;
      this.calculateShipping(taxes);
    });
  }

  calculateShipping(taxes) {
    if (taxes.length) {
      this.taxesFiltered = _.cloneDeep([]);
      taxes.forEach(tax => {
        if (tax.enabled) {
          let taxObj = {
            id: tax.shop_taxes_id,
            name: tax.title,
            message: tax.description,
            price: tax.amount,
            priceLabel: tax.amount === '0' ? 'Gratis' : tax.amount + '€',
            disabled: tax.title === 'Express' && !this.auth.isSubscribed.value
          };
          this.taxesFiltered.push(taxObj);
        }
      });

      this.cartService.getShoppingItemAmount().subscribe(
        (amount) => {
          if (this.stripeService.freePrice && amount > this.stripeService.freePrice.minimum) {
            this.taxesFiltered = [this.stripeService.freePrice];
            this.stripeService.shippingMethod = this.taxesFiltered[0];
            this.shipping = this.stripeService.freePrice.price;
          } else {
            this.stripeService.shippingMethod = this.taxesFiltered[0];
            this.shipping = Number(this.stripeService.shippingMethod.price);
          }
        }
      );
    }
  }

}
