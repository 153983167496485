import {Component, Input} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import SwiperCore, {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
} from 'swiper';
import {IProduct} from '../../interfaces/IProduct';
import {UiService} from '../../services/ui.service';

SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);


@Component({
  selector: 'app-shop-card',
  templateUrl: './shop-card.component.html',
  styleUrls: ['./shop-card.component.scss'],
})
export class ShopCardComponent {

  @Input() product: IProduct;
  @Input() fromChapter: any;
  @Input() isNew: boolean;

  isImgLoaded: boolean = false;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public ui: UiService
  ) {

  }

  ngOnInit() {

  }

  openProduct(product: IProduct) {
    this.router.navigate(['/shop/product/' + product.slug], {
      queryParams: {
        return_url: this.router.url,
      }
    });
  }
}
