import {Component, Input, NgZone, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IComic} from '../../interfaces/IComic';
import {UiService} from '../../services/ui.service';
import {ItemService} from '../../services/items.service';
import * as _ from 'lodash';

import SwiperCore, {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
} from 'swiper';
import {ProgressPipe} from '../../pipes/progress.pipe';
import {AuthService} from '../../services/auth.service';
import {ICarousel} from '../../interfaces/ICarousel';
import {FirebaseService} from '../../services/firebase.service';
import {ChannelsService} from '../../services/channels.service';
import {UtilityProvider} from '../../services/utility.service';

SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);


@Component({
  selector: 'app-comic-grid',
  templateUrl: './comic-grid.component.html',
  styleUrls: ['./comic-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ProgressPipe]
})
export class ComicGridComponent {

  @Input() carousel: ICarousel;

  @Input() required: boolean | number = false;
  @Input() expand?: boolean | number = true;
  @Input() shop: boolean = false;
  @Input() soon: boolean | number = 0;
  @Input() marker: boolean = false;
  @Input() showPrice: boolean = false;
  @Input('big-order') bigOrder: boolean = false;
  @Input('alternative-chip') alternativeChips: boolean = false;
  @Input('from-chapter') fromChapter: any;
  @Input('first-slide') firstSlide = true;
  @Input('css-mode') cssMode = true;
  @Input() isNew: boolean;

  isImgLoaded: boolean = false;
  isFavorite: boolean;
  isReadLater: boolean;

  values: IComic[] = [];

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    public ui: UiService,
    public auth: AuthService,
    private itemService: ItemService,
    private progressPipe: ProgressPipe,
    private itemsService: ItemService,
    private ngZone: NgZone,
    private firebase: FirebaseService,
    private channels: ChannelsService,
    private utility: UtilityProvider,
  ) {

  }

  ngOnInit() {
    // this.values = _.cloneDeep(this.required ? this.data.slice(0, 10) : this.data);
    this.values = _.cloneDeep(this.carousel.comics);
  }

  openReader(comic: IComic) {
    this.router.navigate(['/reader', this.utility.progressChapterId(comic)]).then();
  }

  openComic(comic: IComic) {
    this.router.navigate(['/home'], {
      queryParams: {
        comic: comic.id
      },
      relativeTo: this.route
    }).then();
  }
}
