import {Pipe, PipeTransform} from '@angular/core';
import {IComic} from '../interfaces/IComic';
import {IMedia} from '../interfaces/IMedia';
import {environment} from '../../environments/environment';

@Pipe({
  name: 'featureHorizontalCover'
})
export class FeatureHorizontalCoverPipe implements PipeTransform {
  transform(comic: IComic, args?: any): string {

    if (comic && comic.media_featured && comic.media_featured.filename_landscape) {
      return environment.media + 'featured' + comic.media_featured.original_path + comic.media_featured.filename_landscape;
    }

    if (comic && comic.media && comic.media.filename_landscape) {
      return environment.media + 'previews' + comic.media.original_path + comic.media.filename_landscape;
    }

    if (comic && comic.media_featured && comic.media_featured.filename) {
      return environment.media + 'featured' + comic.media_featured.original_path + comic.media_featured.filename;
    }

    return environment.media + 'images/background-large.png';
  }
}
