import {Component, Input, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PaymentMethodTypeConstant} from '../../constants/payment-method-type.constant';
import {
  PaymentMethod,
  PaymentMethodResult,
  StripeCardCvcElementOptions,
  StripeCardElementOptions, StripeElements,
  StripeElementsOptions
} from '@stripe/stripe-js';
import {StripeCardNumberComponent, StripeService} from 'ngx-stripe';
import {AuthService} from '../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UiService} from '../../services/ui.service';
import {PaymentMethodService} from '../../services/payment-method.service';
import {environment} from '../../../environments/environment';
import {SEOService} from '../../services/seo.service';
import {StripeExpressCheckoutElementOptions} from '@stripe/stripe-js/types/stripe-js/elements/express-checkout';

declare const Stripe: any;

@Component({
  selector: 'app-method-box',
  templateUrl: './method-box.component.html',
  styleUrls: ['./method-box.component.scss'],
})
export class MethodBoxComponent {

  @Input() type: 'checkout' | 'subscription' | 'purchase';
  @Input() product_id: string;

  form: FormGroup;
  formCard: FormGroup;

  return_url: string = '/home';
  subscribe: boolean;

  error: string;
  methodConstant = PaymentMethodTypeConstant;
  methods: any = [
    // {id: this.methodConstant.CARD, iconSrc: 'cc.svg', label: 'Carta di credito'},
    // {id: this.methodConstant.PAYPAL, iconSrc: 'paypal.svg', label: null, disabled: false},
    // {id: this.methodConstant.GOOGLE, iconSrc: 'google-pay.svg', label: null, disabled: false},
    // {id: this.methodConstant.APPLE, iconSrc: 'apple-pay.svg', label: null, disabled: false},
    // {id: this.methodConstant.OTHERS, iconSrc: null, label: 'Non salvare metodi pagamento'},
  ];

  mode: 'methods' | 'manage-card' = 'methods';
  paymentMethods: PaymentMethod[] = [];

  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;

  cardOptions: StripeCardElementOptions = {
    iconStyle: 'solid',
    style: {
      base: {
        padding: '10px',
        iconColor: '#c4f0ff',
        color: '#fff',
        fontFamily: '"Source Sans Pro", sans-serif',
        fontWeight: 500,
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {color: '#fce883'},
        // '::placeholder': {color: '#87bbfd'}
      },
      invalid: {
        iconColor: '#ffc7ee',
        color: '#ffc7ee'
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'it',
  };

  cvvOptions: StripeCardCvcElementOptions = {
    ...this.cardOptions,
    placeholder: 'CVV',
  };

  expOptions: StripeCardCvcElementOptions = {
    ...this.cardOptions,
    placeholder: 'Scadenza',
  };

  numberOptions: StripeCardCvcElementOptions = {
    ...this.cardOptions,
    placeholder: 'Numero della carta',
  };

  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ui: UiService,
    private SEOService: SEOService,
    private stripeService: StripeService,
    private pmService: PaymentMethodService
  ) {
    const {meta} = this.route.snapshot.data;
    this.SEOService.updateTitle(meta.title);
    this.SEOService.updateDescription(meta.description);
    this.SEOService.updateUrl('');
    this.SEOService.updateImage(environment.siteUrl + '/assets/images/logo.svg');
    this.SEOService.updateTag({property: 'og:type', content: 'website'});

    this.route.queryParams.subscribe(params => {
      if (params && params.return_url) {
        this.return_url = decodeURI(params.return_url);
      }
    });

  }

  ngOnInit() {
    this.initForm();
    this.initFormCard();
    this.profile().then();
    this.getPaymentMethodsList();
    this.populateMethods();
  }

  initializeExpressCheckout() {

    // <div id="express-checkout-element"></div>
    let stripe = Stripe(environment.stripeKey);

    const expressCheckoutOptions: StripeExpressCheckoutElementOptions = {
      buttonType: {
        applePay: 'buy',
        googlePay: 'buy'
      },
    };
    const elements: StripeElements = stripe.elements({
      locale: 'it',
      mode: 'payment',
      amount: 1099,
      currency: 'eur',
    });
    const expressCheckoutElement = elements.create(
      'expressCheckout',
      expressCheckoutOptions
    );
    expressCheckoutElement.mount('#express-checkout-element');

    const handleError = (error) => {
      const messageContainer = document.querySelector('#error-message');
      messageContainer.textContent = error.message;
    };

    expressCheckoutElement.on('confirm', async (event) => {
      const {error: submitError} = await elements.submit();
      if (submitError) {
        handleError(submitError);
        return;
      }

      // Create the PaymentIntent and obtain clientSecret
      const res = await fetch('/create-intent', {
        method: 'POST',
      });
      const {client_secret: clientSecret} = await res.json();

      const {error} = await stripe.confirmPayment({
        // `elements` instance used to create the Express Checkout Element
        elements,
        // `clientSecret` from the created PaymentIntent
        clientSecret,
        confirmParams: {
          return_url: 'https://example.com/order/123/complete',
        },
      });

      if (error) {
        // This point is only reached if there's an immediate error when
        // confirming the payment. Show the error to your customer (for example, payment details incomplete)
        handleError(error);
      } else {
        // The payment UI automatically closes with a success animation.
        // Your customer is redirected to your `return_url`.
      }
    });

  }

  async profile() {
    const user = await this.auth.profile().toPromise();
    if (user) {
      const defaultMethod = this.auth.user.value.jundo_user.stripe_customer_default_method || 'card';

      /*if (defaultMethod === this.methodConstant.APPLE_PAY && this.ui.isApplePayAvailable()) {
        this.form.controls['method_id'].setValue(defaultMethod);
      }else if (defaultMethod === this.methodConstant.CARD) {
        this.form.controls['method_id'].setValue(defaultMethod);
      }else if (defaultMethod === this.methodConstant.PAYPAL) {
        this.form.controls['method_id'].setValue(defaultMethod);
      }else if (defaultMethod === this.methodConstant.GOOGLE_PAY) {
        // Stripe.isGooglePayAvailable().then(() => this.form.controls['method_id'].setValue(defaultMethod));
        this.form.controls['method_id'].setValue(defaultMethod);
      } else {
        this.form.controls['method_id'].setValue(defaultMethod);
      }*/

      if (defaultMethod === this.methodConstant.PAYPAL && this.ui.isApplePayAvailable()) {
        this.form.controls['method_id'].setValue(this.methodConstant.PAYPAL);
      } else {
        this.form.controls['method_id'].setValue(this.methodConstant.CARD);
      }

    }
  }

  initForm() {
    this.form = this.fb.group({
      method_id: [null, [Validators.required]]
    });
  }

  initFormCard() {
    this.formCard = this.fb.group({
      name: [null, [Validators.required]]
    });
  }

  async confirm() {
    this.error = null;
    if (this.form.valid) {
      const result: any = await this.pmService.changeDefaultMethod({default_method: this.form.value.method_id}).toPromise();

      const queryParams: any = {return_url: this.return_url};

      if (this.type === 'purchase' && this.product_id) {
        queryParams.product = this.product_id;
      }

      if (result) {
        this.router.navigate([this.type + '/summary'], {queryParams});
      }
    }
  }

  async saveCardAndConfirm() {

    const name = this.formCard.get('name').value;

    const result: PaymentMethodResult = await this.stripeService
      .createPaymentMethod({
        type: 'card',
        card: this.card.element,
        billing_details: {name}
      }).toPromise();

    if (result.error) {
      if (typeof result.error === 'string') {
        // @ts-ignore
        this.error = result.error;
      } else {
        this.error = result.error.message;
      }
      return;
    }

    this.pmService.attachPaymentMethod(result.paymentMethod.id)
      .subscribe(() => {
        this.error = null;
        if (this.form.valid) {

          this.auth.profile().subscribe((user) => {

            const queryParams: any = {return_url: this.return_url};

            if (this.type === 'purchase' && this.product_id) {
              queryParams.product = this.product_id;
            }

            this.router.navigate([this.type + '/summary'], {queryParams});
          });

        }
      }, (error) => {
        if (error.error && error.error.error && typeof error.error.error === 'string') {
          this.error = error.error.error;
        }
      });
  }

  changeMode(mode: 'methods' | 'manage-card') {
    this.error = null;
    this.mode = mode;

    if (this.mode === 'methods') {
      this.getPaymentMethodsList();
    }
  }

  setDefaultPaymentMethod(pm: PaymentMethod) {
    this.pmService.updateDefaultPaymentMethod(pm.id).subscribe(() => {
      this.getPaymentMethodsList();
    });
  }

  getPaymentMethodsList() {
    this.pmService.paymentMethods().subscribe(data => {
      this.paymentMethods = data;
    });
  }

  selectPlan(method: any) {
    this.form.controls['method_id'].setValue(method.id);
  }

  close() {
    this.router.navigateByUrl(this.return_url, {
      replaceUrl: true
    });
  }

  private populateMethods() {
    this.methods = [
      {id: this.methodConstant.CARD, iconSrc: 'cc.svg', label: 'Carta di credito'},
      {id: this.methodConstant.PAYPAL, iconSrc: 'paypal.svg', label: null},
    ];

    if (this.ui.isApplePayAvailable()) {
      // this.methods.push({id: this.methodConstant.APPLE_PAY, iconSrc: 'apple-pay.svg', label: null});
    }

    // this.methods.push({id: this.methodConstant.GOOGLE_PAY, iconSrc: 'google-pay.svg', label: null});

    // this.methods.push({id: this.methodConstant.OTHERS, iconSrc: null, label: 'Non salvare metodi pagamento'});
  }

}
