import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';
import {IAttribute} from '../interfaces/IAttribute';
import {IVariation} from '../interfaces/IVariation';
import {ICart} from '../interfaces/ICart';

@Pipe({
  name: 'variantPrice'
})
export class VariantPricePipe implements PipeTransform {

  transform(item: ICart, args?: any): number {
    const variantId = item.variantId;
    const v = item.product.variations.find(variation => variation.id === variantId);
    if (v) {
      return Number(v.price);
    }
    return 0;
  }
}
