import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';
import {IProduct} from "../interfaces/IProduct";

@Pipe({
    name: 'isPreorder'
})
export class IsPreorderPipe implements PipeTransform {
    transform(value: IProduct, args?: any): any {

        const preorder = _.find(value.categories, item => item.name === 'Preorder');
        return !!preorder;

    }
}
