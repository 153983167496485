import {Component} from '@angular/core';
import {ShopService} from '../../services/shop.service';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-shop-message',
  templateUrl: './shop-message.component.html',
  styleUrls: ['./shop-message.component.scss'],
})
export class ShopMessageComponent {

  isOpen: boolean;

  title: string;
  message: string;

  constructor(
    private shopService: ShopService,
    private dataService: DataService
  ) {
  }

  ngOnInit() {
    const message = localStorage.getItem('jundo-shop-message');
    this.isOpen = !(message && message === '1');
    this.getTaxes();
    this.getBannerInfo();
  }

  getBannerInfo(){
    this.dataService.bannerInfo().subscribe((data: any) => {
      this.title = data?.title;
      this.message = data?.message;
    })
  }

  close() {
    localStorage.setItem('jundo-shop-message', '1');
    this.isOpen = false;
  }

  getTaxes() {
    this.shopService.taxes()
      .subscribe((taxes: any) => {
      });
  }

}
