import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DirectivesModule} from '../directives/directives.module';
import {RouterModule} from '@angular/router';
import {PipesModule} from '../pipes/pipes.module';
import {SwiperModule} from 'swiper/angular';
import {FooterComponent} from './footer/footer.component';
import {NavbarComponent} from './navbar/navbar.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSidenavModule} from '@angular/material/sidenav';
import {BreadcrumbsComponent} from './breadcrumbs/breadcrumbs.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {InputComponent} from './input/input.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import {DialogComponent} from './dialog/dialog.component';
import {LoaderComponent} from './loader/loader.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {SkeletonComponent} from './skeleton/skeleton.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ComicSliderComponent} from './comic-slider/comic-slider.component';
import {ComicSliderBiggerComponent} from './comic-slider-bigger/comic-slider-bigger.component';
import {ComicSliderHorizontalComponent} from './comic-slider-horizontal/comic-slider-horizontal.component';
import {ComicSliderNumberedComponent} from './comic-slider-numbered/comic-slider-numbered.component';
import {StatusItemComponent} from './status-item/status-item.component';
import {MatCardModule} from '@angular/material/card';
import {ChipComponent} from './chip/chip.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {ComicCardComponent} from './comic-card/comic-card.component';
import {NgxPayPalModule} from 'ngx-paypal';
import {NgbCollapseModule} from '@ng-bootstrap/ng-bootstrap';
import {DialogFiltersComponent} from './dialog-filters/dialog-filters.component';
import {MatRadioModule} from '@angular/material/radio';
import {DialogProfileComponent} from './dialog-profile/dialog-profile.component';
import {DialogPasswordComponent} from './dialog-password/dialog-password.component';
import {DialogAvatarComponent} from './dialog-avatar/dialog-avatar.component';
import {DialogGenresStylesComponent} from './dialog-genres-styles/dialog-genres-styles.component';
import {MatStepperModule} from '@angular/material/stepper';
import {DialogPaymentInfoComponent} from './dialog-payment-info/dialog-payment-info.component';
import {DialogLikeComponent} from './dialog-like/dialog-like.component';
import {SearchPopularBar} from './search-popular-bar/search-popular-bar.component';
import {ProductSliderComponent} from './product-slider/product-slider.component';
import {AlertComponent} from './alert/alert.component';
import {CountdownComponent} from './countdown/countdown.component';
import {ToggleProductQuantityComponent} from './toggle-product-quantity/toggle-product-quantity.component';
import {DialogNewsletterComponent} from './dialog-newsletter/dialog-newsletter.component';
import {AddressDataComponent} from './address-data/address-data.component';
import {ShippingCardComponent} from './shipping-card/shipping-card.component';
import {CheckoutPreviewComponent} from './checkout-preview/checkout-preview.component';
import {ShopCardComponent} from './shop-card/shop-card.component';
import {StepperComponent} from './stepper/stepper.component';
import {DialogRequestDeleteComponent} from './admin/dialog-request-delete/dialog-request-delete.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {DateComponent} from './date/date.component';
import {AuthorsAutocompleteComponent} from './authors-autocomplete/authors-autocomplete.component';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {LinearChartComponent} from './linear-chart/linear-chart.component';
import {BarChartModule, LineChartModule, PieChartModule} from '@swimlane/ngx-charts';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {VerticalChartComponent} from './vertical-chart/vertical-chart.component';
import {PieVerticalComponent} from './pie-chart/pie-chart.component';
import {DialogComicsComponent} from './dialog-comics/dialog-comics.component';
import {DialogCropImageComponent} from './dialog-crop-image/dialog-crop-image.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {DialogChaptersBlockComponent} from './dialog-chapters-block/dialog-chapters-block.component';
import {TagsInputComponent} from './tags-input/tags-input.component';
import {ProductsAutocompleteComponent} from './products-autocomplete/products-autocomplete.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {FeaturedProductComponent} from './featured-product/featured-product.component';
import {MerchCardComponent} from './merch-card/merch-card.component';
import {ProductQuantityComponent} from './product-quantity/product-quantity.component';
import {DialogVariantComponent} from './dialog-variant/dialog-variant.component';
import {ShopMessageComponent} from './shop-message/shop-message.component';
import {DialogMatureComponent} from './dialog-mature/dialog-mature.component';
import {DialogProductsComponent} from './dialog-products/dialog-products.component';
import {ProductSliderSquareComponent} from './product-slider-square/product-slider-square.component';
import {PanelSliderComponent} from './panel-slider/panel-slider.component';
import {NgsgModule} from 'ng-sortgrid';
import {ShopSliderComponent} from './shop-slider/shop-slider.component';
import {BundleSliderComponent} from './bundle-slider/bundle-slider.component';
import {DialogBundleComponent} from './dialog-bundle/dialog-bundle.component';
import {DialogAuthComponent} from './dialog-auth/dialog-auth.component';
import {ComicSliderOverlayComponent} from './comic-slider-overlay/comic-slider-overlay.component';
import {HeroComponent} from './hero/hero.component';
import {ComicStatusBarComponent} from './comic-status-bar/comic-status-bar.component';
import {DialogComicComponent} from './dialog-comic/dialog-comic.component';
import {AccountMenuComponent} from './account-menu/account-menu.component';
import {MatListModule} from '@angular/material/list';
import {AccountTitleComponent} from './account-title/account-title.component';
import {PreviewBoxComponent} from './preview-box/preview-box.component';
import {BoardComponent} from './board/board.component';
import {LoaderAnimationComponent} from './loader-animation/loader-animation.component';
import {ChaptersGroupDataComponent} from './chapters-group-data/chapters-group-data.component';
import {SidenavCartComponent} from './sidenav-cart/sidenav-cart.component';
import {CartItemComponent} from './cart-item/cart-item.component';
import {ProductSliderOverlayComponent} from './product-slider-overlay/product-slider-overlay.component';
import {NgxStripeModule} from 'ngx-stripe';
import {MethodBoxComponent} from './method-box/method-box.component';
import {ComicGridComponent} from './comic-grid/comic-grid.component';
import {ProductGridComponent} from './product-grid/product-grid.component';
import {PaymentMethodBoxComponent} from './payment-method-box/payment-method-box.component';
import {PackageCardComponent} from './package-card/package-card.component';
import {DialogCancellationComponent} from './dialog-cancellation/dialog-cancellation.component';
import {DialogManualCoinComponent} from './dialog-manual-coin/dialog-manual-coin.component';
import {DialogCouponComponent} from './dialog-coupon/dialog-coupon.component';
import {DialogSecureInputComponent} from './dialog-secure-input/dialog-secure-input.component';

const PAGES_COMPONENTS = [
  FooterComponent,
  NavbarComponent,
  BreadcrumbsComponent,
  InputComponent,
  DialogComponent,
  LoaderComponent,
  SkeletonComponent,
  ComicSliderComponent,
  ComicSliderBiggerComponent,
  ComicSliderHorizontalComponent,
  ComicSliderNumberedComponent,
  StatusItemComponent,
  ChipComponent,
  ComicCardComponent,
  DialogFiltersComponent,
  DialogProfileComponent,
  DialogPasswordComponent,
  DialogAvatarComponent,
  DialogGenresStylesComponent,
  DialogPaymentInfoComponent,
  DialogLikeComponent,
  SearchPopularBar,
  ProductSliderComponent,
  AlertComponent,
  ToggleProductQuantityComponent,
  CountdownComponent,
  DialogNewsletterComponent,
  AddressDataComponent,
  ShippingCardComponent,
  CheckoutPreviewComponent,
  ShopCardComponent,
  StepperComponent,
  DialogRequestDeleteComponent,
  DateComponent,
  AuthorsAutocompleteComponent,
  LinearChartComponent,
  VerticalChartComponent,
  PieVerticalComponent,
  DialogComicsComponent,
  DialogCropImageComponent,
  DialogChaptersBlockComponent,
  TagsInputComponent,
  ProductsAutocompleteComponent,
  FeaturedProductComponent,
  MerchCardComponent,
  DialogVariantComponent,
  ShopMessageComponent,
  DialogMatureComponent,
  DialogProductsComponent,
  ProductSliderSquareComponent,
  PanelSliderComponent,
  ShopSliderComponent,
  BundleSliderComponent,
  DialogBundleComponent,
  DialogAuthComponent,
  ComicSliderOverlayComponent,
  HeroComponent,
  ComicStatusBarComponent,
  DialogComicComponent,
  AccountMenuComponent,
  AccountTitleComponent,
  PreviewBoxComponent,
  BoardComponent,
  LoaderAnimationComponent,
  ChaptersGroupDataComponent,
  SidenavCartComponent,
  CartItemComponent,
  ProductSliderOverlayComponent,
  MethodBoxComponent,
  ComicGridComponent,
  ProductGridComponent,
  PaymentMethodBoxComponent,
  PackageCardComponent,
  DialogCancellationComponent,
  DialogManualCoinComponent,
  DialogCouponComponent,
  DialogSecureInputComponent,
  ProductQuantityComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    ReactiveFormsModule,
    RouterModule,
    PipesModule,
    SwiperModule,
    MatDialogModule,
    MatButtonModule,
    MatTabsModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatCheckboxModule,
    NgxSkeletonLoaderModule,
    NgxSpinnerModule,
    MatCardModule,
    MatProgressBarModule,
    NgxPayPalModule,
    NgbCollapseModule,
    MatRadioModule,
    MatStepperModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatAutocompleteModule,
    LineChartModule,
    MatButtonToggleModule,
    BarChartModule,
    PieChartModule,
    ImageCropperModule,
    MatExpansionModule,
    NgsgModule,
    MatListModule,
    NgxStripeModule,
  ],
  declarations: [
    PAGES_COMPONENTS,
  ],
  exports: [
    PAGES_COMPONENTS,
  ],
  providers: [],
  schemas: [
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class ComponentsModule {
}
