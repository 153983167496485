import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-chip',
    templateUrl: './chip.component.html',
    styleUrls: ['./chip.component.scss'],
})
export class ChipComponent {

    @Input() label: string | number;
    @Input() state: string;
    @Input('is-filter') isFilter: boolean = false;
    @Input('is-removed') isRemoved: boolean = false;

    constructor() {
    }

}
