<h2 mat-dialog-title class="text-20 fw-700 p-5 mb-0" *ngIf="title">{{title}}</h2>
<div mat-dialog-content>
  <div class="content pr-30 pl-30 pb-10">
    <div class="row">
      <div class="col-12" *ngIf="content">
        <p>{{content}}</p>
      </div>
      <div class="col-12">
        <mat-checkbox color="primary" class="white-text text-14 fw-400 bolder" [(ngModel)]="allowMatureContent">
          Non chiedermelo più su questa opera
        </mat-checkbox>
      </div>
    </div>
  </div>
</div>
<mat-dialog-actions align="end">
  <button class="btn btn-outline-jundo mr-20" [mat-dialog-close]="false">Annulla</button>
  <button class="btn btn-jundo mr-10" (click)="confirm()">Continua</button>
</mat-dialog-actions>
