<div class="featured-box p-50 animate__animated animate__fadeIn" *ngIf="product">

  <div class="overlay" [ngStyle]="{'background-image': 'url(' + backgroundUrl + ')'}"></div>

  <div class="row pt-20 pb-20">
    <div class="col-12">
      <h3 class="white-text fw-700 text-20 text-center">{{title}}</h3>
    </div>
    <div class="col-12 text-center">
      <div class="featured-card text-14 mb-30 mt-20">
        <a [routerLink]="'product/' + product.slug" class="no-decoration">
          <img class="cover" [alt]="product.name" [src]="ui.productCoverUrl(product)"/>
          <div class="variant-marker yellow"
               *ngIf="product && (product.variations.length && !(product.categories | category: categoryNameConstants.MERCHANDISING))">
            <p class="black-text bolder m-0 pt-4 pt-2 text-center text-14">Variant disponibile</p>
          </div>
        </a>
      </div>
    </div>
    <div class="col-12 text-center">
      <h3 class="white-text bolder text-20">{{product.name}}</h3>
      <p class="title14 fw-400 mt-10 mb-20" *ngIf="product.tags && product.tags.length">
          <span *ngFor="let tag of product.tags; let i = index;">
              <span *ngIf="i > 0" class="yellow-text">&nbsp;&nbsp;•&nbsp;&nbsp;</span>
              <span class="white-text">{{tag.name}}</span>
          </span>
      </p>
    </div>
    <div class="col-12 text-center">
      <button type="button"
              class="btn btn-jundo text-18 w100 featured-button"
              [routerLink]="'product/' + product.slug">
        {{(product | isPreorder) ? 'Pre-ordina ora!' : 'Acquista ora'}}
      </button>
    </div>
  </div>

</div>


