import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

    transform(value: any, args?: any): any {

        const date = moment(value);
        const day = date.get('date');
        const month = date.get('months') + 1;
        const year = String(date.get('years'));
        return day + '/' + month + '/' + year;

    }

}
