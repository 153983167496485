import {Component, Input} from '@angular/core';
import {IBadge} from '../../interfaces/IBadge';
import {UtilityProvider} from '../../services/utility.service';
import {AuthService} from '../../services/auth.service';
import {Observable} from 'rxjs';
import {ItemService} from '../../services/items.service';

@Component({
  selector: 'app-search-popular-bar',
  templateUrl: './search-popular-bar.component.html',
  styleUrls: ['./search-popular-bar.component.scss'],
})
export class SearchPopularBar {

  @Input() type: 'comic' | 'shop' = 'comic';

  badges: IBadge[] = [];

  constructor(
    private itemService: ItemService,
    public utility: UtilityProvider,
    public auth: AuthService
  ) {
  }

  ngOnInit() {
    if (this.auth.isAuthenticated.value) {
      this.getListBadge();
    }
  }

  getListBadge() {
    let obs: Observable<any> = this.type === 'shop' ? this.itemService.getShopSuggest() : this.itemService.getSearchSuggest();
    obs.subscribe((data: any) => {
      if (data) {
        this.badges = data;
      }
    });
  }

}
