import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ShopService} from '../../services/shop.service';
import {CartService} from '../../services/cart.service';
import {StripeService} from '../../services/stripe.service';
import {StripePaymentRequestButtonComponent, StripeService as NgxStripeService} from 'ngx-stripe';
import {UiService} from '../../services/ui.service';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ICoupon} from '../../interfaces/ICoupon';
import {PaymentMethodTypeConstant} from '../../constants/payment-method-type.constant';
import {CouponAmountPipe} from '../../pipes/coupon-amount.pipe';
import {ICart} from '../../interfaces/ICart';
import {StripePaymentElement} from '@stripe/stripe-js/types/stripe-js/elements';
import {PaymentIntentService} from '../../services/payment-intent.service';
import {
  PaymentIntent, PaymentRequestOptions,
  PaymentRequestPaymentMethodEvent,
  PaymentRequestShippingAddressEvent, StripeElements,
} from '@stripe/stripe-js';
import {of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {DialogComponent} from '../dialog/dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ShippingService} from '../../services/shipping.service';

@Component({
  selector: 'app-checkout-preview',
  templateUrl: './checkout-preview.component.html',
  styleUrls: ['./checkout-preview.component.scss'],
  providers: [CouponAmountPipe]
})
export class CheckoutPreviewComponent implements OnInit, OnChanges, OnDestroy {

  @Input() cart: ICart[];
  @Input() subtotal: number = 0;
  @Input() stripe_customer_default_method: string;

  @ViewChild(StripePaymentRequestButtonComponent) paymentElement: StripePaymentRequestButtonComponent;

  amount: any = 0;

  couponForm: FormGroup;
  coupon: ICoupon;
  couponData: ICoupon;

  methodConstant = PaymentMethodTypeConstant;
  private timeoutId: any;

  payRequestOptions: PaymentRequestOptions = {
    total: {
      amount: this.amount,
      label: 'Ordine',
      pending: true
    },
    country: 'IT',
    currency: 'eur',
    disableWallets: ['link', 'browserCard'],
    requestPayerName: true,
    requestPayerEmail: true,
  };

  private paymentIntent: PaymentIntent;
  private elements: StripeElements;

  constructor(
    public shopService: ShopService,
    public shippingService: ShippingService,
    public stripeService: StripeService,
    public dialog: MatDialog,
    public ngxStripeService: NgxStripeService,
    public piService: PaymentIntentService,
    public cartService: CartService,
    public ui: UiService,
    public router: Router,
    private fb: FormBuilder,
    private couponAmount: CouponAmountPipe
  ) {
  }

  ngOnInit() {
    this.couponForm = this.fb.group({coupon: null});
    this.timeOutRefresh();
  }

  ngOnChanges(event: SimpleChanges) {
    if (event.cart || event.shipping || event.subtotal) {
      this.timeOutRefresh();
    }
  }

  timeOutRefresh() {
    this.clearTimeout();
    this.timeoutId = setTimeout(() => {
      this.refreshCartAmount();
    }, 1000);
  }

  refreshCartAmount() {

    this.amount = ((this.subtotal + this.shippingService.shipping - this.couponAmount.transform(this.couponData)) * 100).toFixed(2);
    this.refreshCouponData();

    if (!this.ui.isBrowser()) {
      return;
    }

    /*
    if (this.stripe_customer_default_method === this.methodConstant.APPLE) {
      this.paymentApplePayRequestOptions = {
        total: {
          amount: this.amount,
          label: 'Ordine',
          pending: true
        },
        country: 'IT',
        currency: 'eur',
        disableWallets: ['link', 'googlePay', 'browserCard'],
        requestPayerName: true,
        requestPayerEmail: true,
      };
    }

    if (this.stripe_customer_default_method === this.methodConstant.GOOGLE) {
      this.paymentGooglePayRequestOptions = {
        total: {
          amount: this.amount,
          label: 'Ordine',
          pending: true
        },
        country: 'IT',
        currency: 'eur',
        disableWallets: ['link', 'applePay', 'browserCard'],
        requestPayerName: true,
        requestPayerEmail: true,
      };
    }
    */

    if (this.stripe_customer_default_method === this.methodConstant.OTHERS) {
      this.setupCheckout(+this.amount, this.couponData).then();
    }

  }

  onConfirmCoupon() {
    if (this.couponForm.invalid) {
      return;
    }

    this.shopService.coupon(this.couponForm.value).subscribe((data: ICoupon) => {

      this.coupon = data;

      this.refreshCouponData();
      this.refreshCartAmount();

    }, () => {
      this.ui.error('Coupon inserito non valido');
      this.couponData = null;
      this.cleanValidationForm();
    });
  }

  removeCoupon() {
    this.couponForm.reset();
    this.couponData = null;
    this.coupon = null;
    this.refreshCartAmount();
  }

  cleanValidationForm() {
    this.couponForm.reset();
    (Object as any).values(this.couponForm.controls).forEach((control: FormControl) => {
      control.markAsUntouched();
      control.markAsPristine();
    });
  }

  private refreshCouponData() {

    if (!this.coupon) {
      return;
    }

    // check min/max order
    const minimum_amount = this.coupon.minimum_amount * 1;
    const maximum_amount = this.coupon.maximum_amount * 1;

    if (
      (minimum_amount && this.amount < minimum_amount) ||
      (maximum_amount && this.amount > maximum_amount) ||
      this.coupon.discount_type !== 'percent'
    ) {
      this.ui.error('Coupon inserito non valido');
      this.couponData = null;
      this.cleanValidationForm();
      return;
    }

    this.couponData = {
      id: this.coupon.id,
      code: this.coupon.code,
      amount: Number(this.coupon.amount),
      discount_type: 'percent',
      maximum_amount: maximum_amount,
      minimum_amount: minimum_amount,
      free_shipping: this.coupon.free_shipping,
      status: this.coupon.status,
      description: this.coupon.description
    };

    this.cleanValidationForm();
  }

  private clearTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  async setupCheckout(amount: number, coupon) {

    document
      .querySelector('#payment-form')
      .addEventListener('submit', async (e) => {
        e.preventDefault();
        if (this.stripeService.submitted) {
          return;
        }
        this.stripeService.submitted = true;
        this.confirmDataPayment(this.paymentIntent.id, true, this.coupon, this.elements);
      });

    this.stripeService.submitted = true;
    this.coupon = coupon;
    this.paymentIntent = await this.piService.create({amount}).toPromise();

    this.elements = await this.ngxStripeService.elements({
      clientSecret: this.paymentIntent.client_secret,
      appearance: this.stripeService.appearance
    }).toPromise();

    this.elements.fetchUpdates().then(() => {
      this.stripeService.submitted = false;
    });

    const paymentElement: StripePaymentElement = this.elements.create('payment', {
      layout: {
        type: 'accordion',
        defaultCollapsed: false
      }
    });
    paymentElement.mount('#payment-element');
  }

  onNotAvailable() {
    // Subscribe to this event in case you want to act
    // base on availability
    console.log('Payment Request is not Available');
  }

  onPaymentMethod(ev: PaymentRequestPaymentMethodEvent) {
    this.piService.create({amount: +this.amount})
      .pipe(
        switchMap((pi) => {
          return this.ngxStripeService
            .confirmCardPayment(
              pi.client_secret,
              {payment_method: ev.paymentMethod.id},
              {handleActions: false}
            )
            .pipe(
              switchMap((confirmResult) => {
                if (confirmResult.error) {
                  // Report to the browser that the payment failed,
                  // prompting it to re-show the payment interface,
                  // or show an error message and close the payment.
                  ev.complete('fail');
                  return of({
                    error: new Error('Error Confirming the payment'),
                  });
                } else {
                  // Report to the browser that the confirmation was
                  // successful, prompting it to close the browser
                  // payment method collection interface.
                  ev.complete('success');
                  // Let Stripe.js handle the rest of the payment flow.

                  let options: any = {
                    clientSecret: pi.client_secret,
                    confirmParams: {
                      return_url: `${environment.siteUrl}checkout/success`,
                    },
                    redirect: 'if_required'
                  };

                  return this.ngxStripeService.confirmPayment(options);
                }
              })
            );
        })
      )
      .subscribe((result) => {
        if (result.error) {
          // The payment failed -- ask your customer for a new payment method.
        } else {
          // The payment has succeeded.
        }
      });
  }

  onShippingAddressChange(ev: PaymentRequestShippingAddressEvent) {
    if (ev.shippingAddress.country !== 'US') {
      ev.updateWith({status: 'invalid_shipping_address'});
    } else {
      // Replace this with your own custom implementation if needed
      fetch('/calculateShipping', {
        data: JSON.stringify({
          shippingAddress: ev.shippingAddress,
        }),
      } as any)
        .then((response) => response.json())
        .then((result) =>
          ev.updateWith({
            status: 'success',
            shippingOptions: result.supportedShippingOptions,
          })
        );
    }
  }

  confirmDataPayment(paymentIntentId = null, otherPaymentMethod = false, coupon: ICoupon = null, elements: StripeElements = null) {
    const dialog = this.dialog.open(DialogComponent, {
      data: {
        title: 'Completamento ordine',
        message: 'Vuoi confermare ed inviare definitivamente l\'ordine?'
      }
    });
    dialog.afterClosed().subscribe(data => {
      if (data) {
        this.stripeService.getCartItems();
        this.stripeService.createOrder(paymentIntentId, otherPaymentMethod, coupon, elements);
      }
    });
  }

  ngOnDestroy() {
    this.clearTimeout();
  }

}
