import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {

    @Output('on-edit') onEdit: EventEmitter<void> = new EventEmitter<void>();
    @Output('on-remove') onRemove: EventEmitter<void> = new EventEmitter<void>();
    @Output('on-action') onAction: EventEmitter<void> = new EventEmitter<void>();

    @Input() title: string;
    @Input() message: string;
    @Input() type: string;
    @Input() actionTitle: string;
    @Input() edit: boolean = false;
    @Input() remove: boolean = false;
    @Input() action: boolean = false;

    @Input() sourceActionIcon?: string;
    @Input() sourceImg?: string;

    constructor() {
    }

}
