import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {DOCUMENT, isPlatformBrowser, isPlatformServer} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {IComic} from '../interfaces/IComic';
import {IMedia} from '../interfaces/IMedia';
import {environment} from '../../environments/environment';
import * as moment from 'moment';
import {Platform} from '@angular/cdk/platform';
import {IProduct} from '../interfaces/IProduct';
import {IProfilationItem} from '../interfaces/IProfilationItem';
import {IAlbum, Lightbox} from 'ngx-lightbox';
import {AuthService} from './auth.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {DialogAuthComponent} from '../components/dialog-auth/dialog-auth.component';
import {DialogComicComponent} from '../components/dialog-comic/dialog-comic.component';
import {DeviceDetectorService} from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  private _albums: IAlbum[] = [];
  spinnerText = 'Caricamento in corso...';

  constructor(
    private auth: AuthService,
    private toastr: ToastrService,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: any,
    public platform: Platform,
    public router: Router,
    public route: ActivatedRoute,
    private _lightbox: Lightbox,
    private dialog: MatDialog,
    private deviceService: DeviceDetectorService
  ) {
  }

  toast(message: string, title: string = null) {
    this.toastr.success(message, title, {
      progressBar: true,
      positionClass: 'toast-bottom-right'
    });
  }

  error(message: string, title: string = null) {
    this.toastr.error(message, title, {
      progressBar: true,
      positionClass: 'toast-bottom-right',
      extendedTimeOut: 5000
    });
  }

  mediaDrawstyleUrl(drawstyle: IProfilationItem) {

    let url = environment.media + 'profilations/drawstyles/';

    if (drawstyle.filename) {
      url += drawstyle.filename;
    } else if (drawstyle.assets) {
      url += drawstyle.assets + '.jpg';
    } else {
      url = 'assets/images/sfondo.png';
    }

    return url;
  }

  mediaGenreUrl(drawstyle: IProfilationItem) {

    let url = environment.media + 'profilations/genres/';

    if (drawstyle.filename) {
      url += drawstyle.filename;
    } else if (drawstyle.assets) {
      url += drawstyle.assets + '.jpg';
    } else {
      url = environment.media + 'images/background-large.png';
    }

    return url;
  }

  mediaCoverUrl(comic: IComic, is_thumb = false, is_gif = false) {
    if (comic.media) {
      const media: IMedia = comic.media;
      let url = environment.media + 'previews';

      if (is_gif && media.filename_gif) {
        url += media.original_path + media.filename_gif;
      } else if (is_thumb && media.filename) {
        url += media.original_path + 't/' + media.filename;
      } else if (!is_thumb && media.filename) {
        url += media.original_path + media.filename;
      } else if (media.filename) {
        url += media.original_path + media.filename;
      } else {
        url = environment.media + 'images/background-large.png';
      }
      return url;
    }
    return environment.media + 'images/background-large.png';
  }

  mediaFeaturedUrl(comic: IComic, is_thumb = false, is_gif = false) {
    if (comic.media_featured) {
      const media: IMedia = comic.media_featured;
      let url = environment.media + 'featured';

      if (is_gif && media.filename_gif) {
        url += media.original_path + media.filename_gif;
      } else if (is_thumb && media.filename) {
        url += media.original_path + 't/' + media.filename;
      } else if (!is_thumb && media.filename) {
        url += media.original_path + media.filename;
      } else if (media.filename) {
        url += media.original_path + media.filename;
      } else {
        url = environment.media + 'images/background-large.png';
      }
      return url;
    }
    return environment.media + 'images/background-large.png';
  }

  mediaShopFeaturedUrl(product: IProduct) {
    if (product.media_featured) {
      const media: IMedia = product.media_featured;
      let url = environment.media + 'shop-featured';
      if (media.filename_gif) {
        return url + media.original_path + media.filename_gif;
      } else if (media.filename) {
        return url + media.original_path + media.filename;
      } else {
        return url + environment.media + 'images/background-large.png';
      }
    }
    return environment.media + 'images/background-large.png';
  }

  mediaLogoUrl(entity: IComic | IProduct) {
    if (entity.logo) {
      return environment.media + entity.logo.original_path + entity.logo.filename;
    }
    return environment.media + 'images/background-large.png';
  }

  mediaCharacterUrl(entity: IComic | IProduct) {
    if (entity.character) {
      return environment.media + entity.character.original_path + entity.character.filename;
    }
    return environment.media + 'images/background-large.png';
  }

  mediaBackgroundSpecialUrl(entity: IComic) {
    if (entity.background) {
      return environment.media + entity.background.original_path + entity.background.filename;
    }else{
      return this.mediaFeaturedUrl(entity);
    }
    return environment.media + 'images/background-large.png';
  }

  openBrowser(url: string) {
    if (isPlatformBrowser(this.platformId)) {
      window.open(url, '_blank');
    }
  }

  mediaAuthorUrl(author) {
    if (author && author.media) {
      const media = author.media;
      return environment.media + 'users' + media.original_path + media.filename;
    }
    return environment.media + 'artists/auth-' + author.id + '.jpg';
  }

  mediaAttachmentUrl(attachment) {
    if (attachment && attachment.media) {
      const media = attachment.media;
      return environment.media + 'items' + media.original_path + media.filename;
    }
    if (attachment && attachment.filename) {
      const createdAt = moment(attachment['created_at']);
      const segment = this.getDateSegment(createdAt);
      return environment.media + 'attachments' + segment + attachment.filename;
    }
    return environment.media + 'images/background-large.png';
  }

  mediaSliderUrl(slider) {
    if (slider && slider.filename) {
      return environment.media + 'sliders' + slider.original_path + slider.filename;
    }
    return environment.media + 'images/background-large.png';
  }

  private getDateSegment(dd) {
    const day = dd.date() < 10 ? '0' + dd.date() : String(dd.date());
    const month = dd.month() < 9 ? '0' + (dd.month() + 1) : String(dd.month() + 1);
    const year = String(dd.year());

    return '/' + year + '/' + month + '/' + day + '/';
  }

  mediaCoverHorizontalUrl(comic: IComic, is_thumb = false, is_gif = false) {
    if (comic && comic.media) {
      const media: IMedia = comic.media;
      let url = environment.media + 'previews';

      if (is_gif && media.filename_gif) {
        url += media.original_path + media.filename_gif;
      } else if (is_thumb && media.filename_landscape) {
        url += media.original_path + 'tl/' + media.filename_landscape;
      } else if (!is_thumb && media.filename_landscape) {
        url += media.original_path + media.filename_landscape;
      } else if (media.filename) {
        url += media.original_path + media.filename;
      } else {
        url = environment.media + 'images/background-large.png';
      }
      return url;
    }

    return environment.media + 'images/background-large.png';
  }

  mediaUrl(path: string) {
    return environment.media + path;
  }

  isFullScreen(): boolean {
    return !!document.fullscreenElement;
  }

  toggleFullScreen() {
    let elem = this.document.documentElement;
    if (!document.fullscreenElement) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  closeFullScreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }

  isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  isServer(): boolean {
    return isPlatformServer(this.platformId);
  }

  isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  isDesktop(): boolean {
    return this.deviceService.isDesktop();
  }

  isTablet(): boolean {
    return this.deviceService.isTablet();
  }

  isIOS() {
    return this.platform.IOS;
  }

  isAndroid() {
    return this.platform.ANDROID;
  }

  isMac() {
    if (this.isBrowser()) {
      if (window.navigator.userAgent.indexOf('Mac') != -1) {
        return true;
      }
    }
    return false;
  }

  isWindows() {
    if (this.isBrowser()) {
      if (window.navigator.userAgent.indexOf('Windows') != -1) {
        return true;
      }
    }
    return false;
  }

  openStore() {
    if (this.isBrowser()) {
      if (this.isIOS() || this.isMac()) {
        window.open(environment.appleStoreUrl, '_blank');
      } else {
        window.open(environment.androidStoreUrl, '_blank');
      }
    }
  }

  openApp() {
    if (this.isBrowser()) {

    }
  }

  isExpired(date: Date) {

    let countDown = moment(date, 'DD/MM/YYYY HH:mm').toDate().getTime();
    let now = new Date().getTime();
    let distance = countDown - now;

    return distance < 0;
  }

  productCoverUrl(product: IProduct, variantId = 0, index = 0) {
    if (product && product.images && product.images.length && !variantId) {
      const image = product.images[index];
      return image ? image.src : 'assets/images/sfondo.png';
    }

    if (product && variantId && product.variations && product.variations.length) {
      const variation = product.variations.find(variation => variation.id === variantId);
      if (variation && variation.image) {
        return variation ? variation.image['src'] : 'assets/images/sfondo.png';
      }
    }

    return 'assets/images/sfondo.png';
  }

  productAuthorsList(product: IProduct) {
    const authors = [];
    const authorAttributes = ['Sceneggiatore/i', 'Disegnatore/i', 'Colorista/i'];
    product.attributes.forEach((attribute) => {
      if (authorAttributes.indexOf(attribute.name) > -1) {
        authors.push(...attribute.options);
      }
    });
    if (authors.length) {
      return [...new Set(authors)];
    }
    return [];
  }

  loadLightbox(images: any[] = []) {
    this._albums = [];
    images.forEach((image, index) => {
      if (index > 0) {
        this._albums.push({
          src: image.src,
          thumb: image.src
        });
      }
    });
  }

  openLightbox(index = 0): void {
    this._lightbox.open(this._albums, index);
  }

  closeLightbox(): void {
    this._lightbox.close();
  }

  openSubscriptionDialog(
    options = {
      title: 'Sblocca tutti i capitoli',
      message: 'Il capitolo che hai scelto è riservato agli utenti Jundo One. Abbonati subito e sblocca tutti i vantaggi legati al nostro abbonamento!',
      return_url: null
    },
    successMessage: string = 'Abbonamento sottoscritto correttamente'
  ) {
    if (!this.auth.isSubscribed.value) {
      this.openAccessDialog(options).subscribe(() => {
        if (this.auth.isSubscribed.value) {
          this.toast(successMessage);
        }
      });
    }
  }

  openAccessDialog(options = {
    title: 'Sblocca tutti i capitoli',
    message: 'Il capitolo che hai scelto è riservato agli utenti Jundo One. Abbonati subito e sblocca tutti i vantaggi legati al nostro abbonamento!',
    return_url: null
  }): Observable<any> {

    if (!options.return_url) {
      options.return_url = this.router.url;
    }

    const accessDialog: MatDialogRef<DialogAuthComponent> = this.dialog.open(DialogAuthComponent, {
      data: {
        title: options.title,
        content: options.message,
        buttons: [
          {
            label: 'Abbonati a Jundo One',
            class: 'btn-jundo',
            action: () => {
              if (!this.auth.isAuthenticated.value) {
                this.router.navigate(['/access'], {
                  queryParams: {
                    page: 'signup',
                    return_url: options.return_url,
                    subscribe: true
                  }
                });
              } else {
                this.router.navigate(['/subscription'], {
                  queryParams: {
                    return_url: options.return_url
                  }
                });
              }
            }
          }
        ]
      }
    });
    return accessDialog.afterClosed().pipe();
  }

  openSignupDialog(subscribe = false): Observable<any> {
    const accessDialog: MatDialogRef<DialogAuthComponent> = this.dialog.open(DialogAuthComponent, {
      data: {
        title: 'Registrati su Jundo',
        content: 'Per sbloccare tutte le opzioni di un’opera come il salvataggio nella propria collezione, valuatare un’opera o attivarne le notifiche, dovrai registrarti gratutitamente a Jundo.\n' +
          '<br/>' +
          'Se invece vuoi sbloccare tutte le opere e ricevere vantaggi esclusivi abbonati a Jundo One!',
        buttons: [
          {
            label: 'Registrati gratuitamente',
            class: 'btn-outline-jundo',
            action: () => {
              this.router.navigate(['/access'], {
                queryParams: {
                  page: 'signup',
                  return_url: this.router.url,
                  subscribe: subscribe
                }
              });
            }
          },
          {
            label: 'Abbonati a Jundo One',
            class: 'btn-jundo',
            action: () => {
              if (!this.auth.isAuthenticated.value) {
                this.router.navigate(['/access'], {
                  queryParams: {
                    page: 'signup',
                    return_url: this.router.url,
                    subscribe: true
                  }
                });
              } else {
                this.router.navigate(['/subscription-landing'], {
                  queryParams: {
                    return_url: this.router.url
                  }
                });
              }
            }
          }
        ]
      }
    });
    return accessDialog.afterClosed().pipe();
  }

  openModalComic(comicId: number) {

    // Get queryparams
    let state = Object.assign({}, this.route.snapshot.queryParams);

    // Change parameters of url
    state['comic'] = comicId;

    // Create url and navigate to it without reloading
    const url = this.router.createUrlTree([], {relativeTo: this.route, queryParams: state}).toString();
    this.router.navigateByUrl(url).then(() => {
      const dialog = this.dialog.open(DialogComicComponent, {
        data: {comicId},
        panelClass: 'comic-dialog'
      });
      dialog.afterClosed().subscribe(() => {
        setTimeout(() => {
          this.removeQueryParams();
        }, 0);
      });
    });
  }

  removeQueryParams() {
    const urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = {};

    // Naviga alla nuova URL senza i parametri di query
    this.router.navigateByUrl(urlTree);
  }

  isApplePayAvailable() {
    if (this.isBrowser()) {
      if ((window as any).ApplePaySession) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

}
