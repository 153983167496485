<div class="w-100 pt-24 pr-24 pb-24 flex-column justify-content-start d-inline-flex product-item-card">
  <div class="cart-card align-self-stretch justify-content-start d-inline-flex">
    <div class="cart-cover-wrapper position-relative radius4 overflow-hidden pointer" (click)="openProduct(cartItem)">
      <div class="cart-cover flex-column justify-content-center d-inline-flex align-items-center">
        <img [alt]="cartItem.product.name" class="cart-cover-img"
             [src]="ui.productCoverUrl(cartItem.product, cartItem.variantId)"/>
      </div>
      <div class="cart-variant-box"
           *ngIf="cartItem.variantId && !(cartItem.product | isMerchandising) && (cartItem.product.variations | variant:[cartItem.variantId, 'name']) !== coverTypeConstants.CLASSIC">
        <div class="fw-400 text-break text-8 text-center align-self-stretch black-text">Variant</div>
      </div>
      <img class="cart-logo" alt="logo" src="assets/images/logo-compact.svg"/>
    </div>
    <div class="align-self-stretch flex-column justify-content-between d-inline-flex"
         style="flex: 1 1 0; align-items: flex-start; gap: 6px;">
      <p class="align-self-stretch text-18 fw-600 text-break pointer m-0" (click)="openProduct(cartItem)">
        {{cartItem.product.name}}
      </p>
      <p class="align-self-stretch text-18 fw-600 text-break lightyellow-text m-0">
        <span *ngIf="!cartItem.variantId">{{(cartItem.product.price * cartItem.qty) | number: '.2-2'}}€</span>
        <span *ngIf="cartItem.variantId">{{(cartItem | variantPrice) * cartItem.qty | number: '.2-2'}}€</span>
        <span class="grey-text text-14 fw-400 ml-10"
              *ngIf="!(cartItem.product.variations | isVariantRegular:cartItem.variantId)">
                {{cartItem.product.variations | variant:[cartItem.variantId, 'name']}}
            </span>
      </p>
      <div class="align-items-center justify-content-start d-inline-flex" style="gap: 16px;">
        <div class="lightgrey-text text-16 fw-400 text-break">Quantità:</div>
        <div class="justify-content-start align-items-center d-flex" style="gap: 12px;">
          <div class="pointer overflow-hidden justify-content-center align-items-center d-flex"
               (click)="removeQuantity(cartItem)"
               style="width: 20px; height: 20px; background: #272726; border-radius: 36px;">
            <img alt="" src="assets/icon/jundo/minus-small.svg" width="14" height="14"/>
          </div>
          <div style="color: white; font-size: 20px; font-weight: 600; word-wrap: break-word">{{cartItem.qty}}</div>
          <div class="pointer overflow-hidden justify-content-center align-items-center d-flex"
               (click)="addQuantity(cartItem)"
               style="width: 20px; height: 20px; background: #272726; border-radius: 36px;">
            <img alt="" src="assets/icon/jundo/plus-small.svg" width="14" height="14"/>
          </div>
        </div>
      </div>
    </div>
    <img class="pointer" (click)="confirmDelete(cartItem)" src="assets/icon/v2/trash.svg" alt="trash">
  </div>
</div>
