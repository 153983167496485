import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {UiService} from '../../services/ui.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-profile',
  templateUrl: './dialog-profile.component.html',
  styleUrls: ['./dialog-profile.component.scss'],
})
export class DialogProfileComponent {

  form: FormGroup;
  loader: boolean;

  constructor(
    private fb: FormBuilder,
    public auth: AuthService,
    private ui: UiService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      email: [this.auth.user.value.email],
      username: [this.auth.user.value.username, [
        Validators.required
      ]]
    });
    this.form.get('email').disable();
  }


  confirm() {

    if (!this.form.valid) {
      return false;
    }

    this.loader = true;
    const body = Object.assign({}, this.form.value);

    this.auth.updateUser(body).subscribe(this.loadUserData, this.loadUserError);
  }

  loadUserData = () => {
    this.loader = false;
    this.ui.toast('Profilo aggiornato');
    this.dialog.closeAll();
  };

  loadUserError = () => {
    this.loader = false;
  };

}
