export const environment = {
  production: true,

  // siteUrl: 'https://www.dev.jundo.it/',
  siteUrl: 'https://www.jundo.it/',
  siteName: 'Jundo Webtoon Online',

  baseUrl: 'https://jundo.it/app',
  apiUrl: 'https://api.jundo.it/api/',
  // apiUrl: 'https://api.jundo.it/api/',
  // apiUrl: 'http://127.0.0.1:8000/api/',
  appLink: 'http://jundo.app.link',

  media: 'https://d1dqu2eovq69bv.cloudfront.net/',

  validatorUrl: 'https://validator.fovea.cc/v1/validate?appName=it.jundo.app&apiKey=007514d1-7e60-4947-b0cb-c39e7e431257',
  stripeKey: 'pk_live_51IxU6bGfi6MP8yCweEbRfNnGOVc3XXJGQd5LI06AAGLcJd4YQxQO4kvUYOV8km2DIghi4Mp1gZ2WTeMG5GfXC9Mm00oflmOdjn',
  // stripeKey: 'pk_test_51IxU6bGfi6MP8yCwljN5S7vEucG0j7gPJU02260QjT6BZvgjNMyfiNjUdwSngfBy9ZdTuDjZ2saOqd014xLEzFjO00XhdUG0Zd',
  paypalClientId: 'ATeZq-Ay5Yql_cov8NPWgNluOeiolYx-6HkCbRhs4V3f_PAH0c0IVpSNHkJ1jGpMMOHS3QpmMxwRpTVq',

  paypalMonthPlanId: 'P-8B07427676492662VL6SR5LA',
  paypalAnnualPlanId: 'P-7AS20158UB344480CMJEJLGY',

  facebookAppId: '665156907440897',

  firebase: {
    apiKey: 'AIzaSyB3F3n4ydtU23kJZmhmGYcihEEYD_b46D4',
    authDomain: 'jundo-84544.firebaseapp.com',
    projectId: 'jundo-84544',
    storageBucket: 'jundo-84544.appspot.com',
    messagingSenderId: '558545532774',
    appId: '1:558545532774:web:bd710b2b5f724046337aa7',
    measurementId: 'G-DFL2Y287S3'
  },

  appleStoreUrl: 'https://apps.apple.com/it/app/jundo/id1545759268',
  androidStoreUrl: 'https://play.google.com/store/apps/details?id=it.jundo.app&hl=it&gl=US',

  distributionUrl: 'https://d1dqu2eovq69bv.cloudfront.net'
};
