import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {IBadge} from '../interfaces/IBadge';
import {Router} from '@angular/router';
import {FiltersService} from './filters.service';
import {IComic} from '../interfaces/IComic';
import {IAttachment} from '../interfaces/IAttachment';
import * as moment from 'moment';
import {IChapter} from '../interfaces/IChapter';

@Injectable({
  providedIn: 'root'
})
export class UtilityProvider {

  arrayPreventNextCoinRequest: number[] = [];

  constructor(
    private router: Router,
    private filtersService: FiltersService
  ) {
  }

  isArrayEqual(x, y) {
    return _(x).xorWith(y, _.isEqual).isEmpty();
  };

  // add_items_to_array_at_position(old_array, 2, [3,4]);
  itemsInArrayPosition(array, index, new_items) {
    return [...array.slice(0, index), ...new_items, ...array.slice(index)];
  }

  manageBadge(badge: IBadge) {
    if (badge) {
      switch (badge.type) {
        case 'product':
          this.router.navigate(['shop/product/', String(badge.value)]);
          break;
        case 'comic':
          this.router.navigate(['home'], {
            queryParams: {
              comic: String(badge.value)
            }
          });
          break;
        case 'genre':
        case 'drawstyle':
          this.router.navigate(['research'], {
            queryParams: {
              term: badge.label
            }
          });
          break;
      }
    }

  }

  loadScripts(scripts: string[]) {
    const dynamicScripts = scripts;
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
      if (+match === 0) {
        return '';
      } // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : ' ' + match.toUpperCase();
    });
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  progressChapterId(comic: IComic): number {
    let chapterId;

    if (comic.progress && comic.progress.length) {
      const progress: any[] = comic.progress.filter(progressItem => progressItem.status > 0);
      const lastProgress = progress[progress.length - 1];
      chapterId = lastProgress.chapter_id;

      const idx = comic.chapters.findIndex(chapter => chapter.id === lastProgress.chapter_id);
      if (lastProgress.status === 100 && idx > -1 && comic.chapters[idx + 1]) {
        chapterId = comic.chapters[idx + 1].id;
      }

      return chapterId;
    }

    if (
      comic.is_grouped && comic.groups.length &&
      comic.groups[0] && comic.groups[0].chapters.length
    ) {
      chapterId = comic.groups[0].chapters[0].id;
    }

    if (!comic.is_grouped && comic.chapters.length) {
      if (comic.chapters.length) {
        chapterId = comic.chapters[0].id;
      }
    }

    return chapterId;

  }

  getChapterProgressIdx(comic: IComic, chapters: IAttachment[]) {

    if (!comic.progress || !comic.progress.length) {
      return null;
    }

    let progressItems = comic.progress.filter(item => item.status > 0 && (comic.webtoon ? item.status < 98 : item.status < 100));
    if (progressItems && progressItems.length) {

      progressItems.forEach(item => {
        item.group_type_number = Number((item.chapter).replace('chp', ''));
      });

      const progressItemsPublished = progressItems.filter((attachment: IAttachment) => {
        return (moment(attachment.published_at).format() <= moment().format());
      });

      const reorderItems = _.sortBy(progressItemsPublished, [
        (item) => moment(item.published_at).format('YYYY-MM-DD'),
        'group_type_number'
      ]);

      const last = _.last(reorderItems);
      return chapters.findIndex(chapter => chapter.group_type === last.chapter);
    } else {

      const map = _.filter(comic.progress, (progress) => {
        if (comic.webtoon ? (progress.status >= 98) : (progress.status === 100)) {
          return progress.chapter;
        }
      });
      const filtered = _.filter(chapters, (chapter) => {

        const published_at = moment(chapter.published_at, 'YYYY-MM-DD');
        const today = moment().format('YYYY-MM-DD');
        const inProgress = !!(map.find(progress => progress.chapter === chapter.group_type));
        const isSoon = published_at.isAfter(today);

        return !(inProgress) && !(isSoon);
      });

      filtered.forEach(item => {
        item.group_type_number = Number((item.group_type).replace('chp', ''));
      });

      const reorderItems = _.sortBy(filtered, [
        (item) => moment(item.published_at).format('YYYY-MM-DD'),
        'group_type_number'
      ]);

      if (reorderItems.length) {
        const firstUnreaded = _.first(reorderItems);
        const chp = _.replace(firstUnreaded.group_type, 'chp', '');
        return Number(chp) === 0 ? null : Number(chp);
      }
    }
    return 0;
  }

  getChapters(comic: IComic): IAttachment[] {
    const chapters = _.uniqBy(comic.attachments, 'group_type');

    chapters.forEach(chapter => {
      chapter.group_type_number = Number((chapter.group_type).replace('chp', ''));
    });

    const chaptersPublished = chapters.filter((attachment: IAttachment) => {
      if (moment(attachment.published_at).format() <= moment().format()) {
        return attachment;
      }
    });

    const chaptersSoon = chapters.filter((attachment: IAttachment) => {
      if (moment(attachment.published_at).format() > moment().format()) {
        return attachment;
      }
    });

    const publishOrderList = _.sortBy(chaptersPublished, [
      (item) => moment(item.published_at).format('YYYY-MM-DD'), 'group_type_number'
    ]);

    const chaps = [];
    chaps.push(...publishOrderList);
    chaps.push(...chaptersSoon.slice(0, 3));

    return chaps;
  }

  getProgress(comic: IComic): number[] {

    const progress = [];

    const reduceAttachments = comic.attachments.reduce(
      (a, c) => (a[c.group_type] = (a[c.group_type] || []).concat(c), a), {}
    );
    for (const key in reduceAttachments) {
      if (reduceAttachments.hasOwnProperty(key) && comic.progress) {
        comic.progress.forEach(res => {
          if (res.chapter === key) {
            progress[key] = res.status;
          }
        });
      }
    }

    return progress;
  }

  //return a promise that resolves with a File instance
  urltoFile(url, filename, mimeType) {
    return (fetch(url)
        .then((res) => {
          return res.arrayBuffer();
        })
        .then((buf) => {
          return new File([buf], filename, {type: mimeType});
        })
    );
  }

  //return mime Type of bs64
  base64MimeType(encoded) {
    let result = null;
    if (typeof encoded !== 'string') {
      return result;
    }

    const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mime && mime.length) {
      result = mime[1];
    }
    return result;
  }

  isValidUrl(url) {
    let regEx = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm;
    return regEx.test(url);
  }

  isLockChapter(chapter: IChapter): boolean {
    if (chapter.comic.is_grouped) {
      let chapterIndex = chapter.group.chapters.findIndex(chapterItem => chapterItem.id === chapter.id);
      return (chapterIndex + 1) >= chapter.group.paywall;
    } else {
      let chapterIndex = chapter.comic.chapters.findIndex(chapterItem => chapterItem.id === chapter.id);
      return (chapterIndex + 1) >= chapter.comic.paywall;
    }
  }

  isPurchasedChapter(chapter: IChapter): boolean {
    return chapter.comic.is_grouped ? !!chapter.group.purchase : !!chapter.purchase;
  }

  chapterIdx(chapter: IChapter): number {
    return chapter.comic.chapters.findIndex(chapterItem => chapterItem.id === chapter.id);
  }

  chapterGroupIdx(chapter: IChapter): number {
    return chapter.group.chapters.findIndex(chapterItem => chapterItem.id === chapter.id);
  }

  navigateAccessSubscription(url = 'subscription') {
    const return_url = this.router.url;
    const queryParams = {return_url, subscribe: true};
    this.router.navigate([url], {queryParams});
  }

  inArrayPreventRequest(comicId: number): boolean {
    return this.arrayPreventNextCoinRequest.includes(comicId);
  }

  addInArrayPreventRequest(comicId: number): void {
    this.arrayPreventNextCoinRequest.push(comicId);
  }
}
