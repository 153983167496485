<h1 mat-dialog-title>Modifica profilo</h1>
<div mat-dialog-content>

  <form *ngIf="auth.user.value" [formGroup]="form" (ngSubmit)="confirm()" novalidate>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
        <app-input [label]="'Email'"
                   [model]="form.controls['email']"
                   [type]="'email'"
        ></app-input>
      </div>
      <div class="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
        <app-input [label]="'Username'"
                   [model]="form.controls['username']"
        ></app-input>
      </div>
    </div>
  </form>


</div>
<mat-dialog-actions align="end">
  <button class="btn btn-outline-jundo mr-20" [mat-dialog-close]="false">Annulla</button>
  <button class="btn btn-jundo mr-10" (click)="confirm()">Conferma</button>
</mat-dialog-actions>
