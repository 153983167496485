import {Pipe, PipeTransform} from '@angular/core';
import {CartService} from '../services/cart.service';
import {ICoupon} from '../interfaces/ICoupon';

@Pipe({
  name: 'couponAmount'
})
export class CouponAmountPipe implements PipeTransform {

  constructor(private cartService: CartService) {
  }

  transform(coupon: ICoupon, args?: any): number {

    if (!coupon) {
      return 0;
    }
    let val = 0;

    if (coupon.discount_type === 'percent') {
      this.cartService.getShoppingItemAmount().subscribe(data => {
        val = (data / 100) * coupon.amount;
      });
    } else {
      val = coupon.amount;
    }

    return val;
  }
}
