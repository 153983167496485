import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {Platform} from '@angular/cdk/platform';
import {environment} from '../../environments/environment';
import {IComic} from '../interfaces/IComic';
import {IDataPaginate} from '../interfaces/IDataPaginate';
import {ICarousel} from '../interfaces/ICarousel';
import {IProfilationItem} from '../interfaces/IProfilationItem';
import {IAvatar} from '../interfaces/IAvatar';
import {IChapter} from '../interfaces/IChapter';

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  url: string;
  tempStorageComics: IComic[];

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private platform: Platform
  ) {
    this.url = environment.apiUrl;
  }

  profilationList(): Observable<ICarousel[]> {
    return this.http.get<ICarousel[]>(this.url + 'profilation', this.getOptions());
  }

  search(searchBody, page = 1) {
    return this.http.post(this.url + 'searchAll?page=' + page, searchBody, this.getOptions());
  }

  comingSoonChapters() {
    return this.http.get(this.url + 'comingSoonChapters');
  }

  authors(body) {
    return this.http.post(this.url + 'authors', body, this.getOptions());
  }

  latency(pesoFile, latenza) {
    // Calcola la velocità di upload approssimativa (in byte al secondo)
    var velocitaUpload = 1 / latenza;

    // Calcola la durata approssimativa dell'upload (in secondi)
    var durataUpload = pesoFile / velocitaUpload;

    // Converte la durata in minuti e secondi
    var minuti = Math.floor(durataUpload / 60);
    var secondi = Math.round(durataUpload % 60);

    return minuti + ' minuti e ' + secondi + ' secondi';
  }

  comic(id): Observable<IComic> {
    return this.http.get<IComic>(this.url + 'v2/comics/' + id, this.getOptions());
  }

  comics(): Observable<IComic[]> {
    return this.http.get<IComic[]>(this.url + 'v2/comics', this.getOptions());
  }

  comicBySlug(slug: string): Observable<IComic> {
    return this.http.get<IComic>(this.url + 'v2/comicBySlug/' + slug, this.getOptions());
  }

  categories() {
    return this.http.get(this.url + 'categories', this.getOptions());
  }

  drawstyles(): Observable<IProfilationItem[]> {
    return this.http.get<IProfilationItem[]>(this.url + 'drawstyles', this.getOptions());
  }

  genres(): Observable<IProfilationItem[]> {
    return this.http.get<IProfilationItem[]>(this.url + 'genres', this.getOptions());
  }

  favoritesItems() {
    return this.http.get(this.url + 'favoritesItems', this.getOptions());
  }

  readLaterItems() {
    return this.http.get(this.url + 'readLaterItems', this.getOptions());
  }


  toggleFavorite(itemId) {
    return this.http.get(this.url + 'toggleFavorite/' + itemId, this.getOptions());
  }

  toggleReadLater(itemId) {
    return this.http.get(this.url + 'toggleReadLater/' + itemId, this.getOptions());
  }

  like(itemId) {
    return this.http.get(this.url + 'like/' + itemId, this.getOptions());
  }

  dislike(itemId) {
    return this.http.get(this.url + 'dislike/' + itemId, this.getOptions());
  }

  updateStatus(body) {
    return this.http.post(this.url + 'updateStatus', body, this.getOptions());
  }

  saveUserStyles(body) {
    return this.http.post(this.url + 'styles', body, this.getOptions());
  }

  saveUserGenres(body) {
    return this.http.post(this.url + 'genres', body, this.getOptions());
  }

  avatarList(): Observable<IAvatar[]> {
    return this.http.get<IAvatar[]>(this.url + 'avatarList', this.getOptions());
  }

  conversion(body: { name: string, value: string | number }) {
    return this.http.post(this.url + 'conversionApi', body, this.getOptions());
  }

  hideProgress(comicId: number) {
    return this.http.get(this.url + 'hideProgress/' + comicId, this.getOptions());
  }

  carouselByHook(hookId: string): Observable<ICarousel[]> {
    return this.http.get<ICarousel[]>(this.url + 'carouselByHook/' + hookId, this.getOptions());
  }

  dynamic(api: string, page = 1): Observable<IDataPaginate> {
    return this.http.get<IDataPaginate>(this.url + 'dynamic/' + api + '?page=' + page, this.getOptions());
  }

  carousel(api: string): Observable<IDataPaginate> {
    return this.http.get<IDataPaginate>(this.url + 'v2/carousels/' + api, this.getOptions());
  }

  getSearchSuggest(): Observable<any> {
    return this.http.get<any>(this.url + 'getSearchSuggest', this.getOptions());
  }

  getShopSuggest(): Observable<any> {
    return this.http.get<any>(this.url + 'getShopSuggest', this.getOptions());
  }

  soon():Observable<IChapter[]> {
    return this.http.get<IChapter[]>(this.url + 'v2/soon', this.getOptions());
  }

  matureInfo() {
    return this.http.get(this.url + 'matureInfo');
  }


  getOptions() {
    return {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.access_token.value),
    };
  }

}
