import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IUser} from '../../interfaces/IUser';
import {AuthService} from '../../services/auth.service';
import {UiService} from '../../services/ui.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-password',
  templateUrl: './dialog-password.component.html',
  styleUrls: ['./dialog-password.component.scss'],
})
export class DialogPasswordComponent {

  form: FormGroup;
  loader: boolean;
  user: IUser;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private ui: UiService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {

    const formObj = {
      old_password: ['', [
        Validators.required,
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$')
      ]],
      new_password: ['', [
        Validators.required,
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$')
      ]]
    };

    this.form = this.fb.group(formObj);
  }


  confirm() {

    if (!this.form.valid) {
      return false;
    }

    this.loader = true;
    const body = Object.assign({}, this.form.value);

    this.auth.editPassword(body).subscribe(this.loadUserData, this.loadUserError);
  }

  loadUserData = () => {
    this.loader = false;
    this.ui.toast('Password aggiornata');
    this.dialog.closeAll();
  };

  loadUserError = () => {
    this.loader = false;
  };

}
