<!--<mat-progress-bar class="e-progress-bar"
                  mode="indeterminate"
                  *ngIf="authService.pendingRequestCount"></mat-progress-bar>-->

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)"
             size="medium"
             color="#fff"
             type="ball-scale-ripple-multiple"
             [fullScreen]="true">
  <p class="white-text"> {{ui.spinnerText}} </p>
</ngx-spinner>

<!--<app-top-bar></app-top-bar>-->

<mat-drawer-container class="drawer-container black" autosize>

  <app-navbar *ngIf="ui.isDesktop() && !ui.isMobile() && !ui.isTablet()"
              (openCart)="openCart()"
              (onDrawer)="toggleDrawer()"></app-navbar>

  <div class="main">
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>

  <mat-drawer #drawerCart class="drawer-sidenav cart-bg position-fixed"
              *ngIf="ui.isBrowser()"
              mode="over"
              position="end">
    <app-sidenav-cart (on-close)="drawerCart.close()"></app-sidenav-cart>
  </mat-drawer>

</mat-drawer-container>
