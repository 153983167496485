<!-- SINGLE -->
<ng-container *ngIf="comic && !comic.is_grouped">
  <div class="row">
    <div class="col-12" *ngFor="let chapterItem of comic.chapters; let i = index;">
      <div class="row"
           *ngIf="chapterItem.comic && i + 1 === comic.paywall && !comic.is_coin && !auth.isSubscribed.value">
        <div class="col-12">
          <button class="btn btn-jundo w-100 mt-10 mb-10" (click)="utility.navigateAccessSubscription('/subscription/method')">
              <span class="black-text">
                  {{chapterItem.comic.is_coin ? 'Abbonati e ottieni Jundo Coins gratuiti' : 'Abbonati e sblocca tutti i capitoli'}}
              </span>
          </button>
        </div>
        <div class="col-12">
          <button class="btn btn-outline-jundo w-100 mt-10 mb-10" (click)="utility.navigateAccessSubscription()">
            Scopri i vantaggi di Jundo One
          </button>
        </div>
      </div>
      <app-comic-status-bar [chapter]="chapterItem" (on-refresh)="onRefresh()"></app-comic-status-bar>
    </div>
  </div>
</ng-container>


<!-- GROUP -->
<ng-container *ngIf="comic && comic.is_grouped">
  <mat-accordion class="black-light">
    <ng-container *ngFor="let group of comic.groups; let i = index;">
      <div class="row" *ngIf="i + 1 === comic.paywall && !comic.is_coin && !auth.isSubscribed.value">
        <div class="col-12">
          <button class="btn btn-jundo w-100 mt-10"
                  (click)="utility.navigateAccessSubscription('/subscription/method')">
            Abbonati e ottieni Jundo Coins gratuiti
          </button>
        </div>
        <div class="col-12">
          <button class="btn btn-outline-jundo mt-10 w-100" (click)="utility.navigateAccessSubscription()">
            Scopri i vantaggi di Jundo One
          </button>
        </div>
      </div>
      <mat-expansion-panel class="expansion-panel-group mt-20">
        <mat-expansion-panel-header>
          <mat-panel-title class="white-text">
            <!--<mat-icon class="mr-10">web_stories</mat-icon>{{group.title}}-->
            <div class="card-status-bar-image-box d-inline-flex align-items-center">
              <img alt="" [src]="group | chapterGroupMedia"/>
            </div>
            <div class="card-status-bar-info flex-column pl-20">
              <div class="card-status-bar-title text-16 text-truncate" [title]="group.title">
                {{group.title}}
              </div>
              <div class="card-status-bar-description text-14 grey-text" *ngIf="group.chapters.length">
                {{group.chapters.length}}&nbsp;Capitoli
              </div>
            </div>
            <div class="card-status-label text-right text-14 pr-20">
              <!--<span *ngIf="!group.coins" class="yellow-text">Gratuito</span>-->
              <span *ngIf="group.coins && !group.purchase" class="yellow-text bolder">
                            {{group.coins}}&nbsp;<img alt="" src="assets/icon/v2/coin.svg"/>
                        </span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row mt-20">
          <div class="col-12" *ngFor="let chapterItem of group.chapters; let i = index;">
            <app-comic-status-bar [chapter]="chapterItem" (on-refresh)="onRefresh()"></app-comic-status-bar>
          </div>
        </div>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>

</ng-container>
