<div class="no-decoration pointer mt-8" (click)="reader()">
  <div class="card-status-bar pr-16 w-100"
       *ngIf="chapter && chapter.comic"
       [ngClass]="[selected ? 'selected' : '', chapter.comic && chapter.comic.is_grouped ? 'chapter-grouped' : '']">
    <div class="card-status-bar-image-box d-inline-flex align-items-center">
      <img alt="" [src]="chapter | chapterMedia"/>
    </div>
    <div class="card-status-bar-info flex-column">
      <div class="card-status-bar-title text-16 text-truncate white-text"
           [title]="chapter.title">{{chapter.title}}</div>
      <div class="card-status-bar-description text-14 grey-text">
        <span class="grey-text" *ngIf="published_at && isNew">{{published_at | formatDate}}</span>
        <span *ngIf="!isNew && !chapter.comic.webtoon && chapter.attachments_count">
                    {{chapter.attachments_count}} pagine
                </span>
      </div>
    </div>

    <ng-container *ngIf="!isNew">
      <ng-container *ngIf="chapter.comic.is_coin">

        <!-- Completato -->
        <ng-container *ngIf="(isLock && isPurchased || !isLock) && completed">
          <div class="card-status-label text-right text-14 yellow-text no-select">Completato</div>
        </ng-container>

        <ng-container *ngIf="!chapter.group">

          <!-- Gratuito -->
          <ng-container *ngIf="!isLock && !isPurchased && !completed && !auth.isSubscribed.value">
            <div class="card-status-label text-right text-14 lightyellow-text no-select">Gratuito</div>
          </ng-container>

          <!-- Coin -->
          <ng-container *ngIf="isLock && !isPurchased && chapter.coins">
            <div class="card-status-label-coins text-right text-14 no-select">
                            <span class="yellow-text bolder">{{chapter.coins}}&nbsp;
                                <img alt="" src="assets/icon/v2/coin.svg"/></span>
            </div>
          </ng-container>

        </ng-container>
        <ng-container *ngIf="chapter.group">

          <!-- Lock -->
          <ng-container *ngIf="chapter.group.coins && !isPurchased && isLock">
            <div class="card-status-icon position-relative no-select" style="width: 24px; height: 24px;">
              <img alt="" src="assets/icon/jundo/lock.svg"/>
            </div>
          </ng-container>

          <!-- Gratuito -->
          <ng-container
            *ngIf="!auth.isSubscribed.value && !isPurchased && (!isLock || (isLock && !chapter.coins && !chapter.group.coins) || (chapter.group.coins && !isLock))">
            <div class="card-status-label text-right text-14 lightyellow-text no-select">Gratuito</div>
          </ng-container>

          <!-- Coin -->
          <ng-container *ngIf="!chapter.group.coins && !isPurchased && chapter.coins">
            <div class="card-status-label-coins text-right text-14 no-select">
                            <span class="yellow-text bolder">{{chapter.coins}}&nbsp;
                                <img alt="" src="assets/icon/v2/coin.svg"/></span>
            </div>
          </ng-container>

        </ng-container>
      </ng-container>
      <ng-container *ngIf="!chapter.comic.is_coin">

        <!-- Gratuito -->
        <ng-container *ngIf="!isLock && !isPurchased && !completed && !auth.isSubscribed.value">
          <div class="card-status-label text-right text-14 lightyellow-text no-select">Gratuito</div>
        </ng-container>

        <!-- Lock -->
        <ng-container *ngIf="isLock && !auth.isSubscribed.value">
          <div class="card-status-icon position-relative no-select" style="width: 24px; height: 24px;">
            <img alt="" src="assets/icon/jundo/lock.svg"/>
          </div>
        </ng-container>

        <!-- Completato -->
        <ng-container *ngIf="(isLock && auth.isSubscribed.value || !isLock) && completed">
          <div class="card-status-label text-right text-14 yellow-text no-select">Completato</div>
        </ng-container>

      </ng-container>
    </ng-container>

    <ng-container *ngIf="isNew">
      <div class="card-status-icon position-relative no-select" style="width: 24px; height: 24px;">
        <img alt="" src="assets/icon/jundo/lock.svg"/>
      </div>
    </ng-container>

  </div>
  <mat-progress-bar *ngIf="chapter && progressPerc > 2 && !selected" [value]="progressPerc" mode="determinate"
                    class="status-item-progress"></mat-progress-bar>

</div>
