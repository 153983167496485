import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IComic} from '../../interfaces/IComic';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {UtilityProvider} from '../../services/utility.service';

@Component({
  selector: 'app-chapters-group-data',
  templateUrl: './chapters-group-data.component.html',
  styleUrls: ['./chapters-group-data.component.scss'],
})
export class ChaptersGroupDataComponent {

  @Input() comic: IComic;

  @Output('on-refresh') onRefreshData: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public auth: AuthService,
    private router: Router,
    public utility: UtilityProvider
  ) {

  }

  onRefresh() {
    this.onRefreshData.emit();
  }

  goSummaryPage() {
    let path = '/subscription/summary';
    if (!this.auth.isAuthenticated.value) {
      path = '/access';
    }
    this.router.navigate([path], {
      queryParams: {
        return_url: this.router.url,
        subscribe: true
      }
    });
  }

  goLandingPage() {
    this.router.navigate(['/subscription'], {
      replaceUrl: true,
      queryParams: {
        return_url: this.router.url,
        subscribe: true
      }
    });
  }

}
