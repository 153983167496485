import {Component, Inject, Input, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html'
})
export class SkeletonComponent {

  theme: any;
  @Input() height: string = '50px';
  @Input() width: string = '100%';
  @Input() radius: string = '5px';

  isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit() {
    this.theme = {
      'border-radius': this.radius,
      'height': this.height,
      'width': this.width,
      'background-color': '#232323'
    };
  }

  onResize($event: Event) {
    if ($event && $event.target) {
      const width = $event.target['innerWidth'];
      this.theme.width = width < 500 ? '100%' : this.width;
    }
  }

}
