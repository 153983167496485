import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-dialog-coupon',
  templateUrl: './dialog-coupon.component.html',
  styleUrls: ['./dialog-coupon.component.scss'],
})
export class DialogCouponComponent {

  form: FormGroup;
  loader: boolean;

  constructor(
    private fb: FormBuilder,
    public auth: AuthService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      coupon: [null, [Validators.required]]
    });
  }


  confirm() {

    if (!this.form.valid) {
      return false;
    }

    this.loader = true;
    const body = Object.assign({}, this.form.value);

    this.router.navigate(['/coupon'], {
      queryParams: {
        code: body.coupon,
      }
    });
  }

}
