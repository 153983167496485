import {Component} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {PermissionsConstant} from '../../constants/permissions.constant';
import {DialogComponent} from '../dialog/dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {IUser} from '../../interfaces/IUser';
import {filter} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss']
})
export class AccountMenuComponent {

  isAdmin = false;
  isEditor = false;

  menu = [
    {label: 'Overview', path: '/account/overview', hideToggle: true, children: []},
    {
      label: 'Account', path: '', hideToggle: false, children: [
        {label: 'Email e password', path: '/account/edit'},
        {label: 'Avatar', path: '/account/avatar'},
        {label: 'Stili e generi', path: '/account/profilation'},
        {label: 'Metodo di pagamento', path: '/account/payment'},
        {label: 'Indirizzo di spedizione', path: '/account/shipping'},
        {label: 'Elimina account', path: '/account/cancellation'},
      ]
    },
    {label: 'Abbonamento', path: '/account/subscription', hideToggle: true, children: []},
    {label: 'Jundo Coins', path: '/account/coins', hideToggle: true, children: []},
    {label: 'Ordini', path: '/account/orders', hideToggle: true, children: []},
    // {label: 'Notifiche', path: '/notification', hideToggle: true, children: []},
    {
      label: 'Assistenza', path: '', hideToggle: false, children: [
        {label: 'FAQ', path: '/account/support/faq'},
        {label: 'Contattaci', path: '/account/support/contact-us'},
        // {label: 'Contattaci', path: '/faq'},
      ]
    },
    {
      label: 'Informazioni legali', path: '', hideToggle: false, children: [
        {label: 'Termini e condizioni', path: null, pathUrl: 'https://www.iubenda.com/termini-e-condizioni/93467398'},
        {label: 'Privacy Policy', path: null, pathUrl: 'https://www.iubenda.com/privacy-policy/93467398/full-legal'},
        {label: 'Cookies Policy', path: null, pathUrl: 'https://www.iubenda.com/privacy-policy/93467398/cookie-policy'},
      ]
    },
  ];

  isAccountToggle = false;
  isSupportToggle = false;
  isLegalToggle = false;

  constructor(
    public auth: AuthService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const url = event.url;

      this.isAccountToggle =
        url.search('account/edit') > -1 ||
        url.search('account/profilation') > -1 ||
        url.search('account/payment') > -1 ||
        url.search('account/shipping') > -1 ||
        url.search('account/avatar') > -1 ||
        url.search('account/cancellation') > -1;

      this.isSupportToggle =
        url.search('account/support/faq') > -1 ||
        url.search('account/support/contact-us') > -1;

    });
  }

  ngOnInit() {
    this.auth.profile().subscribe((user: IUser) => {
      this.isAdmin = !!(user.permissions.find(permission => permission.permission_code === PermissionsConstant.ADMIN.permission_code));
      this.isEditor = !!(user.permissions.find(permission => permission.permission_code === PermissionsConstant.EDITOR.permission_code));
    });
  }

  disconnectRequest = () => {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Vuoi disconnetterti dal tuo account Jundo?'
      }
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.auth.logout();
      }
    });
  };

  isHideToggle(menuItem: any) {

    if (menuItem.label === 'Account') {
      return this.isAccountToggle;
    }
    if (menuItem.label === 'Assistenza') {
      return this.isSupportToggle;
    }
    if (menuItem.label === 'Informazioni legali') {
      return this.isLegalToggle;
    }

    return false;
  }
}
