import {Component, ViewChild} from '@angular/core';
import {AuthService} from './services/auth.service';
import {CartService} from './services/cart.service';
import {IUser} from './interfaces/IUser';
import {MatDialog} from '@angular/material/dialog';
import {NavigationEnd, Router} from '@angular/router';
import {FiltersService} from './services/filters.service';
import {MatDrawer} from '@angular/material/sidenav';
import {UiService} from './services/ui.service';
import {ChannelsService} from './services/channels.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent {

  @ViewChild('drawerCart', {static: false}) drawerCart?: MatDrawer;

  detectDrawer = false;

  constructor(
    public auth: AuthService,
    public cartService: CartService,
    public ui: UiService,
    public filtersService: FiltersService,
    private matDialog: MatDialog,
    private router: Router,
    private channels: ChannelsService
  ) {
  }

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.matDialog.closeAll();

        // gtag('config','UA-{ID}',{'page_path' : event.url});
        // fbq('track', 'PageView');

      }
    });

    this.filtersService.initializeFilters();
    this.cartService.initializeCart();

    const access_token = localStorage.getItem(this.auth.STORAGE_TOKEN_KEY);
    if (access_token) {
      this.auth.access_token.next(access_token);

      this.auth.profile().subscribe(
        (user: IUser) => {
          this.auth.user.next(user);
          this.auth.isAuthenticated.next(true);
          this.auth.createAccess();
        })
    }
  }

  toggleDrawer() {
    this.detectDrawer = !this.detectDrawer;
  }

  openCart() {
    this.drawerCart.toggle();
    this.channels.publish('cart:updated', {});
  }
}
