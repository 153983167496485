import * as fromCart from '../actions/cart.actions';
import * as _ from 'lodash';
import {ICart} from '../interfaces/ICart';

export interface CartState {
  data: ICart[];
}

export const initialState: CartState = {
  data: []
};

export function reducer(
  state = initialState,
  action: fromCart.ActionsUnion
): CartState {
  switch (action.type) {

    case fromCart.ActionTypes.AddShoppingItem: {

      let elem = getIndexProductCart(action, state);

      if (action.payload.item.product.type === 'bundle') {
        elem = getIndexProductBundleCart(action, state);
      }

      let {idx, tempData} = elem;

      if (idx > -1) {
        tempData[idx].qty = tempData[idx].qty + action.payload.item.qty;
      } else {
        tempData = [...state.data, action.payload.item];
      }

      localStorage.setItem('jundo-cart', JSON.stringify(tempData));
      return {...state, data: tempData};
    }

    case fromCart.ActionTypes.DeleteShoppingItem: {

      let elem = getIndexProductCart(action, state);

      if (action.payload.item.product.type === 'bundle') {
        elem = getIndexProductBundleCart(action, state);
      }

      let {idx, tempData} = elem;

      if (idx > -1) {

        if (action.payload.item.qty && tempData[idx].qty > 1) {
          const res = tempData[idx].qty - action.payload.item.qty;
          if (res > 0) {
            tempData[idx].qty = res;
          } else {
            if (action.payload.item.product.type === 'bundle') {
              tempData = removeItemFromBundleCart(action, state);
            } else {
              tempData = removeItemFromCart(action, state);
            }

          }
        } else {
          if (action.payload.item.product.type === 'bundle') {
            tempData = removeItemFromBundleCart(action, state);
          } else {
            tempData = removeItemFromCart(action, state);
          }
        }
      }

      localStorage.setItem('jundo-cart', JSON.stringify(tempData));
      return {...state, data: tempData};
    }

    case fromCart.ActionTypes.InitializeCart: {

      const cartStorage = localStorage.getItem('jundo-cart');
      const newData = cartStorage ? JSON.parse(cartStorage) : [];

      return {
        ...state,
        data: newData
      };
    }

    case fromCart.ActionTypes.ResetCart: {

      localStorage.removeItem('jundo-cart');

      return {
        ...state,
        data: []
      };
    }

    default: {
      return state;
    }
  }
}

const removeItemFromCart = (action, state) => {
  const payload = action.payload.item;
  const filtered = state.data.filter((item: ICart) => !!(
    (payload.variantId && !item.variantId) ||
    (!payload.variantId && item.variantId) ||
    (payload.product.id !== item.product.id) ||
    (payload.product.id === item.product.id && payload.variantId !== item.variantId)
  ));
  return [...filtered];
};

const removeItemFromBundleCart = (action, state) => {
  const payload: ICart = action.payload.item;
  const filtered = state.data.filter((item: ICart) => payload.product.id !== item.product.id);
  return [...filtered];
};

const getIndexProductCart = (action, state): { idx: number, tempData: any } => {
  let tempData = _.cloneDeep(state.data);

  const idx = tempData.findIndex((item: ICart) =>
    item.product.id === action.payload.item.product.id && item.variantId === action.payload.item.variantId && item.product.type !== 'bundle'
  );

  return {idx, tempData};
};

const getIndexProductBundleCart = (action, state): { idx: number, tempData: any } => {
  let tempData = _.cloneDeep(state.data);

  const idx = tempData.findIndex((item: ICart) =>
    item.product.id === action.payload.item.product.id &&
    JSON.stringify(item.variants) === JSON.stringify(action.payload.item.variants) &&
    item.product.type === 'bundle'
  );

  return {idx, tempData};
};

export const getItems = (state: CartState) => {
  return state.data;
};

export const getItemsQuantity = (state: CartState) => {
  let qty = 0;
  state.data.forEach(item => qty += item.qty);
  return qty;
};

export const getItemsAmount = (state: CartState) => {
  let amount = 0;
  state.data.forEach(item => {
    if (item.variantId && item.product && item.product.variations && item.product.variations.length) {
      const variation = item.product.variations.find(variation => variation.id === item.variantId);
      amount += item.qty * Number(variation.price);
    } else {
      amount += item.qty * Number(item.product.price);
    }
  });
  return amount;
};

export const getItemById = (state: CartState, props: { id: number, variantId: number }) =>
  state.data.find(item => (
    item.product.id === props.id &&
    (props.variantId ? item.variantId === props.variantId : true)
  ));

export const getItemByIdVariant = (state: CartState, props: { id: number, variantId: number }) =>
  state.data.find(item => (
    item.product.id === props.id && (
      item.product.variations
    ).find(variation => variation.id === props.variantId)));
