<div class="mt-40" *ngIf="carousel" [ngStyle]="carousel.hex ? {'background': (carousel.hex | carouselBackground)} : {}">

  <div class="title-box pt-20 ml-55 mb-0 no-select">
    <img class="carousel-icon mr-20" *ngIf="carousel.filename" alt="" style="width: 120px"
         [src]="environment.media + carousel.original_path + carousel.filename"/>
    <span class="fw-600 text-22">{{carousel.title}}</span>
  </div>

  <swiper
    [cssMode]="false"
    [navigation]="true"
    [pagination]="false"
    [mousewheel]="false"
    [keyboard]="false"
    [initialSlide]="0"
    [slidesPerView]="'auto'"
    [spaceBetween]="30"
    [freeMode]="true"
  >
    <ng-template swiperSlide class="spacer-slider" *ngIf="firstSlide"></ng-template>
    <ng-template swiperSlide class="slide mt-20 mb-20 no-select" *ngFor="let comic of values;let i = index;">
      <a class="no-decoration"
         [routerLink]="'/home'"
         [queryParams]="{comic: comic.id}" aria-label="Maggiori informazioni su {{comic.title}}">
        <div class="comic-card text-14"
             [ngClass]="i === 0 ? 'position-left' : (i === values.length - 1 ? 'position-right' : '')">

          <img class="character-img" alt="" [src]="ui.mediaCharacterUrl(comic)" *ngIf="comic.character">
          <img class="logo-img" alt="" [src]="ui.mediaLogoUrl(comic)" *ngIf="comic.logo">

          <div class="row">
            <div class="col-12 small">
              <div class="logo-wrapper text-center"></div>
              <img class="comic-card-img" alt="" [src]="ui.mediaBackgroundSpecialUrl(comic)">
            </div>
          </div>
        </div>
      </a>
    </ng-template>
    <ng-template swiperSlide class="spacer-slider"></ng-template>
    <ng-template swiperSlide class="spacer-slider"></ng-template>
  </swiper>
</div>
