<div class="preview-box black-light" [ngStyle]="{'width': width}">
  <div class="align-self-stretch justify-content-start align-items-center d-inline-flex gap-3">

    <div class="preview-title">{{title}}</div>

    <div class="preview-image-box" *ngIf="editable">
      <img class="position-absolute p-left-0 p-top-0 pointer"
           src="assets/icon/v2/account-edit.svg" alt="edit" (click)="onEdit.emit()"/>
    </div>

    <div class="preview-image-box" *ngIf="openable">
      <img class="position-absolute p-left-0 p-top-0 pointer"
           src="assets/icon/v2/caret-right.svg" alt="edit" (click)="onOpen.emit()"/>
    </div>

  </div>
  <div class="preview-messages-box">
    <div class="align-self-stretch" *ngFor="let item of messages;" [hidden]="!item.message">
      <span class="preview-label">{{item.label}}</span>
      <span class="preview-message" [innerHtml]="item.message"></span>
    </div>
  </div>
</div>
