import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {orderStatus} from './order-status.pipe';
import {orderStatusColor} from './order-status-color.pipe';
import {FormatDatePipe} from './format-date.pipe';
import {CapitalizePipe} from './capitalize.pipe';
import {StripStringPipe} from './strip-string.pipe';
import {AuthorsPipe} from './authors.pipe';
import {ProgressPipe} from './progress.pipe';
import {ReadingPipe} from './reading.pipe';
import {IsFavoritePipe} from './is-favorite.pipe';
import {IsReadLaterPipe} from './is-read-later.pipe';
import {GenreLabelPipe} from './genreLabel.pipe';
import {DrawstyleLabelPipe} from './drawstyleLabel.pipe';
import {IsOriginalPipe} from './is-original.pipe';
import {ProductTitleTagPipe} from './product-title-tag.pipe';
import {IsPreorderPipe} from './is-preorder.pipe';
import {CheckBoolPipe} from './check-bool.pipe';
import {jsonCartValuePipe} from './json-cart-value.pipe';
import {PermissionsLabelPipe} from './permissions-label.pipe';
import {InstagramProfilePipe} from './instagram-profile.pipe';
import {FacebookProfilePipe} from './facebook-profile.pipe';
import {GenresPipe} from './genres.pipe';
import {DrawstylePipe} from './drawstyle.pipe';
import {AuthorsLabelPipe} from './authors-label.pipe';
import {RoleLabelPipe} from './roles-label.pipe';
import {ProductAttributePipe} from './product-attribute.pipe';
import {ChapterMediaPipe} from './chapter-media.pipe';
import {SubscribeStatusPipe} from './subscribe-status.pipe';
import {CarouselTypePipe} from './carousel-type.pipe';
import {AttributePipe} from './attribute.pipe';
import {VariantPipe} from './variant.pipe';
import {FilterVariationsByPipe} from './filter-variations-by.pipe';
import {MapPipe} from './map.pipe';
import {CarouselBgPipe} from './carousel-bg.pipe';
import {MediaEvidencePipe} from './media-evidence.pipe';
import {IsCoverVariantPipe} from './is-cover-variant.pipe';
import {CategoryPipe} from './category.pipe';
import {ProductPricePipe} from './product-price.pipe';
import {GenerateArrayPipe} from './generate-array.pipe';
import {IsMerchandisingPipe} from './is-merchandising.pipe';
import {IsCoverRegularPipe} from './is-cover-regular.pipe';
import {VariantPricePipe} from './variant-price.pipe';
import {IsVariantRegularPipe} from './is-variant-regular.pipe';
import {CouponAmountPipe} from './coupon-amount.pipe';
import {TruncatePipe} from './truncate.pipe';
import {TruncateByWordsPipe} from './truncate-words.pipe';
import {ItemVariationBundlePipe} from './item-variation-bundle.pipe';
import {AvatarResourcePipe} from './avatar-resource.pipe';
import {FormatDateTimePipe} from './format-datetime.pipe';
import {ChapterGroupMediaPipe} from './chapter-group-media.pipe';
import {PmLogoPipe} from './pm-logo.pipe';
import {PmTitlePipe} from './pm-title.pipe';
import {PmMessagePipe} from './pm-message.pipe';
import {IsSubscriberCartPipe} from './is-subscriber-cart.pipe';
import {FeatureHorizontalCoverPipe} from './feature-horizontal-cover.pipe';

const MODULE = [
  orderStatus,
  orderStatusColor,
  FormatDatePipe,
  CapitalizePipe,
  StripStringPipe,
  AuthorsPipe,
  ProgressPipe,
  ReadingPipe,
  IsFavoritePipe,
  IsReadLaterPipe,
  GenreLabelPipe,
  DrawstyleLabelPipe,
  jsonCartValuePipe,
  PermissionsLabelPipe,
  InstagramProfilePipe,
  FacebookProfilePipe,
  GenresPipe,
  DrawstylePipe,
  AuthorsLabelPipe,
  RoleLabelPipe,
  ChapterMediaPipe,
  SubscribeStatusPipe,
  CarouselTypePipe,
  AttributePipe,
  VariantPipe,
  FilterVariationsByPipe,
  MapPipe,
  CarouselBgPipe,
  MediaEvidencePipe,
  IsCoverVariantPipe,
  CategoryPipe,
  ProductPricePipe,
  GenerateArrayPipe,
  IsMerchandisingPipe,
  IsCoverRegularPipe,
  VariantPricePipe,
  IsVariantRegularPipe,
  CouponAmountPipe,
  TruncatePipe,
  TruncateByWordsPipe,
  ItemVariationBundlePipe,
  AvatarResourcePipe,
  FormatDateTimePipe,
  ChapterGroupMediaPipe,
  PmLogoPipe,
  PmTitlePipe,
  PmMessagePipe,
  IsSubscriberCartPipe,
  FeatureHorizontalCoverPipe
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    MODULE,
    IsOriginalPipe,
    ProductTitleTagPipe,
    IsPreorderPipe,
    CheckBoolPipe,
    ProductAttributePipe
  ],
  declarations: [
    MODULE,
    IsOriginalPipe,
    ProductTitleTagPipe,
    IsPreorderPipe,
    CheckBoolPipe,
    ProductAttributePipe
  ]
})
export class PipesModule {
}
