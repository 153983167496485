<div class="row w-100">
  <div class="col-12 mt-20 text-right">
    <button class="no-select transparent" mat-button (click)="close()">
      <img class="mr-30 pointer"
           width="20" height="20"
           src="assets/icon/jundo/close-icon.svg"
           alt="close">
    </button>
  </div>
  <div class="col-12 text-center">
    <!--<h3 class="text-22 fw-700 mt-20">Shopping Bag</h3>-->
    <div class="justify-content-start align-items-center d-inline-flex" style="width: 200px; gap: 16px;">
      <div class="position-relative" style="width: 24px; height: 24px;">

        <img alt="shopping-bag" width="24" height="24" style="left: 0; top: 4px; position: absolute" src="assets/icon/v2/shopping-bag.svg"/>

        <div class="overflow-hidden justify-content-center align-items-center d-inline-flex lightyellow"
             *ngIf="(cartService.getShoppingItemQuantity() | async)"
             style="width: 20px; height: 20px; left: 11px; top: -7px; position: absolute; border-radius: 100px; flex-direction: column;">
          <div class="text-center text-12 fw-600 text-break black-text">
            {{(cartService.getShoppingItemQuantity() | async)}}
          </div>
        </div>
      </div>
      <h3 class="text-center text-20 fw-700 mt-20 white-text text-break">Shopping Bag</h3>
    </div>
  </div>
  <div class="col-12 mt-20 text-center" *ngIf="(cartService.cart | async).length">
    <p class="text-18 m-0">
      <span>Spedizione:</span>&nbsp;
      <span>{{shippingService.shipping === 0 ? 'GRATUITA' : (shippingService.shipping | number: '.2-2') + '€'}}</span>&nbsp;
    </p>
    <p class="text-18 bolder m-0">
      <span>Totale:</span>&nbsp;
      <span>{{(((cartService.getShoppingItemAmount() | async) + shippingService.shipping) | number: '.2-2') + '€'}}</span>
    </p>
  </div>
  <div class="col-12 text-center mt-20">
    <button class="btn btn-jundo" (click)="checkout()" [disabled]="!(cartService.cart | async).length">
      Procedi all'acquisto
    </button>
    <small class="mt-10 d-block p-20 grey-text" *ngIf="(cartService.cart | async) | isSubscriberCart">
      Hai selezionato prodotti o opzioni riservate agli abbonati, ti verra chiesto di abbonarti a Jundo One per
      finalizzare l’acquisto
    </small>
  </div>
  <!--<div class="col-12 text-center mt-30" *ngIf="(cartService.cart | async).length">

    <mat-checkbox id="cart-seal-up" color="primary"
                  class="jcheckbox pointer mr-10"
                  (click)="refreshCart()"
                  [(ngModel)]="globalService.seal_up">
    </mat-checkbox>
    <label for="cart-seal-up"
           (click)="globalService.seal_up = !globalService.seal_up"
           class="title16 no-decoration pointer">Ricevi articoli in busta sigillata</label>

  </div>-->
</div>
<hr class="separator w-100 mt-30 mb-20">
<div class="row w-100">
  <div class="col-12" *ngIf="!(cartService.cart | async).length">
    <p class="text-center grey-text">Nessun prodotto presente</p>
  </div>
  <div class="col-12 pl-24" *ngFor="let cartItem of (cartService.cart | async)">
    <app-cart-item (on-open)="close()" [cartItem]="cartItem" (on-edit)="refreshCart()"></app-cart-item>
  </div>
</div>
