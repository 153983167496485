<nav class="breadcrumb-bar black-light" aria-label="breadcrumb" *ngIf="segments.length && isDashboard">
  <div class="container">
    <ol class="breadcrumb m-0">
      <li *ngFor="let item of segments" class="breadcrumb-item">
        <a class="no-decoration"
           [ngClass]="!item.enable ? 'grey-text cursor-default' : ''"
           [routerLink]="item.enable ? item.url : null">
          {{item.label}}
        </a>
      </li>
    </ol>
  </div>
</nav>


