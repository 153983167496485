<h1 mat-dialog-title>Conferma eliminazione account</h1>
<div mat-dialog-content class="pb-20">

  <div class="row">
    <div class="col-12">
      <p class="bolder mb-0">Per quale motivo te ne vai?</p>
    </div>
    <div class="col-12">
      <form [formGroup]="form" (ngSubmit)="confirm()" novalidate>
        <div class="row">
          <div class="col-12" *ngFor="let option of cancellationOptions;">
            <section class="mt-10">
              <mat-checkbox [formControl]="form.controls[option.value]">
                <span class="white-text">{{option.label}}</span>
              </mat-checkbox>
            </section>
          </div>
          <div class="col-12" *ngIf="form && form.controls['altro'].value">
            <section class="mt-10">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label class="grey-text">Altro motivo</mat-label>
                <textarea matInput [formControl]="form.controls['reason']"></textarea>
              </mat-form-field>
            </section>
          </div>
        </div>
      </form>
    </div>
  </div>

</div>
<mat-dialog-actions align="end">
  <button class="btn btn-outline-jundo mr-20" [mat-dialog-close]="false">Annulla</button>
  <button class="btn btn-jundo mr-10" (click)="confirm()" [mat-dialog-close]="true">Conferma eliminazione account 😭</button>
</mat-dialog-actions>
