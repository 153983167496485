import {Pipe, PipeTransform} from '@angular/core';
import {PaymentMethod} from '@stripe/stripe-js';
import {AuthService} from '../services/auth.service';
import {PaymentMethodTypeConstant} from '../constants/payment-method-type.constant';

@Pipe({
  name: 'pmLogo'
})
export class PmLogoPipe implements PipeTransform {

  methodConstant = PaymentMethodTypeConstant;

  constructor(private auth: AuthService) {}

  transform(pm: PaymentMethod, args?: any[] | undefined): string {

    if (this.auth.user.value && this.auth.user.value.jundo_user.stripe_customer_default_method) {

      switch (this.auth.user.value.jundo_user.stripe_customer_default_method) {
        case this.methodConstant.CARD:
          return (pm && pm.card && pm.card.brand) ? 'assets/icon/v2/' + pm.card.brand + '.svg' : 'assets/icon/v2/cc.svg';
        case this.methodConstant.PAYPAL:
          return 'assets/icon/v2/paypal.svg';
        case this.methodConstant.APPLE_PAY:
          return 'assets/icon/v2/apple-pay.svg';
        case this.methodConstant.GOOGLE_PAY:
          return 'assets/icon/v2/google-pay.svg';
        default:
          return 'assets/icon/v2/cc.svg';
      }
    }

    return 'assets/icon/v2/cc.svg';
  }

}
