import {Component, Input, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IComic} from '../../interfaces/IComic';
import {UiService} from '../../services/ui.service';
import {ItemService} from '../../services/items.service';
import {ProgressPipe} from '../../pipes/progress.pipe';
import {IActionComic} from '../../interfaces/IActionComic';
import {AuthService} from '../../services/auth.service';
import {ICarousel} from '../../interfaces/ICarousel';
import {FirebaseService} from '../../services/firebase.service';
import {UtilityProvider} from '../../services/utility.service';
import {environment} from '../../../environments/environment';
import * as _ from 'lodash';
import SwiperCore, {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
} from 'swiper';
import {Subscription} from 'rxjs';

SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);


@Component({
  selector: 'app-comic-slider',
  templateUrl: './comic-slider.component.html',
  styleUrls: ['./comic-slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ProgressPipe]
})
export class ComicSliderComponent {

  @Input() carousel: ICarousel;
  @Input() required: boolean | number = false;
  @Input() expand?: boolean | number = true;
  @Input() shop: boolean = false;
  @Input() soon: boolean | number = 0;
  @Input() marker: boolean = false;
  @Input() showPrice: boolean = false;
  @Input('big-order') bigOrder: boolean = false;
  @Input('alternative-chip') alternativeChips: boolean = false;
  @Input('from-chapter') fromChapter: any;
  @Input('first-slide') firstSlide = true;
  @Input('css-mode') cssMode = true;
  @Input() isNew: boolean;

  isImgLoaded: boolean = false;
  isFavorite: boolean[] = [];
  isLoaded: boolean[] = [];

  comics: IComic[] = [];
  hoverIdx: boolean[] = [];

  timer = null;
  environment = environment;
  comicObservable: Subscription;

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    public ui: UiService,
    public auth: AuthService,
    private itemsService: ItemService,
    private firebase: FirebaseService,
    private utility: UtilityProvider,
  ) {

  }

  ngOnInit() {
    this.comics = _.cloneDeep(this.carousel.comics);
  }

  favorite(comic: IComic, idx: number) {

    if (!this.auth.isAuthenticated.value) {
      this.ui.openSignupDialog();
      return;
    }

    this.itemsService.toggleFavorite(comic.id)
      .subscribe((data: IActionComic) => {
        this.isFavorite[idx] = !!data.item_id;

        if (this.isFavorite) {
          this.firebase.logEvent({
            name: 'add_read_later',
            params: {
              content_type: comic.title,
              content_id: String(comic.id)
            }
          });
        }
      });
  }

  openReader(comic: IComic) {
    this.router.navigate(['/comic/' + comic.id + '/reader/', this.utility.progressChapterId(comic)]).then();
  }

  openComic(comic: IComic) {
    this.router.navigate(['/home'], {
      queryParams: {
        comic: comic.id
      },
      relativeTo: this.route
    }).then();
  }

  onMouseEnter(idx: number) {

    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.hoverIdx[idx] = true;
      this.hoverIdx = this.hoverIdx.map((hover, i) => i === idx ? hover : false);
      if (!this.isLoaded[idx]) {
        this.loadComic(idx);
      }
    }, 500);
  }

  onMouseLeave(idx: number) {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    if (this.comicObservable) {
      this.comicObservable.unsubscribe();
    }
    this.hoverIdx = [];
  }

  loadComic(idx: number) {
    const comic = this.comics[idx];
    this.comicObservable = this.itemsService.comic(comic.id).subscribe((data: IComic) => {
      this.comics[idx] = data;
      this.isLoaded[idx] = true;
      if (this.auth.isAuthenticated.value && this.auth.user.value && this.auth.user.value.jundo_user) {
        this.isFavorite[idx] = this.auth.user.value.jundo_user.favorites.some(favorite => favorite.id === data.id);
      }
    });
  }
}
