import {Component, EventEmitter, Output} from '@angular/core';
import {GlobalService} from '../../services/global.service';
import {ShopService} from '../../services/shop.service';
import {CartService} from '../../services/cart.service';
import {CoverTypeConstant} from '../../constants/cover-type.constant';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {IsSubscriberCartPipe} from '../../pipes/is-subscriber-cart.pipe';
import {UiService} from '../../services/ui.service';
import {StripeService} from '../../services/stripe.service';
import {ShippingService} from '../../services/shipping.service';

@Component({
  selector: 'app-sidenav-cart',
  templateUrl: './sidenav-cart.component.html',
  styleUrls: ['./sidenav-cart.component.scss'],
  providers: [IsSubscriberCartPipe]
})
export class SidenavCartComponent {

  @Output('on-close') onClose: EventEmitter<any> = new EventEmitter<any>();

  loader = false;
  coverTypeConstants = CoverTypeConstant;

  constructor(
    public globalService: GlobalService,
    public shopService: ShopService,
    public cartService: CartService,
    public router: Router,
    private auth: AuthService,
    private ui: UiService,
    private isSubscriberCartPipe: IsSubscriberCartPipe,
    public stripeService: StripeService,
    public shippingService: ShippingService,
  ) {
    this.shippingService.getTaxes();
  }

  refreshCart() {
    this.shippingService.getTaxes();
  }

  checkout() {

    if (!this.auth.isAuthenticated.value) {
      this.router.navigate(['/access'], {
        replaceUrl: true,
        queryParams: {
          page: 'signup',
          return_url: this.router.url
        }
      });
      this.close();
      return;
    }

    if ((this.globalService.seal_up || this.isSubscriberCartPipe.transform()) && !this.auth.isSubscribed.value) {
      this.ui.openSubscriptionDialog({
        title: 'Abbonati a Jundo',
        message: 'Hai scelto un opzione speciale riservata agli abbonati Jundo. Abbonati subito per poter procedere con il checkout.',
        return_url: null,
      });
      this.close();
      return;
    }

    this.router.navigate(['/checkout/shipping']);
    this.close();
  }

  close() {
    this.onClose.emit();
  }
}
