import {Component, Input, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {IProduct} from '../../interfaces/IProduct';
import {UiService} from '../../services/ui.service';
import * as _ from 'lodash';

import SwiperCore, {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
} from 'swiper';
import {AuthService} from '../../services/auth.service';
import {CategoryNameConstant} from '../../constants/category-name.constant';

SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);


@Component({
  selector: 'app-product-grid',
  templateUrl: './product-grid.component.html',
  styleUrls: ['./product-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductGridComponent {

  @Input() data: IProduct[] = [];
  @Input() title?: string;
  @Input() api?: string;
  @Input() required: boolean | number = false;
  @Input() expand?: boolean | number = true;
  @Input() shop: boolean = false;
  @Input() soon: boolean | number = 0;
  @Input() marker: boolean = false;
  @Input() showPrice: boolean = false;
  @Input() noSpacing: boolean = false;
  @Input('big-order') bigOrder: boolean = false;
  @Input('alternative-chip') alternativeChips: boolean = false;
  @Input('from-chapter') fromChapter: any;

  isImgLoaded: boolean = false;

  values: IProduct[] = [];

  categoryNameConstants = CategoryNameConstant;

  constructor(
    public route: ActivatedRoute,
    public ui: UiService,
    public auth: AuthService
  ) {

  }

  ngOnInit() {
    // this.values = _.cloneDeep(this.required ? this.data.slice(0, 10) : this.data);
    this.values = _.cloneDeep(this.data);
  }

}
