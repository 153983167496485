import {Pipe, PipeTransform} from '@angular/core';
import {IAuthor} from '../interfaces/IAuthor';
import {IComic} from '../interfaces/IComic';
import {IChapter} from '../interfaces/IChapter';

@Pipe({
  name: 'progress'
})
export class ProgressPipe implements PipeTransform {

  transform(comic: IComic, args?: any): any {
    return this.calcProgress(comic);
  }

  calcProgress(comic: IComic): number {

    let percentage: number = 0;

    if (comic.progress && comic.progress.length) {

      let onlyProgressItems = comic.progress.filter(item => item.status > 0 && item.status < 100);

      if (onlyProgressItems.length) {
        let lastProgress = onlyProgressItems[onlyProgressItems.length - 1];
        percentage = lastProgress.status;

        // const lastGroupType = lastProgress.chapter;
        // const unique = [...new Set(comic.attachments.map(item => item.group_type))];
        // let indexH = unique.indexOf(lastGroupType);

      }
    }

    return percentage;
  }

}
