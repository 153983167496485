<mat-dialog-content>

  <div class="skeleton-container no-select" *ngIf="!comic">
    <div class="row">
      <div class="col-12">
        <app-skeleton [height]="'400px'"></app-skeleton>
      </div>
      <div class="col-8 mt-20">
        <app-skeleton [height]="'100px'"></app-skeleton>
      </div>
      <div class="col-4 mt-20">
        <app-skeleton [height]="'100px'"></app-skeleton>
      </div>
      <div class="col-12 mt-20">
        <app-skeleton [height]="'80px'"></app-skeleton>
      </div>
      <div class="col-12">
        <app-skeleton [height]="'80px'"></app-skeleton>
      </div>
      <div class="col-12">
        <app-skeleton [height]="'80px'"></app-skeleton>
      </div>
      <div class="col-12">
        <app-skeleton [height]="'80px'"></app-skeleton>
      </div>
      <div class="col-12">
        <app-skeleton [height]="'80px'"></app-skeleton>
      </div>
      <div class="col-12">
        <app-skeleton [height]="'80px'"></app-skeleton>
      </div>
      <div class="col-12">
        <app-skeleton [height]="'80px'"></app-skeleton>
      </div>
      <div class="col-12">
        <app-skeleton [height]="'80px'"></app-skeleton>
      </div>
      <div class="col-12">
        <app-skeleton [height]="'80px'"></app-skeleton>
      </div>
    </div>
  </div>

  <div class="comic-container" *ngIf="comic">
    <div class="comic-container-bg" [ngStyle]="{'background-image': 'url(' + ui.mediaCoverHorizontalUrl(comic) + ')'}">
      <div class="comic-container-overlay"></div>
      <div class="comic-box-1 no-select">
        <img *ngIf="comic.originals" src="assets/images/logo-compact.svg" alt=""/>
        <div class="badge-coin" *ngIf="comic.is_coin">
          <div class="badge-coin-container">
            <img src="assets/icon/v2/coin.svg" alt="coin"/>
          </div>
          <div class="badge-coin-label white-text text-12 fw-600 text-break">Opera Jundo Coins</div>
        </div>
      </div>
      <div class="comic-box-2">
        <!--<button *ngIf="isAdmin" [routerLink]="'dashboard/comics/' + this.comic.id"
                mat-mini-fab class="transparent shadow-none close-btn" aria-label="close">
          <img src="assets/icon/jundo/close-round-2.svg" alt="close"/>
        </button>-->
        <button mat-mini-fab class="transparent shadow-none close-btn" aria-label="close" (click)="dialog.closeAll()">
          <img src="assets/icon/jundo/close-round-2.svg" alt="close"/>
        </button>
      </div>
      <div class="comic-info-box">
        <div class="comic-title-box">
          <h1>{{comic.title}}</h1>
        </div>
        <div class="comic-actions-box">
          <button class="btn btn-jundo mr-20 begin-btn" (click)="openReader()" *ngIf="comic.chapters.length">
            {{comic.progress && comic.progress.length ? 'Continua a leggere' : 'Inizia a leggere'}}
          </button>
          <div class="comic-actions-box-item">
            <div class="flex-column justify-content-start align-items-center g-4 d-inline-flex pointer"
                 (click)="addFavorite()">
              <img alt="" [src]="isFavorite ? 'assets/icon/v2/bookmark-on.svg' : 'assets/icon/v2/bookmark-off.svg'"/>
              <div class="white-text text-12 fw-400 text-break">Collezione</div>
            </div>
            <!--<div class="flex-column justify-content-start align-items-center g-4 d-inline-flex">
              <img alt="" src="assets/icon/generic/check-green.svg" />
              <div class="white-text text-12 fw-400 text-break">Notifiche</div>
            </div>-->
            <div class="flex-column justify-content-start align-items-center g-4 d-inline-flex pointer"
                 (click)="openLikeDialog()">
              <img *ngIf="isLike" alt="" [src]="'assets/icon/v2/thumb_up_active.svg'"/>
              <img *ngIf="isDislike" alt="" [src]="'assets/icon/v2/thumb_down_active.svg'"/>
              <img *ngIf="!isLike && !isDislike" alt="" [src]="'assets/icon/v2/thumb_up.svg'"/>
              <div class="white-text text-12 fw-400 text-break">Valuta</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="comic-meta-box mt-40">

      <div class="row">
        <div class="col-8">
          <div class="flex-column justify-content-center g-8 d-flex" style="align-items: flex-start;">
            <div class="align-items-center g-8 d-inline-flex" style="justify-content: flex-start;">
              <div class="row">
                <div class="col-12 text-center">
                  <p class="text-truncate mt-2 mb-2 text-14">
                    <span *ngFor="let chip of comic.genres; let i = index;">
                      <span *ngIf="i > 0" class="yellow-text">&nbsp;&nbsp;•&nbsp;&nbsp;</span>
                      <span class="white-text">{{chip.label}}</span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="comic-chip">
              <div class="comic-chip-content" *ngIf="comic.webtoon">
                <div class="fw-400 lightgrey-text text-12 text-break">Webtoon</div>
              </div>
              <span class="grey-text" *ngIf="comic.chapters.length">{{comic.chapters.length}} Capitoli</span>
            </div>
          </div>
          <div class="comic-description text-14 white-text mt-15 pointer"
               *ngIf="!isReadMore"
               (click)="isReadMore = !isReadMore"
               [innerHtml]="(convertTag(comic.introtext) | slice:0:180) + '...'">
          </div>
          <div class="comic-description text-14 white-text mt-15 pointer"
               *ngIf="isReadMore"
               (click)="isReadMore = !isReadMore"
               [innerHtml]="comic.introtext">
          </div>
          <span (click)="isReadMore = !isReadMore"
                *ngIf="!isReadMore"
                class="yellow-text text-14 pointer">Leggi altro...</span>
        </div>
        <div class="col-4">
          <div
            style="width: 100%; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 8px; display: inline-flex">
            <div style="align-self: stretch" *ngIf="published_at">
              <span class="grey-text text-14 fw-600 text-break">Uscita:</span>
              <span class="white-text text-14 fw-600 text-break">{{published_at | date: 'dd/MM/YYYY'}}</span>
            </div>
            <div style="align-self: stretch" *ngFor="let author of comic.authors">
              <span class="grey-text text-14 fw-600 text-break">{{author | roleLabel}}:&nbsp;</span>
              <span class="white-text text-14 fw-600 text-break">{{author.username}}</span>
            </div>
            <div style="align-self: stretch" *ngFor="let attribute of comic.attributes">
              <span class="grey-text text-14 fw-600 text-break">{{attribute.name}}:&nbsp;</span>
              <span class="white-text text-14 fw-600 text-break"> {{attribute.value}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-20 mb-20">
        <div class="col-12">

          <ng-container *ngIf="!comic.products.length">
            <app-chapters-group-data (on-refresh)="onRefresh()" [comic]="comic"></app-chapters-group-data>
          </ng-container>

          <ng-container *ngIf="comic.products.length">
            <mat-tab-group mat-stretch-tabs animationDuration="0ms">
              <mat-tab class="tab-chapters">
                <ng-template mat-tab-label>
                <span class="j-font text-16 fw-600">
                  {{comic.is_grouped ? 'Volumi' : 'Capitoli'}}
                </span>
                </ng-template>
                <ng-template matTabContent>
                  <app-chapters-group-data (on-refresh)="onRefresh()" [comic]="comic"></app-chapters-group-data>
                </ng-template>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label><span class="j-font text-16 fw-600">Online Shop</span></ng-template>
                <ng-template matTabContent>
                  <div class="row mt-30">
                    <div class="col-12">
                      <app-product-slider
                        *ngIf="productsAssociated.length"
                        title="Opere cartacee"
                        [data]="productsAssociated"
                        [required]="false"
                        [shop]="true"
                        [showPrice]="true"
                      ></app-product-slider>
                    </div>
                    <div class="col-12">
                      <app-product-slider
                        *ngIf="merchandisingAssociated.length"
                        title="Merchandising"
                        [data]="merchandisingAssociated"
                        [required]="false"
                        [shop]="true"
                        [showPrice]="true"
                      ></app-product-slider>
                    </div>
                  </div>
                </ng-template>
              </mat-tab>
            </mat-tab-group>
          </ng-container>

        </div>
      </div>

    </div>
  </div>
</mat-dialog-content>
