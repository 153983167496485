import {Component, Input} from '@angular/core';
import {IComic} from '../../interfaces/IComic';
import {UiService} from '../../services/ui.service';
import {Router} from '@angular/router';
import {FirebaseService} from '../../services/firebase.service';
import {IActionComic} from '../../interfaces/IActionComic';
import {ItemService} from '../../services/items.service';
import {ChannelsService} from '../../services/channels.service';
import {AuthService} from '../../services/auth.service';
import {ICarousel} from '../../interfaces/ICarousel';
import {environment} from '../../../environments/environment';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
})
export class HeroComponent {

  @Input() carousel: ICarousel;

  comic: IComic;
  readLaterFlag = false;
  isFavorite: boolean = false;
  backgroundUrl: string = environment.media + 'images/background-large.png';

  constructor(
    public ui: UiService,
    private router: Router,
    private firebase: FirebaseService,
    private itemsService: ItemService,
    private channels: ChannelsService,
    public auth: AuthService,
    public deviceService: DeviceDetectorService
  ) {

  }

  ngOnInit() {
    this.comic = this.carousel.comics[Math.floor(Math.random() * this.carousel.comics.length)];
    if (this.auth.isAuthenticated.value) {
      this.profile();
    }
  }

  continueReading() {

    this.firebase.logEvent({
      name: 'inizia_a_leggere',
      params: {
        content_type: this.comic.title,
        content_id: String(this.comic.id)
      }
    });

    let chapterId = null;

    if(this.comic.is_coin){
      this.ui.openModalComic(this.comic.id);
      return;
    }

    if (this.comic.progress && this.comic.progress.length) {
      chapterId = this.comic.progress[0].chapter_id;
      this.router.navigate(['comic/' + this.comic.id + '/reader/', chapterId]).then();
    }

    if (this.comic.chapters && this.comic.chapters.length) {
      chapterId = this.comic.chapters[0].id;
      this.router.navigate(['comic/' + this.comic.id + '/reader/', chapterId]).then();
    }

    if (!chapterId) {
      this.ui.openModalComic(this.comic.id);
    }

  }

  toggleFavorite(id) {

    if (!this.auth.isAuthenticated.value) {
      this.ui.openSignupDialog();
      return;
    }

    this.readLaterFlag = true;
    this.isFavorite = !this.isFavorite;
    this.itemsService.toggleFavorite(id)
      .subscribe((data: IActionComic) => {
        this.isFavorite = !!data.item_id;
        this.channels.publish('favorite:update', {});
      });
  }

  profile() {
    this.auth.profile()
      .subscribe((data) => {
        this.auth.user.next(data);
        this.isFavorite = !!this.auth.user.value.jundo_user.reads_later.find(item => item.id === this.comic.id);
      });
  }

  convertTag(introtext: string) {
    if (!introtext) {
      return '';
    }
    const val1 = introtext.replace('<p>', '<span>');
    return val1.replace('</p>', '</span>');
  }

  protected readonly environment = environment;
}
