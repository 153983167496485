import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-account-title',
    templateUrl: './account-title.component.html',
    styleUrls: ['./account-title.component.scss'],
})
export class AccountTitleComponent {

    @Input() parentTitle?: string;
    @Input() title: string;

    constructor() {
    }

}
