import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {UiService} from '../../services/ui.service';
import {CartService} from '../../services/cart.service';
import {StripeService} from '../../services/stripe.service';

@Component({
  selector: 'app-shipping-card',
  templateUrl: './shipping-card.component.html',
  styleUrls: ['./shipping-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShippingCardComponent implements OnInit {

  @Output() shippingSelected: EventEmitter<any> = new EventEmitter();
  @Input('taxes') taxes = [];

  selectedIndex = 0;

  constructor(
    public ui: UiService,
    public stripeService: StripeService,
    public cartService: CartService
  ) {
  }

  ngOnInit() {
    this.stripeService.shippingMethod = this.taxes[0];
  }

  selectMethod(methodSelected: any, i: number) {
    this.selectedIndex = i;
    this.shippingSelected.emit(methodSelected);
  }
}
