import {Pipe, PipeTransform} from '@angular/core';
import {IAttribute} from '../interfaces/IAttribute';
import {CoverTypeConstant} from '../constants/cover-type.constant';

@Pipe({
  name: 'isVariant'
})
export class IsCoverVariantPipe implements PipeTransform {

  protected coverTypeConstants = CoverTypeConstant;

  transform(attributes: IAttribute[], args?: any): boolean {
    return attributes.length && attributes[0].option && attributes[0].option === this.coverTypeConstants.CLASSIC;
  }

}
