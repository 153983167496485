import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AutofocusDirective} from './autofocus.directive';
import {TextareaAutoresize} from './textarea-autoresize';
import {DndDirective} from './dnd.directive';
import {HoverClassDirective} from './hover-class.directive';


const DIRECTIVES = [
  AutofocusDirective,
  TextareaAutoresize,
  DndDirective,
  HoverClassDirective
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    DIRECTIVES
  ],
  declarations: [
    DIRECTIVES
  ]
})
export class DirectivesModule {
}
