<ng-container>
  <h2 class="fw-700 text-22 mt-10 ml-60 mb-0 no-select" *ngIf="title">{{title}}&nbsp;</h2>
  <swiper
    [navigation]="true"
    [pagination]="false"
    [mousewheel]="false"
    [keyboard]="false"
    [initialSlide]="0"
    [slidesPerGroup]="3"
    [slidesPerView]="'auto'"
    [spaceBetween]="20"
  >
    <ng-template swiperSlide class="spacer-slider"></ng-template>
    <ng-template swiperSlide class="slide mt-20 mb-20 no-select {{expand ? 'expand' : ''}}"
                 *ngFor="let product of values;let i = index;">
      <div class="comic-card text-14"
           [ngClass]="i === 0 ? 'position-left' : (i === values.length - 1 ? 'position-right' : '')">
        <div class="row">
          <div class="col-12 small">

            <div class="badge-wrapper text-center" *ngIf="(product | isOriginal) && !(product | isPreorder)">
              <img class="badge-icon" src="assets/images/badge-yellow.svg" alt="badge"/>
            </div>

            <div class="no-stock" *ngIf="shop && product && (!product.variations.length && product.stock_quantity < 1)">
              <app-chip label="Esaurito" state="danger"></app-chip>
            </div>

            <div class="variant-marker yellow"
                 *ngIf="product && product.variations.length && !(product | isMerchandising)">
              <p class="black-text pt-4 pt-2 text-center text-14">Variant disponibile</p>
            </div>

            <!--<div class="sub-title">
              <app-chip [label]="product.name | productTitleTag" state="dark round"></app-chip>
            </div>-->

            <a class="no-decoration white-text" href="javascript:void(0);"
               [routerLink]="'/shop/product/' + product.slug">
              <img class="comic-card-img" alt=""
                   (load)="isImgLoaded = true"
                   [src]="isImgLoaded ? ui.productCoverUrl(product) : 'assets/images/sfondo.png'">
            </a>
            <h3 class="comic-card-title text-22 bolder mb-0 pt-2 mt-10 text-center">{{product.price}} €</h3>
            <button class="btn btn-link yellow-text no-decoration text-16" (click)="addToCart(product)">
              <img class="shop-icon" src="assets/icon/bag-yellow.svg" alt=""/>
              <span class="ml-10 merch-cart yellow-text">Aggiungi alla Shopping Bag</span>
            </button>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template swiperSlide class="spacer-slider"></ng-template>
    <ng-template swiperSlide class="spacer-slider"></ng-template>
  </swiper>
</ng-container>
