import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import * as moment from 'moment';
import {UiService} from '../../services/ui.service';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent implements OnDestroy, OnInit {

  @Input('preorder-date') preorderDate: any;

  second = 1000;
  minute = 1000 * 60;
  hour = (1000 * 60) * 60;
  day = ((1000 * 60) * 60) * 24;

  days: number = 0;
  hours: number = 0;
  minutes: number = 0;
  seconds: number = 0;

  countDown: any;
  interval: any;

  expired: boolean;
  loader: boolean = true;

  constructor(
    private ui: UiService
  ) {
  }

  ngOnInit(): void {
    if(this.ui.isBrowser()){
      this.runTimer();
    }
  }

  runTimer() {
    this.countDown = moment(this.preorderDate, 'DD/MM/YYYY HH:mm').valueOf();

    this.interval = setInterval(() => {
      this.loader = false;

      let now = new Date();
      const currentDate = moment(now).valueOf();
      let distance = this.countDown - currentDate;

      this.days = Math.floor(distance / (this.day));
      this.hours = Math.floor((distance % (this.day)) / (this.hour));
      this.minutes = Math.floor((distance % (this.hour)) / (this.minute));
      this.seconds = Math.floor((distance % (this.minute)) / this.second);

      if (distance < 0) {
        this.expired = true;
        this.loader = false;
        clearInterval(this.interval);
      }

    }, 1000);
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

}
