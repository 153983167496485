<div class="sticky-checkout">

  <app-skeleton *ngIf="!cart.length" [height]="'280px'" [width]="'100%'"></app-skeleton>

  <div class="radius8 p-20" style="background: #272726" *ngIf="cart.length">
    <h3 class="bolder text-18">Articoli</h3>
    <div class="row" *ngFor="let item of cart">
      <div class="col-8">
        <p class="text-18 m-0 mt-10 grey-text">{{item.qty}}x&nbsp;{{item.product.name}}</p>
        <small class="mt-0 text-truncate grey-text" *ngIf="item.variantId">
          {{item.product.variations | variant:[item.variantId, 'name']}}
        </small>
      </div>
      <div class="col-4">
        <p class="text-18 text-right m-0 mt-10 grey-text">
          {{((item.product.price * item.qty) | number: '.2-2') + ' €'}}
        </p>
      </div>
    </div>
    <div class="row">

      <!-- subtotal -->
      <div class="col-6">
        <p class="text-18 bolder mt-20 mb-10">Subtotale</p>
      </div>
      <div class="col-6">
        <p class="text-18 text-right bolder mt-20 mb-10">{{subtotal | number : '1.2-2'}}€</p>
      </div>

      <hr class="mt-20 mb-20 pl-20 pr-20" style="border: 1px solid rgba(119,119,119,0.43);"/>

      <!-- coupon -->
      <div class="col-6 mb-10" *ngIf="couponData">
        <p class="text-18 bolder yellow-text uppercase mb-0">
          {{couponData.code}}
        </p>
        <small class="yellow-text d-block" *ngIf="couponData.description">
          {{couponData.description}}
        </small>
        <small class="red-text d-block pointer" (click)="removeCoupon()">Rimuovi codice sconto</small>
      </div>
      <div class="col-6 mb-10" *ngIf="couponData">
        <p class="text-18 text-right fw-600 yellow-text">
          -{{((couponData | couponAmount)| number: '.2-2') + ' €'}}
        </p>
      </div>

      <!-- spedizione -->
      <div class="col-12">
        <h3 class="bolder text-18">Spedizione</h3>
      </div>
      <div class="col-6">
        <div class="text-18 m-0 mt-10 grey-text" *ngIf="stripeService.shippingMethod"
             [innerHtml]="stripeService.shippingMethod.message"></div>
      </div>
      <div class="col-6">
        <p class="text-18 text-right m-0 mt-10 grey-text">
          {{shippingService.shipping === 0 ? 'GRATUITA' : (shippingService.shipping | number: '.2-2') + ' €'}}
        </p>
      </div>

      <div class="col-12 mt-0 mb-0 mt-20">
        <p class="text-left yellow-text margin-0 mb-10">Hai un codice sconto?</p>
        <form novalidate [formGroup]="couponForm" (ngSubmit)="onConfirmCoupon()">
          <app-input [model]="couponForm.controls['coupon']"
                     (confirmCoupon)="onConfirmCoupon()"
                     [isCoupon]="true"
                     [label]="'Codice coupon'"></app-input>
        </form>
      </div>

      <hr class="mt-20 mb-20 pl-20 pr-20" style="border: 1px solid rgba(119,119,119,0.43);"/>

      <!-- totale -->
      <div class="col-6">
        <p class="text-18 bolder mb-2 mt-4">TOTALE</p>
      </div>
      <div class="col-6">
        <p class="text-18 text-right bolder mb-2 mt-4">
          {{((subtotal + shippingService.shipping - (couponData | couponAmount)) | number: '.2-2') + ' €'}}
        </p>
      </div>
    </div>
  </div>

  <ng-container *ngIf="ui.isBrowser">

    <!-- APPLE PAY -->
    <div class="p-0" *ngIf="stripe_customer_default_method === methodConstant.APPLE_PAY">
      <div class="col-12 mt-20">
        <ngx-stripe-payment-request-button
          [paymentOptions]="payRequestOptions"
          (paymentMethod)="onPaymentMethod($event)"
          (shippingaddresschange)="onShippingAddressChange($event)"
          (notavailable)="onNotAvailable()"
        ></ngx-stripe-payment-request-button>
      </div>
    </div>

    <!-- GOOGLE PAY -->
    <div class="p-0" *ngIf="stripe_customer_default_method === methodConstant.GOOGLE_PAY">
      <div class="col-12 mt-20">
        <ngx-stripe-payment-request-button
          [paymentOptions]="payRequestOptions"
          (paymentMethod)="onPaymentMethod($event)"
          (shippingaddresschange)="onShippingAddressChange($event)"
          (notavailable)="onNotAvailable()"
        ></ngx-stripe-payment-request-button>
      </div>
    </div>

    <!-- CARD/PAYPAL -->
    <div class="p-0"
         *ngIf="stripe_customer_default_method === methodConstant.CARD || stripe_customer_default_method === methodConstant.PAYPAL">
      <div class="row mt-20">
        <div class="col-12">
          <div class="mt-0 mb-0 ml-2">
            <button class="btn btn-jundo w-100"
                    (click)="confirmDataPayment(null, null, couponData)"
                    [disabled]="!cart.length">
              Conferma
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- OTHERS -->
    <div class="p-0" *ngIf="stripe_customer_default_method === methodConstant.OTHERS">
      <form novalidate id="payment-form">
        <div class="row mt-20">
          <div class="col-12">
            <!-- Elements will create form elements here -->
            <div id="payment-element"></div>
          </div>
        </div>
        <div class="row mt-20">
          <div class="col-12">
            <button id="submit" class="btn btn-jundo w-100" [disabled]="stripeService.submitted">
              <span id="button-text" class="black-text">Conferma</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </ng-container>

</div>
