import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'orderStatusColor'
})
export class orderStatusColor implements PipeTransform {
    transform(value: any, args?: any): any {
        
        switch (value) {
            case 'failed':
                return 'danger';
            case 'processing':
                return 'warning';
            case 'shipped':
                return 'warning';
            case 'completed':
                return 'success';
            case 'cancelled':
                return 'danger';
            case 'pending':
                return 'warning';
            case 'refunded':
                return 'success';
            default:
                return 'warning'
        }

    }
}
