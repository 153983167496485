<nav class="navbar navbar-expand-lg fixed-top animate__animated animate__fadeIn"
     *ngIf="!isReader && !isLogin && !isSubscription && !isCheckout && !isPurchase">

  <div class="container-fluid container-large position-relative">
    <a class="navbar-brand" [routerLink]="'/'">
      <img class="logo" alt="Jundo" [src]="environment.media + 'images/logo.png'">
    </a>

    <!-- NAV WEB -->
    <div *ngIf="!isDashboard" [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item" *ngFor="let item of menu;" [hidden]="item.auth && !auth.isAuthenticated.value">
          <a class="nav-link"
             routerLinkActive="yellow-text"
             [routerLinkActiveOptions]="{exact: true}"
             [routerLink]="item.path">{{item.label}}</a>
        </li>
      </ul>
      <form class="d-flex">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item mr-0" *ngIf="auth.isAuthenticated.value && auth.user.value">
            <a class="nav-link active" aria-current="page"
               routerLinkActive="yellow-text"
               [routerLinkActiveOptions]="{exact: true}"
               [routerLink]="'/account/coins'">
              <span class="bolder mt-4 mr-10 coin-label yellow-text text-16">
                {{auth.user.value.account.aggregate_balance.balance}}
              </span>
              <img class="coin-icon" src="assets/icon/v2/coin.svg" alt="Coin">
            </a>
          </li>
          <li class="nav-item mr-0">
            <a class="nav-link active" aria-current="page"
               routerLinkActive="yellow-text"
               [routerLinkActiveOptions]="{exact: true}"
               [routerLink]="'/research'">
              <img class="navbar-icon" src="assets/icon/v2/search.svg" alt="Cerca">
            </a>
          </li>
          <li class="nav-item mr-0">
            <button class="nav-link position-relative" mat-button (click)="openCart.emit()">
              <div *ngIf="(cartService.getShoppingItemQuantity() | async)" class="flex-column counter">
                <div class="text-center black-text text-12 fw-600 text-break">
                  {{cartService.getShoppingItemQuantity() | async}}
                </div>
              </div>
              <img class="navbar-icon pointer"
                   src="assets/icon/v2/shopping-bag.svg"
                   alt="Shopping">
            </button>
          </li>
          <li class="nav-item" *ngIf="auth.isAuthenticated.value && auth.user.value">
            <a class="nav-link active" aria-current="page" [routerLink]="'/account/overview'">
              <img class="avatar" alt="" [src]="auth.user.value.jundo_user.avatar"/>
            </a>
          </li>
        </ul>
      </form>
    </div>

    <!-- NAV ADMIN -->
    <div *ngIf="isDashboard" [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link"
             routerLinkActive="yellow-text"
             [routerLinkActiveOptions]="{exact: true}"
             [routerLink]="'/dashboard'">Dashboard</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"
             routerLinkActive="yellow-text"
             [routerLinkActiveOptions]="{exact: true}"
             [routerLink]="'/dashboard/users'">Gestione utenti</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"
             routerLinkActive="yellow-text"
             [routerLinkActiveOptions]="{exact: true}"
             [routerLink]="'/dashboard/comics'">Gestione opere</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"
             routerLinkActive="yellow-text"
             [routerLinkActiveOptions]="{exact: true}"
             [routerLink]="'/'">Torna alla Web App</a>
        </li>
        <!--<li class="nav-item">
          <a class="nav-link" href="https://shop.jundo.it" target="_blank">Online Shop</a>
        </li>-->
      </ul>
      <form class="d-flex" *ngIf="auth.isAuthenticated.value && auth.user.value">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" [routerLink]="'/account/overview'">
              <img alt="" class="avatar" [src]="auth.user.value.jundo_user.avatar"/>
            </a>
          </li>
        </ul>
      </form>
    </div>

    <div class="actions float-end" *ngIf="ui.isBrowser()">
      <button class="btn btn-link-jundo float-end mr-10 navbar-toggler" (click)="isMenuCollapsed = !isMenuCollapsed">
        <mat-icon>menu</mat-icon>
      </button>
      <!--<button *ngIf="!auth.isAuthenticated.value && isMobile"
              class="btn btn-jundo float-end mr-10"
              (click)="ui.openStore()">
        Scarica l'app
      </button>-->
      <button *ngIf="!auth.isAuthenticated.value"
              class="btn btn-jundo float-end mr-10"
              (click)="access('signup')">
        Crea un account
      </button>
      <button *ngIf="!auth.isAuthenticated.value"
              class="btn btn-outline-jundo float-end mr-20"
              (click)="access()">
        Accedi
      </button>
    </div>

  </div>
</nav>


<app-shop-message *ngIf="isShop && !isDashboard"></app-shop-message>
