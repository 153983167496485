<h1 mat-dialog-title>Modifica password</h1>
<div mat-dialog-content>

  <form [formGroup]="form" (ngSubmit)="confirm()" novalidate>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
        <app-input [label]="'Password precedente'"
                   [model]="form.controls['old_password']"
                   [type]="'password'"
                   [icon]="true"
                   [iconMessage]="'Nascondi password'"
                   [error]="'La password deve contenere minimo 8 caratteri, almeno una lettera maiuscola e un numero'"
        ></app-input>
      </div>
      <div class="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
        <app-input [label]="'Nuova Password'"
                   [model]="form.controls['new_password']"
                   [type]="'password'"
                   [icon]="true"
                   [iconMessage]="'Nascondi password'"
                   [error]="'La password deve contenere minimo 8 caratteri, almeno una lettera maiuscola e un numero'"
        ></app-input>
      </div>
    </div>
  </form>


</div>
<mat-dialog-actions align="end">
  <button class="btn btn-outline-jundo mr-20" [mat-dialog-close]="false">Annulla</button>
  <button class="btn btn-jundo mr-10" (click)="confirm()">Conferma</button>
</mat-dialog-actions>
