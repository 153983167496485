import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {UiService} from '../services/ui.service';

@Injectable({
  providedIn: 'root'
})
export class LandingGuard implements CanActivate {

  constructor(
    public router: Router,
    private ui: UiService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.ui.isMobile() || this.ui.isTablet()) {
      let return_url = state.url;
      this.router.navigate(['/landing'], {queryParams: {return_url}});
      return false;
    }

    return true;
  }
}
