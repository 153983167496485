import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'jsonCartValuePipe'
})
export class jsonCartValuePipe implements PipeTransform {
    transform(value: any, args?: any): any {

        if(!value || !args) return null;

        const cart = JSON.parse(value);
        if (cart && cart[args[0]]){
            return cart[args[0]];
        }

        return null;

    }
}
