import {ProductResolver} from './resolvers/product.resolver';
import {AuthGuard} from './guards/auth.guard';
import {CheckoutGuard} from './guards/checkout.guard';
import {LandingGuard} from './guards/landing.guard';

function getMeta(
  title: string,
  description: string = 'La prima piattaforma italiana per leggere manga, fumetti e webcomics originali e internazionali online su smarthphone o PC. I primi capitoli sono gratis!'
) {
  return {
    breadcrumb: title,
    meta: {title: title, description: description}
  };
}

export const shopRouting = [
  {
    path: '',
    canActivate: [LandingGuard],
    data: getMeta('Compra online Manga, Manhwa, Fumetti e Comics'),
    loadChildren: () => import('./pages/shop/shop.module').then(m => m.ShopPageModule)
  },
  {
    path: 'product/:slug',
    resolve: {data: ProductResolver},
    loadChildren: () => import('./pages/product/product.module').then(m => m.ProductPageModule)
  },
  {
    path: 'orders',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        data: getMeta('Ordini'),
        loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersPageModule)
      },
      {
        path: ':id',
        data: getMeta('Ordine'),
        loadChildren: () => import('./pages/order/order.module').then(m => m.OrderPageModule)
      },
    ]
  },
  {
    path: 'checkout',
    canActivate: [CheckoutGuard],
    children: [
      {
        path: 'shipping',
        data: getMeta('Checkout | Indirizzo di spedizione'),
        loadChildren: () => import('./pages/shipping/shipping.module').then(m => m.ShippingPageModule)
      },
      {
        path: 'payment',
        data: getMeta('Checkout | Pagamento'),
        loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentPageModule)
      },
    ]
  },
];
