<div class="container" *ngIf="preorderDate">

  <app-loader *ngIf="loader"></app-loader>

  <div class="row">
    <div class="col-6">
      <p class="uppercase title14 fw-700 preorder-text p-10 m-0">Tempo rimasto</p>
    </div>
    <div class="col-6" *ngIf="!expired && !loader">
      <p class="text-right text-20 fw-600 preorder-text p-10 m-0">
        <span>{{days ? days + ':' : ''}}</span>
        <span>{{hours ? (hours < 10 ? '0' + hours : hours) + ':' : '00:'}}</span>
        <span>{{minutes ? (minutes < 10 ? '0' + minutes : minutes) + ':' : '00:'}}</span>
        <span>{{seconds ? (seconds < 10 ? '0' + seconds : seconds) : '00'}}</span>
      </p>
    </div>
    <div class="col-12" *ngIf="expired && !loader">
      <p class="m-0 mt-15 text-center preorder-text fw-700">Preorder concluso</p>
      <p class="preorder-text text-center title14">
        Se hai partecipato al pre-order il tuo volume sarà spedito il giorno d’uscita.
      </p>
    </div>
  </div>

</div>
