<div class="p-16 radius8 overflow-hidden justify-content-start d-inline-flex g-10 pointer"
     style="width: 100%; height: 80px; background: #272726; border: 1px #535353 solid; align-items: flex-start;"
     *ngIf="!type">
  <div class="justify-content-start align-items-center d-flex g-10" style="max-width: 60px; height: 42px;"
       *ngIf="sourceImg">
    <img alt="edit" [src]="sourceImg"/>
  </div>
  <div class="flex-column justify-content-start g-4 d-inline-flex"
       style="flex: 1 1 0; align-items: flex-start;">
    <div class="align-self-stretch white-text bolder text-break text-capitalize" *ngIf="title">{{title}}</div>
    <div class="lightgrey-text align-self-stretch fw-400" *ngIf="message" [innerHTML]="message"></div>
  </div>
  <img class="pointer" *ngIf="edit" (click)="onEdit.emit()" alt="edit" [src]="'assets/icon/v2/account-edit.svg'"/>
  <img class="pointer"
       [matTooltip]="actionTitle"
       *ngIf="sourceActionIcon && action" (click)="onAction.emit()" alt="edit"
       [src]="sourceActionIcon"/>
  <img class="pointer" *ngIf="remove" (click)="onRemove.emit()" alt="edit" [src]="'assets/icon/v2/trash.svg'"/>
</div>
