import {Component, Input} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {IComic} from '../../interfaces/IComic';
import {UiService} from '../../services/ui.service';

import SwiperCore, {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
} from 'swiper';
import {IProduct} from '../../interfaces/IProduct';
import {CartService} from '../../services/cart.service';

SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);


@Component({
  selector: 'app-merch-card',
  templateUrl: './merch-card.component.html',
  styleUrls: ['./merch-card.component.scss'],
})
export class MerchCardComponent {

  @Input() product: IProduct;

  isImgLoaded: boolean = false;

  constructor(
    public route: ActivatedRoute,
    public ui: UiService,
    private cartService: CartService
  ) {

  }

  ngOnInit() {

  }

  addToCart() {
    this.cartService.addShoppingItem({
      product: this.product,
      qty: 1
    });
    this.ui.toast('Aggiunto alla Shopping Bag');
  }

}
