<ng-container *ngIf="auth.isAuthenticated.value && menu.length">
  <ng-container *ngFor="let menuItem of menu;">
    <mat-list *ngIf="!menuItem.children.length">
      <mat-list-item class="white-text">
        <a *ngIf="!menuItem.pathUrl" class="no-decoration" [routerLink]="menuItem.path" routerLinkActive="yellow-text">
          {{menuItem.label}}
        </a>
        <a *ngIf="menuItem.pathUrl" class="no-decoration" [href]="menuItem.pathUrl" target="_blank">
          {{menuItem.label}}
        </a>
      </mat-list-item>
    </mat-list>
    <mat-accordion class="example-headers-align black" *ngIf="menuItem.children.length">

      <mat-expansion-panel class="black shadow-none" [hideToggle]="menuItem.hideToggle"
                           [expanded]="isHideToggle(menuItem)">
        <mat-expansion-panel-header class="radius0">
          <mat-panel-title class="white-text">{{menuItem.label}}</mat-panel-title>
        </mat-expansion-panel-header>

        <mat-list>
          <mat-list-item *ngFor="let child of menuItem.children;" class="white-text">
            <a *ngIf="!child.pathUrl" class="no-decoration" [routerLink]="child.path" routerLinkActive="yellow-text">
              {{child.label}}
            </a>
            <a *ngIf="child.pathUrl" class="no-decoration" [href]="child.pathUrl" target="_blank">
              {{child.label}}
            </a>
          </mat-list-item>
        </mat-list>

      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>
  <ng-container *ngIf="isAdmin">
    <mat-list>
      <mat-list-item class="white-text">
        <a class="no-decoration" href="https://dashboard.jundo.it" target="_blank">Dashboard Web</a>
      </mat-list-item>
      <mat-list-item class="white-text">
        <a class="no-decoration" href="https://shop.jundo.it/shop/login" target="_blank">Dashboard Shop</a>
      </mat-list-item>
    </mat-list>
  </ng-container>
  <ng-container *ngIf="auth.isAuthenticated.value">
    <mat-list>
      <mat-list-item (click)="disconnectRequest()">
        <span class="red-text pointer">Disconnetti account</span>
      </mat-list-item>
    </mat-list>
  </ng-container>
</ng-container>
