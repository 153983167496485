import {Component, Input} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {IComic} from '../../interfaces/IComic';
import {UiService} from '../../services/ui.service';

import SwiperCore, {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
} from 'swiper';

SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);


@Component({
  selector: 'app-comic-card',
  templateUrl: './comic-card.component.html',
  styleUrls: ['./comic-card.component.scss'],
})
export class ComicCardComponent {

  @Input() comic: IComic;
  @Input() fromChapter: any;
  @Input() isNew: boolean;

  isImgLoaded: boolean = false;

  constructor(
    public route: ActivatedRoute,
    public ui: UiService
  ) {

  }

  ngOnInit() {

  }

}
