<swiper *ngIf="!isMobileLayout && sliders.length" [config]="sliderConfig" class="shop-swiper">
  <ng-template swiperSlide class="slide no-select" *ngFor="let slider of sliders;let i = index;">
    <img class="slider-image w100 pointer" alt="" [src]="ui.mediaSliderUrl(slider)" (click)="openSlider(slider)">
  </ng-template>
</swiper>

<swiper *ngIf="isMobileLayout && slidersMobile.length" [config]="sliderConfig" class="shop-swiper">
  <ng-template swiperSlide class="slide no-select" *ngFor="let slider of slidersMobile;let i = index;">
    <img class="slider-image w100 pointer" alt="" [src]="ui.mediaSliderUrl(slider)" (click)="openSlider(slider)">
  </ng-template>
</swiper>
