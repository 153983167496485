import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {IChapter} from '../../interfaces/IChapter';
import {ActivatedRoute, Router} from '@angular/router';
import {PurchaseService} from '../../services/purchase.service';
import {UiService} from '../../services/ui.service';
import {UtilityProvider} from '../../services/utility.service';
import * as moment from 'moment';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogAuthComponent} from '../dialog-auth/dialog-auth.component';

@Component({
  selector: 'app-comic-status-bar',
  templateUrl: './comic-status-bar.component.html',
  styleUrls: ['./comic-status-bar.component.scss'],
})
export class ComicStatusBarComponent implements OnInit {

  @Input() chapter: IChapter;

  @Output('on-refresh') onRefresh: EventEmitter<any> = new EventEmitter<any>();

  chapterIndex: number = 0;
  progressPerc: number = 0;
  isNew = false;
  isLock = false;
  completed = false;
  published_at: any;
  isPurchased = false;
  selected = false;
  loaderPurchase = false;

  constructor(
    public auth: AuthService,
    public ui: UiService,
    private router: Router,
    private route: ActivatedRoute,
    private purchaseService: PurchaseService,
    private utility: UtilityProvider,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {

    this.route.params.subscribe(params => {
      if (params.id) {
        this.selected = this.chapter.id === Number(params.id);
      }
    });

    if (this.chapter.comic) {

      this.isPurchased = this.utility.isPurchasedChapter(this.chapter);

      if (!this.isLock) {
        if (this.chapter.group) {
          this.chapterIndex = this.utility.chapterGroupIdx(this.chapter);
        } else {
          this.chapterIndex = this.utility.chapterIdx(this.chapter);
        }

        this.isLock = this.utility.isLockChapter(this.chapter);
      }
    }


    if (this.chapter.published_at) {
      this.published_at = moment(this.chapter.published_at);
      this.isNew = this.published_at.diff(moment()) >= 0;
    }

    if (this.chapter.progress) {
      this.progressPerc = this.chapter.progress.status || 0;
      if (this.progressPerc === 0) {
        this.progressPerc = 1;
      }
      if (this.chapter.comic.webtoon ? this.progressPerc >= 98 : this.progressPerc === 100) {
        this.progressPerc = 100;
      }
      this.completed = (this.chapter.comic.webtoon ? this.progressPerc >= 98 : this.progressPerc === 100);
    }
  }

  reader() {

    if (this.isNew) {
      return;
    }

    if (!this.isLock) {
      this.openReader();
    }

    if (this.isLock) {
      if (this.chapter.comic.is_coin) {
        if (
          this.isPurchased || (
            this.chapter.group ? (
              !this.chapter.group.coins && !this.chapter.coins
            ) : !this.chapter.coins
          )) {
          this.openReader();

        } else {
          if (this.auth.isAuthenticated.value) {
            this.openCoinsDialog();
          } else {
            this.redirectAccess();
          }
        }
      } else {
        if (this.auth.isSubscribed.value) {
          this.openReader();
        } else {
          this.openAccessSubscriptionDialog();
        }
      }
    }
  }

  fnUseCredit() {
    this.loaderPurchase = true;

    let body: any = {};

    if (this.chapter.group) {
      body.group_id = this.chapter.group.id;
    } else {
      body.chapter_id = this.chapter.id;
    }

    this.purchaseService.purchaseByCoins(body).subscribe(
      () => {
        this.loaderPurchase = false;
        this.onRefresh.emit();
        this.ui.toast('Transazione coin eseguita con successo');
      }, (error) => {
        if (error.error && error.error.message) {
          this.ui.error(error.error.message);
          this.onRefresh.emit();
        }
      });
  }

  redirectAccess() {
    this.router.navigate(['/access'], {
      relativeTo: this.route,
      queryParams: {
        page: 'signup',
        return_url: this.router.url,
        subscribe: false
      }
    });
  }

  openCoinsDialog() {

    let balance = 0;
    const coins = this.chapter.group ? this.chapter.group.coins : this.chapter.coins;

    if (this.auth.isAuthenticated.value && this.auth.user.value && this.auth.user.value.account.aggregate_balance) {
      balance = this.auth.user.value.account.aggregate_balance?.balance;
    }

    const data: any = {
      chapter: this.chapter,
      title: null,
      content: null,
      buttons: [
        {
          label: 'Acquista Coins',
          class: 'btn-outline-jundo',
          action: () => {
            if (!this.auth.isAuthenticated.value) {
              this.router.navigate(['/access'], {
                queryParams: {
                  page: 'signup',
                  return_url: this.router.url,
                  subscribe: false,
                  coin: true
                }
              });
            } else {
              this.router.navigate(['/purchase'], {
                queryParams: {
                  return_url: this.router.url
                }
              });
            }
          }
        }
      ]
    };

    if (balance >= coins) {

      if (this.utility.inArrayPreventRequest(this.chapter.comic.id)) {
        this.fnUseCredit();
        return false;
      }

      data.buttons.push({
        label: 'Utilizza credito',
        class: 'btn-jundo',
        action: () => {
          this.loaderPurchase = true;
          dialogRef.close();

          this.fnUseCredit();
        }
      });
    }

    data.title = 'Utilizza il credito per sbloccare questo ' + (this.chapter.group ? 'Volume' : 'Capitolo');
    data.content = 'Il capitolo che hai scelto è sbloccabile tramite i Jundo Coins.<br/> ' +
      'Crediti necessari: <strong class="yellow-text">' + coins + ' coins</strong><br/>' +
      'Hai a disposizione: ' + (balance < coins ? '<strong class="red-text">' + balance + ' coins</strong>' : '<strong class="yellow-text">' + balance + ' coins</strong>');

    const dialogRef: MatDialogRef<DialogAuthComponent> = this.dialog.open(DialogAuthComponent, {data: data});
    dialogRef.afterClosed().subscribe(() => {

    });
  }

  openReader() {
    this.router.navigate(['/comic/' + this.chapter.comic.id + '/reader/' + this.chapter.id], {
      relativeTo: this.route,
      replaceUrl: true
    }).then();
  }

  openAccessSubscriptionDialog() {
    this.dialog.open(DialogAuthComponent, {
      data: {
        title: 'Sblocca tutti i capitoli',
        content: 'Il capitolo che hai scelto è riservato agli utenti Jundo One. Abbonati subito e sblocca tutti i vantaggi legati al nostro abbonamento!',
        buttons: [
          {
            label: 'Abbonati a Jundo One',
            class: 'btn-jundo',
            action: () => {
              if (!this.auth.isAuthenticated.value) {
                this.router.navigate(['/access'], {
                  queryParams: {
                    page: 'signup',
                    return_url: this.router.url,
                    subscribe: true
                  }
                });
              } else {
                this.router.navigate(['/subscription'], {
                  queryParams: {
                    return_url: this.router.url
                  }
                });
              }
            }
          }
        ]
      }
    });
  }

}
