import {Injectable} from '@angular/core';
import {ShopService} from './shop.service';
import {CartService} from './cart.service';
import {Router} from '@angular/router';
import {ICart} from '../interfaces/ICart';
import {IUser} from '../interfaces/IUser';
import {UiService} from './ui.service';
import {ILineItems} from '../interfaces/ILineItem';
import {NgxSpinnerService} from 'ngx-spinner';
import {GlobalService} from './global.service';
import {StripeService as NgxStripeService} from 'ngx-stripe';
import {IOrder} from '../interfaces/IOrder';
import {ICoupon} from '../interfaces/ICoupon';

import {PaymentIntent, StripeElements} from '@stripe/stripe-js';
import {EPaymentType} from '../enums/EPaymentType';
import {ConfirmOrderDto} from '../dto/confirm-order.dto';
import {environment} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class StripeService {

  source: any;
  cartItems: ICart[] = [];

  shippingMethod: any;
  user: IUser;
  url: string;
  errors: any;
  freePrice: any;

  private orderId: string;

  appearance: any = {
    theme: 'stripe',

    variables: {
      colorPrimary: '#f7bd04',
      colorBackground: '#272726',
      colorText: '#fff',
      colorDanger: '#982339',
      fontFamily: '"Source Sans Pro", sans-serif',
      borderRadius: '8px',
      // See all possible variables below
    },
    labels: 'floating'
  };
  submitted: boolean = true;

  constructor(
    private shopService: ShopService,
    private cartService: CartService,
    private globalService: GlobalService,
    private router: Router,
    private ui: UiService,
    public spinner: NgxSpinnerService,
    private ngxStripeService: NgxStripeService,
  ) {
  }

  getCartItems() {
    this.cartService.cart.subscribe((items: ICart[]) => {
      this.cartItems = items;
    });
  }

  confirmPayment(paymentIntent: PaymentIntent, otherPaymentMethod = false, elements: StripeElements) {

    // let url = `${environment.apiUrl}checkout/success`;

    let options: any = {
      clientSecret: paymentIntent.client_secret,
      confirmParams: {
        return_url: `${environment.siteUrl}checkout/success`,
      },
      redirect: 'if_required'
    };

    if (otherPaymentMethod) {
      options.elements = elements;
      delete options.clientSecret;
    }

    this.ngxStripeService.confirmPayment(options)
      .subscribe((result) => {

        if (result.error) {
          this.ui.error(result.error.message);
          this.spinner.hide();
          this.shopService.cancelOrder(this.orderId).subscribe();
          this.submitted = false;
          return;
        }

        this.confirmOrder(result.paymentIntent.id);
      });
  }

  createOrder(paymentIntentId = null, otherPaymentMethod = false, coupon: ICoupon, elements: StripeElements) {

    this.spinner.show();
    this.ui.spinnerText = 'Creazione ordine in corso...';

    const body = this.createPayloadOrder(coupon, EPaymentType.STRIPE, paymentIntentId);

    this.shopService.createOrder(body)
      .subscribe(
        (result: any) => {

          this.orderId = result.order.id;

          if (result.payment.result && result.payment.result.id) {
            this.confirmPayment(result.payment.result, otherPaymentMethod, elements);
          } else {
            this.spinner.hide();
            this.ui.error(result.error.message);
          }

        },
        (error) => {
          if (error.error && error.error.message) {
            error.error.message = error.error.message.replace('[woocommerce_rest_invalid_coupon]', '');
          }
          this.ui.error(error.error.message);
          this.spinner.hide();
        });
  }

  confirmOrder(paymentIntentId: string, paymentType = EPaymentType.STRIPE) {
    const body: ConfirmOrderDto = {
      paymentId: paymentIntentId,
      paymentType: paymentType,
    };
    this.shopService.confirmOrder(body)
      .subscribe(
        (order: IOrder) => {
          this.spinner.hide();
          this.cartService.resetCart();
          this.router.navigate(['checkout/success'], {
            replaceUrl: true,
            queryParams: {
              redirect: '/shop',
              code: order.code,
              id: order.id
            }
          });
        },
        (error) => {
          this.spinner.hide();
          this.ui.error(error.error.message);
        });
  }

  createPayloadOrder(coupon: ICoupon, paymentType = EPaymentType.STRIPE, paymentIntentId = null): any {
    let lineItems: ILineItems[] = [];
    this.cartItems.forEach((item: ICart) => {
      const itemObj: ILineItems = {
        product_id: item.product.id,
        quantity: item.qty
      };

      if (item.variantId && item.variantId > 0) {
        itemObj.variation_id = item.variantId;
      }

      if (item.variants && item.variants.length) {

        const bundle_configuration = [];

        item.product.bundled_items.forEach((bundle_item) => {

          const item_bundle: any = {
            bundled_item_id: bundle_item.bundled_item_id,
            product_id: bundle_item.product_id
          };

          const variation = item.variants.find(variant => variant.product_id === bundle_item.product_id);
          if (variation) {
            item_bundle.variation_id = variation.id;
            item_bundle.attributes = variation.attributes;
          }

          bundle_configuration.push(item_bundle);
        });

        itemObj['bundle_configuration'] = bundle_configuration;
      }

      lineItems.push(itemObj);
    });
    let body: any = {
      cartList: lineItems,
      paymentType: paymentType,
      shippingId: this.shippingMethod.id,
      seal_up: this.globalService.seal_up,
      coupon: coupon ? coupon.code : null,
    };
    if (paymentIntentId) {
      body.paymentIntentId = paymentIntentId;
    }
    return body;
  }

}
