import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    public authService: AuthService,
    public router: Router
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    let return_url = state.url;

    if (state.url.includes('/subscription')) {
      return_url = '/home';
    }

    if (!this.authService.isAuthenticated.value) {
      this.router.navigate(['/access'], {queryParams: {return_url}}).then();
    }
    return true;
  }
}
