import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PaymentMethodTypeConstant} from '../../constants/payment-method-type.constant';
import {AuthService} from '../../services/auth.service';
import {Params, Router} from '@angular/router';
import {PaymentMethodService} from '../../services/payment-method.service';
import {PaymentMethod} from '@stripe/stripe-js';
import {IPackage} from '../../interfaces/IPackage';

@Component({
  selector: 'app-payment-method-box',
  templateUrl: './payment-method-box.component.html',
  styleUrls: ['./payment-method-box.component.scss'],
})
export class PaymentMethodBoxComponent {

  @Output('on-edit') onEdit: EventEmitter<void> = new EventEmitter<void>();

  @Input('edit-url') editUrl: string;
  @Input() package: IPackage;

  pmConstant = PaymentMethodTypeConstant;
  paymentMethod: PaymentMethod;

  constructor(
    public auth: AuthService,
    public router: Router,
    private pmService: PaymentMethodService
  ) {

  }

  ngOnInit() {
    this.getDefaultPaymentMethod();
  }

  private getDefaultPaymentMethod() {
    this.pmService.defaultPaymentMethod().subscribe(data => {
      this.paymentMethod = data;
    });
  }

  editPayment(){
    let queryParams: Params = {};

    if(this.package){
      queryParams = {
        redirect: this.router.url,
        product: this.package.id
      }
    }

    this.router.navigate([this.editUrl], {
      queryParams
    });
  }

}
