import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'orderStatus'
})
export class orderStatus implements PipeTransform {
    transform(value: any, args?: any): any {

        switch (value) {
            case 'failed':
                return 'Fallito';
            case 'processing':
                return 'In lavorazione';
            case 'shipped':
                return 'Spedito';
            case 'completed':
                return 'Completato';
            case 'cancelled':
                return 'Cancellato';
            case 'pending':
                return 'In attesa di pagamento';
            case 'refunded':
                return 'Rimborsato';
            default:
                return 'In attesa'
        }

    }
}
