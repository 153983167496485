import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IComic} from '../../interfaces/IComic';
import {UiService} from '../../services/ui.service';
import {ItemService} from '../../services/items.service';

export interface DialogData {
  comic: IComic;
}

@Component({
  selector: 'app-dialog-like',
  templateUrl: './dialog-like.component.html',
  styleUrls: ['./dialog-like.component.scss'],
})
export class DialogLikeComponent {

  isLike: boolean;
  isDislike: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<DialogLikeComponent>,
    private ui: UiService,
    private itemsService: ItemService
  ) {
    if (this.data && this.data.comic) {
      if (this.data.comic.dislikes.length) {
        const dislike = this.data.comic.dislikes[0];
        this.isLike = dislike.like === 1;
        this.isDislike = dislike.like !== 1;
      }
    }
  }

  close() {
    this.dialogRef.close();
  }

  like() {
    // this.close();
    this.ui.toast('Grazie per il tuo feedback!');

    if (!this.isLike) {
      this.isLike = true;
      this.isDislike = false;
    } else {
      this.isLike = false;
      this.isDislike = false;
    }

    this.itemsService.like(this.data.comic.id).subscribe();
    this.dialogRef.close();
  }

  dislike() {
    // this.close();
    this.ui.toast('Grazie per il tuo feedback!');

    if (!this.isDislike) {
      this.isLike = false;
      this.isDislike = true;
    } else {
      this.isLike = false;
      this.isDislike = false;
    }

    this.itemsService.dislike(this.data.comic.id).subscribe();
    this.dialogRef.close();
  }

}
