import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UtilityProvider} from '../../services/utility.service';
import {IChapter} from '../../interfaces/IChapter';

export interface DialogData {
  chapter?: IChapter;
  returnPath?: string;
  title?: string;
  content?: string;
  menu?: { label: string, action?: Function, src?: string }[],
  buttons?: { label: string, action?: Function, class?: string }[]
}

@Component({
  selector: 'app-dialog-auth',
  templateUrl: './dialog-auth.component.html',
  styleUrls: ['./dialog-auth.component.scss'],
})
export class DialogAuthComponent {

  returnPath?: string = '/home';
  title: string = '';
  content: string = '';
  menu?: { label: string, action?: Function, src?: string }[] = [];
  buttons?: { label: string, action?: Function, class?: string }[] = [];
  isCoin: boolean = false;

  constructor(
    public utility: UtilityProvider,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    if (data) {
      this.title = data.title;
      this.content = data.content;
      this.menu = data.menu;
      this.buttons = data.buttons;
      this.returnPath = data.returnPath;
      this.isCoin = !!(data.chapter && data.chapter.comic.is_coin);
    }
  }

}
