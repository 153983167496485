import {IPermission} from '../interfaces/IPermission';

export class PermissionsConstant {

  public static readonly USER: IPermission = {id: 1, permission_code: 'ROLE_USER', label: 'User'};
  public static readonly ADMIN: IPermission = {id: 2, permission_code: 'ROLE_ADMIN', label: 'Admin'};
  public static readonly SUPER_ADMIN: IPermission = {id: 3, permission_code: 'ROLE_SUPER_ADMIN', label: 'Super Admin'};
  public static readonly AUTHOR: IPermission = {id: 4, permission_code: 'ROLE_AUTHOR', label: 'Author'};
  public static readonly EDITOR: IPermission = {id: 8, permission_code: 'ROLE_PUBLISHER', label: 'Editor'};

}
