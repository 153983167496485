import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ICart} from '../../interfaces/ICart';
import {CartService} from '../../services/cart.service';
import {UiService} from '../../services/ui.service';
import {DialogComponent} from '../dialog/dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {CoverTypeConstant} from '../../constants/cover-type.constant';
import {Router} from '@angular/router';

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss'],
})
export class CartItemComponent {

  @Output('on-edit') onEdit: EventEmitter<void> = new EventEmitter<void>();
  @Output('on-remove') onRemove: EventEmitter<void> = new EventEmitter<void>();
  @Output('on-open') onOpen: EventEmitter<void> = new EventEmitter<void>();

  @Input() cartItem: ICart;

  coverTypeConstants = CoverTypeConstant;

  constructor(
    public cartService: CartService,
    public ui: UiService,
    private dialog: MatDialog,
    private router: Router
  ) {
  }

  removeQuantity(item: ICart) {
    if (item.qty < 2) {
      return;
    } else {
      this.cartService.deleteShoppingItem({
        qty: 1,
        variantId: item.variantId,
        product: item.product,
        preorder: item.preorder,
        variants: item.variants
      });
      this.onEdit.emit();
    }
  }

  addQuantity(item: ICart) {
    this.cartService.addShoppingItem({
      qty: 1,
      variantId: item.variantId,
      product: item.product,
      preorder: item.preorder,
      variants: item.variants
    });
    this.onEdit.emit();
  }

  confirmDelete(item: ICart) {
    const dialog = this.dialog.open(DialogComponent, {
      data: {
        title: 'Rimuovere dalla Shopping Bag?',
        message: 'Sei sicuro di voler rimuovere questo articolo dalla Shopping Bag? Rimuovendolo non sarà inserito nell’ordine, per acquistarlo dovrai riaggiungerlo in seguito.'
      }
    });
    dialog.afterClosed().subscribe(data => {
      if (data) {
        this.removeItem(item);
      }
    });
  }

  removeItem(cartItem: ICart) {
    let cartObj: ICart = {product: cartItem.product, qty: 0};
    if (cartItem.variantId) {
      cartObj.variantId = cartItem.variantId;
    }

    this.cartService.deleteShoppingItem(cartItem);
    this.onRemove.emit();
  }

  openProduct(cartItem: ICart) {
    this.onOpen.emit();
    this.router.navigate(['shop/product/' + cartItem.product.slug]);
  }
}
