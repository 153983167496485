import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {ItemService} from '../../services/items.service';
import {IComic} from '../../interfaces/IComic';
import {UiService} from '../../services/ui.service';
import * as moment from 'moment/moment';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {IProduct} from '../../interfaces/IProduct';
import {IsMerchandisingPipe} from 'src/app/pipes/is-merchandising.pipe';
import {UtilityProvider} from '../../services/utility.service';
import {DialogLikeComponent} from '../dialog-like/dialog-like.component';
import {IActionComic} from '../../interfaces/IActionComic';
import {ChannelsService} from '../../services/channels.service';

@Component({
  selector: 'app-dialog-comic',
  templateUrl: './dialog-comic.component.html',
  styleUrls: ['./dialog-comic.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [IsMerchandisingPipe]
})
export class DialogComicComponent {

  comic: IComic;

  productsAssociated: IProduct[] = [];
  merchandisingAssociated: IProduct[] = [];
  loader = true;

  title: string = '';
  message: string = '';
  published_at: any = null;

  isFavorite: boolean = false;
  isLike = false;
  isDislike = false;
  isReadMore = false;

  constructor(
    public dialog: MatDialog,
    public itemService: ItemService,
    public ui: UiService,
    public channels: ChannelsService,
    public utility: UtilityProvider,
    public auth: AuthService,
    private router: Router,
    private isMerchandisingPipe: IsMerchandisingPipe,
    @Inject(MAT_DIALOG_DATA) public data: { comicId: number; }
  ) {
  }

  ngOnInit() {
    this.getComic();
  }

  profile() {
    this.auth.profile().subscribe((user) => {
      this.isFavorite = !!user.jundo_user.favorites.find(item => item.id === Number(this.comic.id));
    });
  }

  getComic() {
    this.itemService.comic(this.data.comicId).subscribe(comic => {
      this.comic = comic;
      this.published_at = moment(comic.published_at);

      if (this.comic.products && this.comic.products.length) {
        this.productsAssociated = this.comic.products.filter(product => !this.isMerchandisingPipe.transform(product));
        this.merchandisingAssociated = this.comic.products.filter(product => this.isMerchandisingPipe.transform(product));
      }

      if (comic.dislikes && comic.dislikes.length) {
        const dislike = comic.dislikes[0];
        this.isLike = dislike.like === 1;
        this.isDislike = dislike.like !== 1;
      } else {
        this.isLike = false;
        this.isDislike = false;
      }

      if (this.auth.isAuthenticated.value) {
        this.profile();
      }
    });
  }

  close() {
    this.dialog.closeAll();
  }

  onRefresh() {
    this.ngOnInit();
  }

  openLikeDialog() {

    if (!this.auth.isAuthenticated.value) {
      this.ui.openSignupDialog();
      return;
    }

    const dialogRef = this.dialog.open(DialogLikeComponent, {
      data: {comic: this.comic}
    });

    dialogRef.afterClosed().subscribe(() => {
      this.itemService.comic(this.comic.id)
        .subscribe((comic: IComic) => {
          this.comic = comic;

          if (comic.dislikes && comic.dislikes.length) {
            const dislike = comic.dislikes[0];
            this.isLike = dislike.like === 1;
            this.isDislike = dislike.like !== 1;
          } else {
            this.isLike = false;
            this.isDislike = false;
          }
        });
    });
  }

  addFavorite() {

    if (!this.auth.isAuthenticated.value) {
      this.ui.openSignupDialog();
      return;
    }

    this.isFavorite = !this.isFavorite;
    this.itemService.toggleFavorite(this.comic.id)
      .subscribe((data: IActionComic) => {
        this.isFavorite = !!data.item_id;
        this.channels.publish('favorite:update', {});
      });
  }

  openReader() {
    this.router.navigate(['/comic/' + this.comic.id + '/reader/', this.utility.progressChapterId(this.comic)]).then();
  }

  convertTag(description: string) {
    if (!description) {
      return '';
    }
    const val1 = description.replace('<p>', '<span>');
    return val1.replace('</p>', '</span>');
  }
}
