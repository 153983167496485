import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {Meta, MetaDefinition, Title} from '@angular/platform-browser';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor(
    @Inject(DOCUMENT) private dom,
    private titleSvc: Title,
    private metaSvc: Meta
  ) {
  }

  updateTitle(title: string) {
    if (title === 'Home') {
      title = 'Jundo - Leggi Manga e Webtoon Online';
      this.titleSvc.setTitle(title);
      this.metaSvc.updateTag({property: 'og:title', title});
      this.metaSvc.updateTag({name: 'twitter:title', title});
      this.metaSvc.removeTag('itemprop=\'name\'');
      this.metaSvc.addTag({itemprop: 'name', title});
    } else {
      const content = title + ' | Leggi Manga e Webtoon Online';
      this.titleSvc.setTitle(content);
      this.metaSvc.updateTag({property: 'og:title', content});
      this.metaSvc.updateTag({name: 'twitter:title', content});
      this.metaSvc.removeTag('itemprop=\'name\'');
      this.metaSvc.addTag({itemprop: 'name', content});
    }
  }

  updateDescription(content: string) {
    this.metaSvc.removeTag('itemprop=\'description\'');
    this.metaSvc.addTag({itemprop: 'description', content});
    this.metaSvc.updateTag({name: 'description', content});
    this.metaSvc.updateTag({name: 'twitter:description', content});
    this.metaSvc.updateTag({property: 'og:description', content});
  }

  addTags(tags: MetaDefinition[]) {
    this.metaSvc.addTags(tags);
  }

  removeTags(selectors: string[]) {
    selectors.forEach(selector => this.metaSvc.removeTag(selector));

  }

  updateTag(tag: MetaDefinition) {
    this.metaSvc.updateTag(tag);
  }

  updateUrl(url: string) {
    const content = environment.siteUrl + url;
    this.metaSvc.updateTag({property: 'og:url', content});
  }

  updateImage(content: string) {
    this.metaSvc.updateTag({property: 'og:image', content});
    this.metaSvc.updateTag({name: 'twitter:image:src', content});
    this.metaSvc.removeTag('itemprop=\'image\'');
    this.metaSvc.addTag({itemprop: 'image', content});
  }

  createCanonicalLink(url: string) {
    let link: HTMLLinkElement =
      this.dom.createElement('link');

    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', url);
    this.dom.head.appendChild(link);
  }

  setCanonicalURL(url?: string) {
    const canURL = url == undefined ? this.dom.URL : url;
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);
  }

}
