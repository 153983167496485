import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';
import {IAttribute} from '../interfaces/IAttribute';
import {environment} from '../../environments/environment';

@Pipe({
  name: 'avatarResource'
})
export class AvatarResourcePipe implements PipeTransform {


  transform(value: string, args?: string): any {

    const urlPattern = new RegExp('(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?');
    const isUrl = !!urlPattern.test(value);

    if (!isUrl) {
      const filename = value.substring(value.lastIndexOf('/') + 1);
      return environment.distributionUrl + '/profile-icons/' + filename;
    }

    return value;
  }
}
