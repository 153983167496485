import {Component, Input, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IProduct} from '../../interfaces/IProduct';
import {IComic} from '../../interfaces/IComic';
import {UiService} from '../../services/ui.service';
import {ItemService} from '../../services/items.service';
import * as _ from 'lodash';

import SwiperCore, {Keyboard, Mousewheel, Navigation, Pagination,} from 'swiper';
import {ICarousel} from '../../interfaces/ICarousel';
import {environment} from '../../../environments/environment';

SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);

@Component({
  selector: 'app-comic-slider-bigger',
  templateUrl: './comic-slider-bigger.component.html',
  styleUrls: ['./comic-slider-bigger.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ComicSliderBiggerComponent {

  @Input() carousel: ICarousel;

  @Input() api?: string;
  @Input() required: boolean | number = false;
  @Input() shop: boolean = false;
  @Input() soon: boolean | number = 0;
  @Input() marker: boolean = false;
  @Input() showPrice: boolean = false;
  @Input('big-order') bigOrder: boolean = false;
  @Input('alternative-chip') alternativeChips: boolean = false;
  @Input('from-chapter') fromChapter: any;
  @Input('first-slide') firstSlide = true;
  @Input() isNew: boolean;

  isImgLoaded: boolean = false;

  values: IComic[] = [];

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    public ui: UiService,
    private itemService: ItemService,
  ) {

  }

  ngOnInit() {
    this.values = _.cloneDeep(this.carousel.comics);
  }

  openProduct(comic: any): void {
    this.router.navigate(this.shop ? ['/product', comic.id] : ['/home'], {
      relativeTo: this.route,
      queryParams: !this.shop ? {comic: comic.id} : {}
    }).then();
  }

  openList(): void {
    if (!this.shop) {
      this.itemService.tempStorageComics = _.cloneDeep(this.carousel.comics);
    }
    this.router.navigate(['/comics'], {
      relativeTo: this.route,
      queryParams: {
        'title': this.carousel.title,
        'api': this.api
      }
    }).then();
  }

  hideProgress(comic: IProduct | IComic) {
    const idx = this.values.findIndex(item => item.id === comic.id);

    this.values.splice(idx, 1);
    this.carousel.comics.splice(idx, 1);

    this.itemService.hideProgress(comic.id).subscribe();
  }

  protected readonly environment = environment;
}
