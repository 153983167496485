import {Pipe, PipeTransform} from '@angular/core';
import {PaymentMethod} from '@stripe/stripe-js';
import {PaymentMethodTypeConstant} from '../constants/payment-method-type.constant';
import {AuthService} from '../services/auth.service';

@Pipe({
  name: 'pmTitle'
})
export class PmTitlePipe implements PipeTransform {

  methodConstant = PaymentMethodTypeConstant;

  constructor(private auth: AuthService) {
  }

  transform(pm: PaymentMethod, args?: any[] | undefined): string {

    if (this.auth.user.value && this.auth.user.value.jundo_user.stripe_customer_default_method) {
      switch (this.auth.user.value.jundo_user.stripe_customer_default_method) {
        case this.methodConstant.CARD:
          return (pm && pm.card && pm.card.brand) ? pm.card.brand : null;
        case this.methodConstant.PAYPAL:
        case this.methodConstant.APPLE_PAY:
        case this.methodConstant.GOOGLE_PAY:
          return null;
        default:
          return null;
      }
    }

    return null;
  }

}
