<div class="close pointer" (click)="close()">
  <img src="assets/icon/jundo/arrow-left.svg" alt="back"/>
</div>

<div class="wrapper">
  <div class="overlay"></div>

  <div class="row position-relative form-box mb-40" *ngIf="mode === 'methods'">
    <div class="col-12">
      <h1 class="title text-22 fw-700 mt-32 text-center">Metodo di pagamento</h1>
      <p class="fw-400 text-center">Relativo agli acquisti sullo Shop Online</p>
    </div>
    <div class="col-12">
      <mat-radio-group aria-labelledby="vertical-radio-group-label">

        <form novalidate [formGroup]="form" (ngSubmit)="confirm()">
          <mat-radio-group
            aria-labelledby="example-radio-group-label"
            class="example-radio-group"
            [formControl]="form.controls['method_id']">

            <ng-container *ngFor="let method of methods;">

              <div [ngClass]="[form.controls['method_id'].value === method.id ? 'active' : '']"
                   class="method-box mb-20 p-16 w-100 justify-content-start align-items-center d-inline-flex radius8 pointer g-16"
                   style="height: 60px; background: #272726;" (click)="selectPlan(method)">
                <div class="justify-content-start align-items-center d-flex" style="gap: 16px;">
                  <mat-radio-button class="vertical-radio-button white-text title18 fw-400 m-0"
                                    color="primary"
                                    [disabled]="method.disabled"
                                    [value]="method.id">
                    <img *ngIf="method.iconSrc"
                         [ngClass]="[method.disabled ? 'opacity-25' : '']"
                         class="ml-20 no-select"
                         [alt]="method.id" [src]="'assets/icon/v2/' + method.iconSrc"/>
                    <span class="white-text m-0 pl-20 no-select"
                          [ngClass]="[method.disabled ? 'opacity-25' : '']">
                                          {{method.label}}
                                        </span>
                  </mat-radio-button>
                </div>
              </div>

              <div
                *ngIf="form.controls['method_id'].value === methodConstant.CARD && method.id === methodConstant.CARD">

                <div *ngFor="let pm of paymentMethods;" (click)="setDefaultPaymentMethod(pm)"
                     class="mb-10 p-16 radius8 overflow-hidden justify-content-start d-inline-flex g-10 pointer"
                     style="width: 100%; height: 80px; background: #272726; border: 1px #535353 solid; align-items: flex-start;">
                  <div class="justify-content-start align-items-center d-flex g-10" *ngIf="pm.card && pm.card.brand"
                       style="width: 40px; height: 42px;">
                    <img alt="edit" [src]="'assets/icon/v2/' + pm.card.brand + '.svg'"/>
                  </div>
                  <div class="flex-column justify-content-start g-4 d-inline-flex"
                       style="flex: 1 1 0; align-items: flex-start;">
                    <div class="align-self-stretch white-text fw-600 text-break text-capitalize">
                      {{pm.card && pm.card.brand ? pm.card.brand : pm.type}}
                    </div>
                    <div class="lightgrey-text align-self-stretch fw-400" *ngIf="pm.card">
                      {{'•••• •••• •••• ' + pm.card.last4}}
                    </div>
                  </div>
                  <!--<img alt="edit" src="assets/icon/v2/account-edit.svg"/>-->
                  <i [ngClass]="[pm['default'] ? 'fa-solid fa-star yellow-text' : 'fa-regular fa-star grey-text']"></i>
                </div>

                <div (click)="changeMode('manage-card')"
                     class="mt-10 mb-20 w-100 pl-8 pr-12 text-center overflow-hidden justify-content-center align-items-center d-inline-flex g-8"
                     style="height: 30px;">
                  <img class="no-select" alt="cc" src="assets/icon/v2/cc-yellow.svg"/>
                  <div class="lightyellow-text text-14 fw-600 text-break pointer no-select bolder">
                    Aggiungi una nuova carta
                  </div>
                </div>

                <div class="w-100 mb-40" style="height: 0; border: 1px #30302F solid"></div>

              </div>

            </ng-container>

          </mat-radio-group>
        </form>

      </mat-radio-group>

    </div>
    <div class="col-12">
      <button [disabled]="form.invalid || (form.controls['method_id'].value === methodConstant.CARD && !paymentMethods.length)"
              type="submit" class="btn btn-jundo w-100 mt-20" (click)="confirm()">
        Conferma e continua
      </button>
    </div>
  </div>

  <div class="row position-relative form-box mb-40" *ngIf="mode === 'manage-card'">
    <div class="col-12">
      <h1 class="title text-22 fw-700 mt-32 text-center">Metodo di pagamento</h1>
      <p class="fw-400 text-center">Inserisci una nuova carte di credito</p>
    </div>
    <div class="col-12">
      <form novalidate [formGroup]="formCard" (ngSubmit)="saveCardAndConfirm()">
        <div class="row manage-card-box" ngxStripeCardGroup [formGroup]="formCard"
             [elementsOptions]="elementsOptions">
          <div class="col-12">
            <app-input label="Nome completo" [model]="formCard.controls['name']"
                       placeholder="Nome completo"></app-input>
          </div>
          <div class="col-12">
            <ngx-stripe-card-number [options]="numberOptions"></ngx-stripe-card-number>
          </div>
          <div class="col-6">
            <ngx-stripe-card-expiry [options]="expOptions"></ngx-stripe-card-expiry>
          </div>
          <div class="col-6">
            <ngx-stripe-card-cvc [options]="cvvOptions"></ngx-stripe-card-cvc>
          </div>
        </div>
      </form>
    </div>
    <div class="col-12" *ngIf="error">
      <p class="text-12 text-center text-break red-text">{{error}}</p>
    </div>
    <div class="col-12">
      <p class="text-12 text-center text-break grey-text">
        La carta di credito verrà salvata come metodo di pagamento predefinito
      </p>
    </div>
    <div class="col-12">
      <div class="text-14 lightyellow-text bolder text-break pointer text-center mb-30 mt-20"
           (click)="changeMode('methods')">
        Utilizza un altro metodo di pagamento
      </div>
    </div>
    <div class="col-12">
      <button *ngIf="!loader" type="submit" class="btn btn-jundo w-100 mt-20"
              [disabled]="!formCard.valid"
              (click)="saveCardAndConfirm()">
        {{type === 'checkout' ? 'Conferma e vai al riepilogo' : 'Conferma e continua'}}
      </button>
    </div>
  </div>

</div>
