import {Pipe, PipeTransform} from '@angular/core';
import {IVariation} from '../interfaces/IVariation';

@Pipe({
  name: 'variant'
})
export class VariantPipe implements PipeTransform {

  transform(values: IVariation[], args: any[]): any {
    if (args && values && values.length) {
      const item = values.find(data => data.id === args[0]);
      if (item && item.attributes && item.attributes.length) {
        if (args[1] === 'name') {
          return item.attributes[0]['option'];
        }
        return item;
      }
    }
    return null;
  }
}
