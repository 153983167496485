<div class="stepper">
  <div class="step completed">
    <div class="step-label"></div>
    <div class="step-description uppercase bolder">
      <a routerLink="/shop/cart" class="no-decoration">Carrello</a>
    </div>
  </div>
  <div *ngFor="let step of steps;" class="step {{step.class ? step.class : ''}}">
    <div class="step-label"></div>
    <div class="step-description uppercase bolder">
      <a *ngIf="step.class" [routerLink]="step.url" class="no-decoration">{{step.label}}</a>
      <span *ngIf="!step.class" class="pointer">{{step.label}}</span>
    </div>
  </div>
</div>
