import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, tap} from 'rxjs/operators';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent {

  @ViewChild('searcher') searcher: ElementRef;
  @Output('changeModel') onChangeModel: EventEmitter<any> = new EventEmitter<any>();
  @Output('openFilter') onOpenFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output('confirmCoupon') onConfirmCoupon: EventEmitter<any> = new EventEmitter<any>();
  @Output('on-blur') onBlur: EventEmitter<any> = new EventEmitter<any>();

  toggleIcon: boolean = false;
  errorMessage: string;

  @Input() model: FormControl = new FormControl();
  @Input() label: string;
  @Input() hint: string;
  @Input() placeholder: string;
  @Input() type: string = 'text';
  @Input() icon: boolean;
  @Input() loader: boolean;
  @Input() disabled: boolean;
  @Input() checkForm: boolean;
  @Input() autofocus: boolean;
  @Input() uppercase: boolean;
  @Input() isSearch: boolean;
  @Input() isCoupon: boolean;
  @Input() isPassword: boolean;
  @Input() isFilter: boolean;
  @Input() iconMessage: string;
  @Input() error: string;
  @Input() debounce: number = 500;

  constructor() {
  }

  ngOnInit() {
    this.errorMessage = !this.error ? 'campo non valido' : this.error;
    if (this.autofocus) {
      setTimeout(() => this.searcher.nativeElement.focus());
    }
  }

  ngAfterViewInit() {
    // server-side search
    fromEvent(this.searcher.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(this.debounce),
        distinctUntilChanged(),
        tap((text) => {
          this.changeModel();
        })
      )
      .subscribe();
  }

  changeModel() {
    this.model.markAsUntouched();
    this.model.updateValueAndValidity();

    if (this.checkForm && this.model.valid) {
      this.checkForm = false;
    }
    this.onChangeModel.emit(this.searcher.nativeElement.value);
  }

  openFilter() {
    this.onOpenFilter.emit();
  }

  togglePassword() {
    if (this.type === 'password') {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
  }

  confirmCoupon() {
    this.onConfirmCoupon.emit();
  }
}
