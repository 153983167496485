import {Action} from '@ngrx/store';
import {IFilters} from "../interfaces/IFilters";

export enum ActionTypes {
    UpdateFilters = '[Update Filters Service] Update filters',
    UpdateFiltersShop = '[Update Filters Service] Update filters shop',
    InitializeFilters = '[Initialize Filters Service] Initialize filters',
    InitializeFiltersShop = '[Initialize Filters Service] Initialize filters shop'
}

export class InitializeFilters implements Action {
    readonly type = ActionTypes.InitializeFilters;

    constructor(public payload: {}) {
    }
}

export class InitializeFiltersShop implements Action {
    readonly type = ActionTypes.InitializeFiltersShop;

    constructor(public payload: {}) {
    }
}

export class UpdateFilters implements Action {
    readonly type = ActionTypes.UpdateFilters;

    constructor(public payload: { item: IFilters }) {
    }
}

export class UpdateFiltersShop implements Action {
    readonly type = ActionTypes.UpdateFiltersShop;

    constructor(public payload: { item: IFilters }) {
    }
}

export type ActionsUnion =
    InitializeFilters |
    UpdateFilters |
    InitializeFiltersShop |
    UpdateFiltersShop;
