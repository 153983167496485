import {Component, Input} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash';
// @ts-ignore
import * as countries from '../../../assets/json/countries.json';
import {AuthService} from '../../services/auth.service';


@Component({
  selector: 'app-address-data',
  templateUrl: './address-data.component.html',
  styleUrls: ['./address-data.component.scss'],
})
export class AddressDataComponent {

  @Input() form: FormGroup;
  @Input() fieldsShipping: any[];
  @Input() fieldsFiscal: any[];

  countries = [];

  constructor(
    public auth: AuthService
  ) {
    this.countries = _.map(countries);
  }

  setEqualFiscalValidators() {

    let initObj: any = {};
    this.fieldsFiscal.forEach((field) => initObj[field.name] = this.form.get(field.name));

    this.form.get('equal_fiscal').valueChanges
      .subscribe(isEqual => {
        this.fieldsFiscal.forEach((field) => {
          isEqual ? initObj[field.name].setValidators(null) : initObj[field.name].setValidators([Validators.required]);
        });
        this.fieldsFiscal.forEach((field) => initObj[field.name].updateValueAndValidity());
      });
  }

}
