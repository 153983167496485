import {Pipe, PipeTransform} from '@angular/core';
import {IVariation} from '../interfaces/IVariation';

@Pipe({
  name: 'filterVariationsBy'
})
export class FilterVariationsByPipe implements PipeTransform {

  transform(variations: IVariation[], property: string): IVariation[] {
    if (property && variations && variations.length) {
      return variations.filter(variation => variation.attributes[0]['name'] === property);
    }
    return [];
  }
}
