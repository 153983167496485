import {Component} from '@angular/core';
import {IShopSlider} from '../../interfaces/IShopSlider';
import {DataService} from '../../services/data.service';
import {SwiperOptions} from 'swiper';
import {UiService} from '../../services/ui.service';
import {UtilityProvider} from '../../services/utility.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-shop-slider',
  templateUrl: './shop-slider.component.html',
  styleUrls: ['./shop-slider.component.scss'],
})
export class ShopSliderComponent {

  sliders: IShopSlider[] = [];
  slidersMobile: IShopSlider[] = [];
  sliderConfig: SwiperOptions = {
    navigation: true,
    pagination: true,
    mousewheel: false,
    keyboard: false,
    loop: true,
    initialSlide: 0,
    slidesPerView: 1,
    spaceBetween: 0,
    scrollbar: {
      draggable: true
    }
  };

  isMobileLayout = false;

  constructor(
    public dataService: DataService,
    public ui: UiService,
    private utility: UtilityProvider,
    private router: Router
  ) {
  }

  ngOnInit() {
    if (this.ui.isBrowser()) {
      this.isMobileLayout = window.innerWidth <= 991;
      window.onresize = () => this.isMobileLayout = window.innerWidth <= 991;
    }

    this.sliderList();
    this.sliderMobileList();
  }

  sliderList() {
    this.dataService.sliders().subscribe((data: IShopSlider[]) => {
      this.sliders = data;
    });
  }

  sliderMobileList() {
    this.dataService.slidersMobile().subscribe((data: IShopSlider[]) => {
      this.slidersMobile = data;
    });
  }

  async openSlider(slider: IShopSlider) {
    if (this.ui.isBrowser()) {
      const url = slider.url;
      if (this.utility.isValidUrl(url)) {
        window.open(url, slider.target ? '_blank' : '_self');
      } else {
        await this.router.navigate([url]);
      }
    }
  }
}
