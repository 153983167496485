import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';
import {IChapter} from '../interfaces/IChapter';

@Injectable({
  providedIn: 'root'
})
export class ChaptersService {

  url: string;

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.url = environment.apiUrl;
  }

  chapter(id: number): Observable<IChapter> {
    return this.http.get<IChapter>(this.url + 'v3/chapters/' + id, this.getOptions());
  }

  meta(id: string): Observable<IChapter> {
    return this.http.get<IChapter>(this.url + 'v3/meta/chapters/' + id);
  }

  find(id: number): Observable<IChapter> {
    return this.http.get<IChapter>(this.url + 'admin/v2/chapters/' + id, this.getOptions());
  }

  create(body): Observable<IChapter> {
    return this.http.post<IChapter>(this.url + 'admin/v2/chapters', body, this.getOptions());
  }

  update(id: number, body): Observable<IChapter> {
    return this.http.put<IChapter>(this.url + 'admin/v2/chapters/' + id, body, this.getOptions());
  }

  delete(id: number): Observable<IChapter> {
    return this.http.delete<IChapter>(this.url + 'admin/v2/chapters/' + id, this.getOptions());
  }

  reorder(body) {
    return this.http.patch(this.url + 'admin/v2/chapters-reorder', body, this.getOptions());
  }

  warningList(page = 1, term = null, direction = 'DESC', order = null) {
    let url = this.url + 'admin/v2/chapters-warning-list?page=' + page;
    if (term) {
      url = url + '&term=' + term;
    }
    if (direction) {
      url = url + '&direction=' + direction;
    }
    if (order) {
      url = url + '&order=' + order;
    }
    return this.http.get<IChapter[]>(url, this.getOptions());
  }

  uploadCover(body) {

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post(this.url + 'admin/v2/chapter-upload-cover', body, {headers});
  }

  chaptersGenerate(body) {
    return this.http.post(this.url + 'admin/v2/chapters-generate', body, this.getOptions());
  }

  getOptions() {
    return {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.access_token.value),
    };
  }
}
