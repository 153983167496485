import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';
import {IProduct} from '../interfaces/IProduct';
import {ICoupon} from '../interfaces/ICoupon';
import {ICarousel} from '../interfaces/ICarousel';
import {IShopCarousel} from '../interfaces/IShopCarousel';
import {IDataPaginate} from '../interfaces/IDataPaginate';
import {IOrder} from '../interfaces/IOrder';
import {IDataPaginateShop} from '../interfaces/IDataPaginateShop';
import {IShopCarouselProduct} from '../interfaces/IShopCarouselProduct';


@Injectable({
  providedIn: 'root'
})
export class ShopService {

  url: string;
  taxData: any = [];

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.url = environment.apiUrl;
  }

  productsFeatured() {
    return this.http.get(this.url + 'shop/productsFeatured', this.getOptions());
  }

  productsInEvidence(): Observable<IProduct[]> {
    return this.http.get<IProduct[]>(this.url + 'shop/productsInEvidence', this.getOptions());
  }

  productsPreorder(): Observable<IProduct[]> {
    return this.http.get<IProduct[]>(this.url + 'shop/productsPreorder', this.getOptions());
  }

  productsAvailable() {
    return this.http.get(this.url + 'shop/productsAvailable', this.getOptions());
  }

  productsLast(): Observable<IProduct[]> {
    return this.http.get<IProduct[]>(this.url + 'shop/productsLast', this.getOptions());
  }

  productsTrending(): Observable<IProduct[]> {
    return this.http.get<IProduct[]>(this.url + 'shop/trending', this.getOptions());
  }

  product(slug: string): Observable<IProduct> {
    return this.http.get<IProduct>(this.url + 'web/products/' + slug);
  }

  favorite() {
    return this.http.get(this.url + 'shop/favorite', this.getOptions());
  }

  blasters() {
    return this.http.get(this.url + 'shop/blasters', this.getOptions());
  }

  productsMerchandising(): Observable<IProduct[]> {
    return this.http.get<IProduct[]>(this.url + 'shop/merchandising', this.getOptions());
  }

  productsTopWeek(): Observable<IProduct[]> {
    return this.http.get<IProduct[]>(this.url + 'shop/topWeek', this.getOptions());
  }

  shippingFiscalData() {
    return this.http.get(this.url + 'shop/shippingFiscalData', this.getOptions());
  }

  saveCustomerAddress(params: any) {
    return this.http.post(this.url + 'shop/saveCustomerAddress', params, this.getOptions());
  }

  productsCart(params: any) {
    return this.http.post(this.url + 'shop/productsCart', params, this.getOptions());
  }

  getOrdersList(): Observable<IOrder[]> {
    return this.http.get<IOrder[]>(this.url + 'shop/orders', this.getOptions());
  }

  getSingleOrder(id: string) {
    return this.http.get(this.url + 'shop/order/' + id, this.getOptions());
  }

  createOrder(params: any) {
    return this.http.post(this.url + 'shop/order', params, this.getOptions());
  }

  getPaymentIntent(params) {
    return this.http.post(this.url + 'shop/intent', params, this.getOptions());
  }

  confirmOrder(params: any) {
    return this.http.post(this.url + 'shop/confirmOrder', params, this.getOptions());
  }

  cancelOrder(orderId: any) {
    return this.http.post(this.url + 'shop/cancelOrder', {orderId}, this.getOptions());
  }

  createPrintRequest(id: number) {
    return this.http.get(this.url + 'shop/addPrintRequest/' + id, this.getOptions());
  }

  getPrintRequest(id: number) {
    return this.http.get(this.url + 'shop/getPrintRequest/' + id, this.getOptions());
  }

  getShippingTaxes() {
    return this.http.get(this.url + 'shop/shippingTaxes', this.getOptions(),);
  }

  taxes() {
    return this.http.get(this.url + 'shop/taxes', this.getOptions(),);
  }

  taxesFree() {
    return this.http.get(this.url + 'web/taxFree');
  }

  taxPreview() {
    return this.http.get(this.url + 'web/taxPreview');
  }

  search(params: any) {
    return this.http.post(this.url + 'shop/search', params, this.getOptions());
  }

  searchByTag(params: any): Observable<IProduct[]> {
    return this.http.post<IProduct[]>(this.url + 'shop/searchByTag', params, this.getOptions());
  }

  carouselByHook(hookId: string): Observable<IShopCarouselProduct[]> {
    return this.http.get<IShopCarouselProduct[]>(this.url + 'shop/carouselByHook/' + hookId, this.getOptions());
  }

  carousel(api: string): Observable<IDataPaginateShop> {
    return this.http.get<IDataPaginateShop>(this.url + 'shop/carousel/' + api, this.getOptions());
  }

  coupon(body) {
    return this.http.post(this.url + 'shop/coupon', body, this.getOptions());
  }

  soon(): Observable<IProduct[]>{
    return this.http.get<IProduct[]>(this.url + 'v2/shop/soon', this.getOptions());
  }

  getOptions() {
    return {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.access_token.value),
    };
  }

}
