<ng-container>
  <h2 *ngIf="title" class="bolder text-20 mb-10 no-select" [ngClass]="[noSpacing ? '' : 'ml-60']">{{title}}&nbsp;</h2>
  <swiper [navigation]="true"
          [pagination]="false"
          [mousewheel]="false"
          [keyboard]="false"
          [initialSlide]="0"
          [slidesPerGroup]="3"
          [slidesPerView]="'auto'"
          [spaceBetween]="12">
    <ng-template swiperSlide class="spacer-slider" *ngIf="!noSpacing"></ng-template>
    <ng-template swiperSlide *ngFor="let product of values;let i = index;" class="slide no-select">

      <a class="no-decoration" [routerLink]="'/shop/product/' + product.slug">
        <div class="shop-card flex-column justify-content-start g-8 d-inline-flex"
             [ngClass]="[(product | isMerchandising) ? 'large' : '']">
          <div class="radius4 overflow-hidden position-relative w-100" style="height: 187px;">
            <div class="product-image-box justify-content-center align-items-center d-inline-flex">
              <img alt="" (load)="isImgLoaded = true"
                   [ngClass]="[(product | isMerchandising) ? 'large' : '']"
                   [src]="isImgLoaded ? ui.productCoverUrl(product) : 'assets/images/sfondo.png'"/>
            </div>

            <img alt="badge" *ngIf="(product | isOriginal) && !(product | isPreorder)"
                 class="product-original-icon" src="assets/images/logo-compact.svg"/>

            <div class="featured-label g-10 yellow d-inline-flex p-2 align-items-center w-100 flex-column"
                 *ngIf="product && product.variations.length && !(product | isMerchandising)">
              <div class="align-self-stretch text-center text-12 fw-400 text-break">
                <span class="black-text" *ngIf="product.variations.length > 2">
                                    {{(product.variations.length - 1)}}
                  variant {{(product.variations.length - 1) > 1 ? 'disponibili' : 'disponibile'}}
                                </span>
                <span class="black-text" *ngIf="product.variations.length < 3">Variant disponibile</span>
              </div>
            </div>
          </div>
          <div class="text-left flex-column justify-content-start d-flex w-100" style="align-items: flex-start;">
            <div class="product-title fw-400 text-break overflow-hidden lightgrey-text w-100">{{product.name}}</div>
            <div class="fw-600 text-break lightgrey-text w-100">{{product.price | number : '1.2-2'}}€</div>
          </div>
        </div>
      </a>

    </ng-template>
    <ng-template swiperSlide class="spacer-slider"></ng-template>
    <ng-template swiperSlide class="spacer-slider"></ng-template>
  </swiper>
</ng-container>


<!--

<div class="comic-card text-14"
           [ngClass]="i === 0 ? 'position-left' : (i === values.length - 1 ? 'position-right' : '')">
        <div class="row">
          <div class="col-12 small">

            <div class="badge-wrapper text-center" *ngIf="(product | isOriginal) && !(product | isPreorder)">
              <img class="badge-icon" src="assets/images/badge-yellow.svg" alt="badge"/>
            </div>

            <div class="no-stock" *ngIf="shop && product && (!product.variations.length && product.stock_quantity < 1)">
              <app-chip label="Esaurito" state="danger"></app-chip>
            </div>

            <div class="variant-marker yellow"
                 *ngIf="product && product.variations.length && !(product | isMerchandising)">
              <p class="black-text pt-4 pt-2 text-center text-14">Variant disponibile</p>
            </div>

            <a class="no-decoration white-text" href="javascript:void(0);"
               [routerLink]="'/shop/product/' + product.slug">
              <img class="comic-card-img" alt=""
                   (load)="isImgLoaded = true"
                   [src]="isImgLoaded ? ui.productCoverUrl(product) : 'assets/images/sfondo.png'">
            </a>
            <p class="comic-card-title text-truncate text-15 fw-300 mb-0 pt-0">{{product.name}}</p>
            <h3 class="mt-2 mb-0 text-20">{{product.price}}€</h3>
          </div>
      </div>
</div>


-->
