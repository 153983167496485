import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';
import {IProduct} from '../interfaces/IProduct';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  url: string;

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.url = environment.apiUrl;
  }

  products(page = 1) {
    return this.http.get(this.url + 'products?page=' + page, this.getOptions());
  }

  product(slug) : Observable<IProduct>{
    return this.http.get<IProduct>(this.url + 'products/' + slug);
  }

  search(term: string = '') {
    return this.http.post(this.url + 'search', {term}, this.getOptions());
  }

  ot(direct){
    return this.http.get(this.url + 'ot?direct=' + direct);
  }

  bannerInfo() {
    return this.http.get(this.url + 'bannerInfo');
  }

  matureInfo() {
    return this.http.get(this.url + 'matureInfo');
  }

  sliders() {
    return this.http.get(this.url + 'sliders');
  }

  slidersMobile() {
    return this.http.get(this.url + 'slidersMobile');
  }

  getOptions() {
    return {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.access_token.value),
    };
  }
}
