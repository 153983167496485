import * as fromFilters from '../actions/filters.actions';
import * as _ from 'lodash';
import {IFilters} from '../interfaces/IFilters';

export interface FiltersState {
  filters: IFilters;
  filtersShop: IFilters;
}

export const initialState: FiltersState = {
  filters: {
    webtoon: undefined,
    orderBy: undefined,
    originals: undefined,
    complete: undefined,
    drawstyles: [],
    genres: []
  },
  filtersShop: {
    webtoon: undefined,
    orderBy: undefined,
    originals: undefined,
    drawstyles: [],
    genres: []
  }
};

export function reducer(
  state = initialState,
  action: fromFilters.ActionsUnion
): FiltersState {
  switch (action.type) {

    case fromFilters.ActionTypes.UpdateFilters: {
      let tempData = _.cloneDeep(state.filters);
      tempData = action.payload.item;
      return {...state, filters: tempData};
    }

    case fromFilters.ActionTypes.UpdateFiltersShop: {
      let tempData = _.cloneDeep(state.filters);
      tempData = action.payload.item;
      return {...state, filtersShop: tempData};
    }

    case fromFilters.ActionTypes.InitializeFilters: {
      return {
        ...state,
        filters: initialState.filters
      };
    }

    case fromFilters.ActionTypes.InitializeFiltersShop: {
      return {
        ...state,
        filtersShop: initialState.filtersShop
      };
    }

    default: {
      return state;
    }
  }
}

export const getFiltersStatus = (state: FiltersState) => state.filters;
export const getFiltersShopStatus = (state: FiltersState) => state.filtersShop;
