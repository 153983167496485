import {Injectable, NgZone} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {IUser} from '../interfaces/IUser';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {FacebookAuthProvider} from 'firebase/auth';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {OAuthProvider} from 'firebase/auth';
import {IAuthor} from '../interfaces/IAuthor';
import {Router} from '@angular/router';
import {CartService} from './cart.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  isSubscribed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  subscriptionJustDone: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  redirectSubjectUrl: BehaviorSubject<string> = new BehaviorSubject<string>('/');
  access_token: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  refresh_token: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  user: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(null);

  pendingRequestCount = 0;
  url: string;

  STORAGE_TOKEN_KEY = 'jjwt';
  STORAGE_TOKEN_REFRESH_KEY = 'jjwtrefresh';
  STORAGE_EMAIL_KEY = 'jemail';

  constructor(
    private http: HttpClient,
    public firebaseAuth: AngularFireAuth,
    private router: Router,
    private cartService: CartService
  ) {
    this.url = environment.apiUrl;
    this.loadToken();
  }

  refreshToken() {

    if (!this.refresh_token.value) {
      localStorage.removeItem(this.STORAGE_TOKEN_KEY);
      localStorage.removeItem(this.STORAGE_TOKEN_REFRESH_KEY);

      this.access_token.next(null);
      this.refresh_token.next(null);
      this.user.next(null);

      this.logout();
      return;

    }

    const headers = {headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.refresh_token.value)};
    return this.http.get<any>(`${this.url}refresh`, headers)
      .pipe(
        tap((data) => {
          localStorage.setItem(this.STORAGE_TOKEN_KEY, data.access_token);
          localStorage.setItem(this.STORAGE_TOKEN_REFRESH_KEY, data.refresh_token);
          this.access_token.next(data.access_token);
          this.refresh_token.next(data.refresh_token);
        }),
        catchError(() => {

          localStorage.removeItem(this.STORAGE_TOKEN_KEY);
          localStorage.removeItem(this.STORAGE_TOKEN_REFRESH_KEY);

          this.access_token.next(null);
          this.refresh_token.next(null);

          this.router.navigate(['/']);
          return of(false);
        })
      );
  }

  getJwtToken() {
    return localStorage.getItem(this.STORAGE_TOKEN_KEY);
  }

  getRefreshToken() {
    return localStorage.getItem(this.STORAGE_TOKEN_REFRESH_KEY);
  }

  loadToken() {
    const access_token = localStorage.getItem(this.STORAGE_TOKEN_KEY);
    const refresh_token = localStorage.getItem(this.STORAGE_TOKEN_REFRESH_KEY);
    this.access_token.next(access_token);
    this.refresh_token.next(refresh_token);
    this.isAuthenticated.next(!!access_token);
  }

  loadAccessData = (data: any) => {
    localStorage.setItem(this.STORAGE_TOKEN_KEY, data.access_token);
    localStorage.setItem(this.STORAGE_TOKEN_REFRESH_KEY, data.refresh_token);

    if (data.user && data.user?.email) {
      localStorage.setItem(this.STORAGE_EMAIL_KEY, data.user.email);
    }

    this.access_token.next(data.access_token);
    this.refresh_token.next(data.refresh_token);

    this.isAuthenticated.next(true);
    this.isSubscribed.next(!!data.user.jundo_user.subscription);
    this.user.next(data.user);

    return data;
  };

  signin(body) {
    return this.http.post(this.url + 'v2/signin', body).pipe(map(this.loadAccessData));
  }

  signup(body) {

    const str = JSON.stringify(body.email + ':' + body.password);
    const authString = btoa(str);
    const headers = {
      headers: new HttpHeaders().set('Authorization', 'Basic ' + authString),
    };

    return this.http.post(this.url + 'v2/signup', body, headers).pipe(map(this.loadAccessData));
  }

  logout() {

    this.router.navigate(['/home']).then();

    this.http.get(this.url + 'logout', this.getOptions()).subscribe();

    setTimeout(() => {

      this.user.next(null);
      this.isAuthenticated.next(false);
      this.isSubscribed.next(false);
      this.redirectSubjectUrl.next('/');

      this.cartService.resetCart();

      this.access_token.next(null);
      this.refresh_token.next(null);

      localStorage.removeItem(this.STORAGE_TOKEN_KEY);
      localStorage.removeItem(this.STORAGE_TOKEN_REFRESH_KEY);
    }, 0);
  }

  recovery(body) {
    return this.http.post(this.url + 'recovery', body);
  }

  reset(body) {
    return this.http.post(this.url + 'reset', body);
  }

  profile(): Observable<IUser> {
    return this.http.get<IUser>(this.url + 'v2/user', this.getOptions()).pipe(
      tap(user => {
        this.isAuthenticated.next(true);
        this.isSubscribed.next(!!user.jundo_user.subscription || !!user.subscription);
        this.user.next(user);
      })
    );
  }

  author(id): Observable<IAuthor> {
    return this.http.get<IAuthor>(this.url + 'web/author/' + id, this.getOptions());
  }

  updateUser(user): Observable<IUser> {
    return this.http.put<IUser>(this.url + 'user', user, this.getOptions()).pipe(
      map((data: IUser) => {
        this.user.next(data);
        return data;
      })
    );
  }

  updateAvatar(body) {
    return this.http.post(this.url + 'web/avatar', body, this.getOptions());
  }

  editPassword(passObj) {
    return this.http.put(this.url + 'resetPassword', passObj, this.getOptions());
  }

  profileNewsletterData() {
    return this.http.get(this.url + 'profileNewsletterData', this.getOptions());
  }

  pendingDeleteAccount() {
    return this.http.get(this.url + 'pendingDeleteAccount', this.getOptions());
  }

  unsubscribeNewsletter() {
    return this.http.get(this.url + 'unsubscribeNewsletter', this.getOptions());
  }

  subscribeNewsletter() {
    return this.http.get(this.url + 'subscribeNewsletter', this.getOptions());
  }

  requestDelete(body) {
    return this.http.post(this.url + 'requestDelete', body, this.getOptions());
  }

  cancelDelete() {
    return this.http.get(this.url + 'cancelDelete', this.getOptions());
  }

  promotionRevoke() {
    return this.http.get(this.url + 'v2/promotionRevoke', this.getOptions());
  }

  checkPromotionRevoke() {
    return this.http.get(this.url + 'v2/checkPromotionRevoke', this.getOptions());
  }

  createAccess() {
    this.http.post(this.url + 'access', {platform: 'web'}, this.getOptions()).subscribe();
  }

  facebookServerLogin(body): Observable<any> {
    return this.http.post(this.url + 'web/auth/facebook', body).pipe(map(this.loadAccessData));
  }

  appleServerLogin(body): Observable<any> {
    return this.http.post(this.url + 'web/auth/apple', body).pipe(map(this.loadAccessData));
  }

  async facebookSignIn() {
    const signInResult = await this.firebaseAuth.signInWithPopup(new FacebookAuthProvider());
    if (signInResult) {

      const body = {
        email: signInResult.additionalUserInfo.profile['email'],
        access_token: signInResult.credential['accessToken']
      };

      const response = await this.facebookServerLogin(body).toPromise();
      if (response) {
        return response;
      }
    }

    return null;
  }

  async appleSignIn() {

    const provider = new OAuthProvider('apple.com');
    const signInResult = await this.firebaseAuth.signInWithPopup(provider);

    if (signInResult) {

      const body = {
        user: signInResult.additionalUserInfo.profile['sub'],
      };

      const response = await this.appleServerLogin(body).toPromise();
      if (response) {
        return response;
      }
    }

    return null;
  }

  getOptions() {
    return {headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.access_token.value)};
  }
}
