import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import {AuthService} from '../services/auth.service';
import {Observable, throwError, BehaviorSubject} from 'rxjs';
import {catchError, filter, take, switchMap} from 'rxjs/operators';
import {UiService} from '../services/ui.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private auth: AuthService,
    private ui: UiService
  ) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.auth.getJwtToken() && this.ui.isBrowser()) {

      let token = this.auth.getJwtToken();

      // const expiration_unix = jwt_decode(token).exp;
      // const diff = moment.unix(expiration_unix).diff(moment(), 'minutes');
      // console.log(diff < 0 ? 'token scaduto' : 'token valido', diff + ' minutes');

      if (request.url.includes('api/refresh')) {
        token = this.auth.getRefreshToken();
      }

      request = this.appendHeader(request, token);
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401 && this.ui.isBrowser()) {
          if (request.url && request.url.includes('api/refresh')) {
            this.auth.logout();
            return throwError(null);
          } else {
            return this.handle401Error(request, next);
          }
        } else {
          return throwError(error);
        }
      })
    );
  }

  private appendHeader(request: HttpRequest<any>, access_token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.auth.refreshToken()
        .pipe(
          switchMap((data: any) => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(data.access_token);
            return next.handle(this.appendHeader(request, data.access_token));
          })
        );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.appendHeader(request, jwt));
        })
      );
    }
  }
}


export const tokenInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor,
  multi: true
};
