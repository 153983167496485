import {
    ActionReducerMap,
    createSelector,
    MetaReducer
} from '@ngrx/store';
import {environment} from '../../environments/environment';

import * as fromCart from './cart.reducers';
import * as fromFilters from './filters.reducers';

export interface AppState {
    cart: fromCart.CartState;
    filters: fromFilters.FiltersState
    filtersShop: fromFilters.FiltersState
}

export const reducers: ActionReducerMap<AppState> = {
    cart: fromCart.reducer,
    filters: fromFilters.reducer,
    filtersShop: fromFilters.reducer
};


export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];

export const getCartState = (state: AppState) => state.cart;
export const getFiltersState = (state: AppState) => state.filters;
export const getFiltersStateShop = (state: AppState) => state.filtersShop;

export const getAllItems = createSelector(
    getCartState,
    fromCart.getItems
);

export const getItemById = createSelector(
    getCartState,
    fromCart.getItemById
);

export const getItemsQuantity = createSelector(
    getCartState,
    fromCart.getItemsQuantity
);

export const getItemsAmount = createSelector(
    getCartState,
    fromCart.getItemsAmount
);

export const getFilters = createSelector(
    getFiltersState,
    fromFilters.getFiltersStatus
);

export const getFiltersShop = createSelector(
    getFiltersState,
    fromFilters.getFiltersShopStatus
);
