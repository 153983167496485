import {Injectable} from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {UiService} from '../services/ui.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private ui: UiService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {

        if (error) {
          switch (error.status) {
            case 400:
              const errorKey = Object.keys(error.error)[0];
              if (errorKey) {
                this.ui.error(error.error[errorKey]);
              } else {
                this.ui.error(error.error);
              }
              break;
            default:
              break;
          }
        }

        return throwError(error);
      }));
  }
}

export const errorInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true
};
