import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PipesModule} from './pipes/pipes.module';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {reducers, metaReducers} from './reducers';
import {DirectivesModule} from './directives/directives.module';
import {SwiperModule} from 'swiper/angular';
import {ComponentsModule} from './components/components.module';
import {ToastrModule} from 'ngx-toastr';
import {MatSidenavModule} from '@angular/material/sidenav';
import {NgxStripeModule} from 'ngx-stripe';
import {environment} from '../environments/environment';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {NgxSpinnerModule} from 'ngx-spinner';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';

import {registerLocaleData} from '@angular/common';
import {TransferHttpCacheModule} from '@nguniversal/common';
import localeIt from '@angular/common/locales/it';
import {NgxPayPalModule} from 'ngx-paypal';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MAT_RIPPLE_GLOBAL_OPTIONS, MatNativeDateModule, RippleGlobalOptions} from '@angular/material/core';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFireAnalyticsModule} from '@angular/fire/compat/analytics';
import {PlatformModule} from '@angular/cdk/platform';

import {NgsgModule} from 'ng-sortgrid';
import {QuillModule} from 'ngx-quill';

import {ImageCropperModule} from 'ngx-image-cropper';
import {LightboxModule} from 'ngx-lightbox';
import {MAT_COLOR_FORMATS, NGX_MAT_COLOR_FORMATS, NgxMatColorPickerModule} from '@angular-material-components/color-picker';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {tokenInterceptor} from './interceptors/token.interceptors';
import {requestInterceptor} from './interceptors/request.interceptor';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {errorInterceptor} from './interceptors/error.interceptor';


const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 0
  }
};

registerLocaleData(localeIt, 'it-IT');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    PlatformModule,
    PipesModule,
    DirectivesModule,
    BrowserAnimationsModule,
    ComponentsModule,
    ToastrModule.forRoot(),
    MatSidenavModule,
    NgxStripeModule.forRoot(environment.stripeKey),
    MatProgressBarModule,
    NgxSkeletonLoaderModule,
    NgxSpinnerModule,
    StoreModule.forRoot(reducers, {metaReducers}),
    StoreDevtoolsModule.instrument({maxAge: 20}),
    EffectsModule.forRoot(),
    InfiniteScrollModule,
    NgxPayPalModule,
    SwiperModule,
    NgbModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    NgsgModule,
    QuillModule.forRoot(),
    ImageCropperModule,
    TransferHttpCacheModule,
    LightboxModule,
    NgxMatColorPickerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatCheckboxModule
  ],
  providers: [
    requestInterceptor,
    tokenInterceptor,
    errorInterceptor,

    {provide: LOCALE_ID, useValue: 'it-IT'},
    {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig},
    {provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
