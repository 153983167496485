import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IProduct} from '../../interfaces/IProduct';
import {IVariation} from '../../interfaces/IVariation';
import {AuthService} from '../../services/auth.service';
import {CategoryPipe} from '../../pipes/category.pipe';
import {IsMerchandisingPipe} from '../../pipes/is-merchandising.pipe';
import * as _ from 'lodash';

export interface DialogData {
  product: IProduct;
}

@Component({
  selector: 'app-dialog-bundle',
  templateUrl: './dialog-bundle.component.html',
  styleUrls: ['./dialog-bundle.component.scss'],
  providers: [CategoryPipe, IsMerchandisingPipe]
})
export class DialogBundleComponent {

  product: IProduct;

  productVariations: IProduct[] = [];

  variants: IVariation[] = [];

  enableForm: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<DialogBundleComponent>,
    public auth: AuthService
  ) {
    if (data && data.product) {
      this.product = data.product;
      this.productVariations = this.product.bundled_products.filter(product => product.variations.length > 0);
    }
  }

  confirm() {

    if (!this.enableForm) {
      return;
    }

    this.dialogRef.close({variants: this.variants});
  }

  close() {
    this.dialogRef.close();
  }

  checkChoose() {
    let variantCount = 0;
    this.variants.forEach(variant => {
      if (!_.isEmpty(variant)) {
        variantCount++;
      }
    });
    this.enableForm = (variantCount === this.productVariations.length);
  }
}
