import {Pipe, PipeTransform} from '@angular/core';
import {PaymentMethod} from '@stripe/stripe-js';

@Pipe({
    name: 'pmMessage'
})
export class PmMessagePipe implements PipeTransform {

    transform(pm: PaymentMethod, args?: any[] | undefined): string {
        return pm && pm.card && pm.card.last4 ? '•••• •••• •••• ' + pm.card.last4 : null;
    }

}
