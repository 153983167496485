<!--<h1 mat-dialog-title>&nbsp;</h1>-->
<div mat-dialog-content>

  <div *ngIf="data && data.comic" class="like-box">
    <div class="like-box-main mt-20 mb-20">
      <div class="row text-center">
        <div class="col-4">
          <img class="close-icon mt-6 pointer" (click)="close()" alt="close like box"
               src="assets/icon/jundo/close-box.svg"/>
        </div>
        <div class="col-4">
          <img class="like-icon pointer" (click)="like()" alt="like"
               [src]="isLike ? 'assets/icon/jundo/like-box-active.svg' : 'assets/icon/jundo/like-box.svg'"/>
        </div>
        <div class="col-4">
          <img class="like-icon pointer" (click)="dislike()" alt="dislike"
               [src]="isDislike ? 'assets/icon/jundo/dislike-box-active.svg' : 'assets/icon/jundo/dislike-box.svg'"/>
        </div>
      </div>
    </div>
  </div>

</div>
<!--<mat-dialog-actions align="end">
  <button class="btn btn-jundo mr-10" [mat-dialog-close]="true" cdkFocusInitial>Chiudi</button>
</mat-dialog-actions>-->
