
<form novalidate [formGroup]="form">
  <div class="row mt-20">
    <div *ngFor="let field of fieldsShipping" class="{{field.col}}">
      <app-input *ngIf="field.type !== 'select'"
                 [model]="form.controls[field.name]"
                 [label]="field.label"></app-input>

      <mat-form-field *ngIf="field.type === 'select'" appearance="outline">
        <mat-label>Stato</mat-label>
        <select matNativeControl [formControl]="form.controls[field.name]" [value]="field.value">
          <option *ngFor="let country of countries;" [value]="country.code">{{country.name}}</option>
        </select>
        <mat-error *ngIf="form.controls[field.name].hasError('required')">Campo obbligatorio</mat-error>
        <mat-error
          *ngIf="form.controls[field.name].hasError('pattern') && !form.controls[field.name].hasError('required')">
          Campo non valido
        </mat-error>
      </mat-form-field>

    </div>
    <div class="col-12">
      <mat-checkbox (click)="setEqualFiscalValidators()" [formControl]="form.controls['equal_fiscal']">
        Indirizzo fatturazione uguale alla spedizione
      </mat-checkbox>
    </div>
  </div>
  <div class="row mt-20" *ngIf="!form.controls['equal_fiscal'].value">
    <div class="col-12">
      <h3 class="fw-700 grey-text text-18">Indirizzo di fatturazione</h3>
    </div>
    <div *ngFor="let field of fieldsFiscal" class="{{field.col}}">
      <app-input *ngIf="field.type !== 'select'" [value]="field.value" [model]="form.controls[field.name]"
                 [label]="field.label"></app-input>

      <mat-form-field *ngIf="field.type === 'select'" appearance="outline">
        <mat-label>Stato</mat-label>
        <select matNativeControl [formControl]="form.controls[field.name]" [value]="field.value">
          <option *ngFor="let country of countries;" [value]="country.code">{{country.name}}</option>
        </select>
        <mat-error *ngIf="form.controls[field.name].hasError('required')">Campo obbligatorio</mat-error>
        <mat-error
          *ngIf="form.controls[field.name].hasError('pattern') && !form.controls[field.name].hasError('required')">
          Campo non valido
        </mat-error>
      </mat-form-field>

    </div>
    <div class="col-12">
      <mat-checkbox [formControl]="form.controls['fiscal_reminder']">
        Salva indirizzo di fatturazione
      </mat-checkbox>
    </div>
  </div>
</form>
