import {Pipe, PipeTransform} from '@angular/core';
import {GlobalService} from '../services/global.service';
import {CartService} from '../services/cart.service';
import * as _ from 'lodash';
import {CategoryNameConstant} from '../constants/category-name.constant';
import {CoverTypeConstant} from '../constants/cover-type.constant';

@Pipe({
  name: 'isSubscriberCart'
})
export class IsSubscriberCartPipe implements PipeTransform {

  private categoryNameConstant = CategoryNameConstant;
  private coverTypeConstants = CoverTypeConstant;

  constructor(
    private globalService: GlobalService,
    private cartService: CartService
  ) {
  }

  transform(data?: any, args?: any): boolean {

    let isSubscriber = false;

    this.cartService.cart.subscribe(value => {
      value.forEach((item) => {

        const isVariantRegular = item.product.variations.find(variation =>
          variation.id === item.variantId && variation &&
          variation.attributes.length &&
          variation.attributes[0].option &&
          variation.attributes[0].option === this.coverTypeConstants.CLASSIC
        );

        const isMerch = _.find(item.product.categories, item => item.name === this.categoryNameConstant.MERCHANDISING);
        const isSpecial = _.find(item.product.attributes, variation => _.toLower(variation.name) === 'subscription');
        const isBundle = item.product.type === 'bundle';
        const isSealUp = this.globalService.seal_up;
        const isVariant = item.variantId && !isMerch && !isVariantRegular;

        if (isSpecial || isBundle || isSealUp || isVariant) {
          isSubscriber = true;
        }
      });
    });

    return isSubscriber;
  }

}
