import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Observable} from "rxjs";
import {IComic} from '../interfaces/IComic';
import {ItemService} from '../services/items.service';

@Injectable({
  providedIn: 'root'
})
export class SitemapResolver implements Resolve<IComic[]> {

  constructor(private itemsService: ItemService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<IComic[]> {
    return this.itemsService.comics();
  }
}
