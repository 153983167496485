import {Component} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {IUser} from '../../interfaces/IUser';
import {AuthService} from '../../services/auth.service';
import {UiService} from '../../services/ui.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-dialog-cancellation',
  templateUrl: './dialog-cancellation.component.html',
  styleUrls: ['./dialog-cancellation.component.scss'],
})
export class DialogCancellationComponent {

  form: FormGroup;
  loader: boolean;
  user: IUser;

  cancellationOptions = [
    {value: 'troppo_costoso', label: 'Troppo costoso'},
    {value: 'poche_opere_interessanti', label: 'Poche opere interessanti'},
    {value: 'altro_account_jundo', label: 'Ho un altro account Jundo'},
    {value: 'exp_negativa', label: 'Esperienza negativa dell’app'},
    {value: 'preferisco_altro', label: 'Preferisco un’altra app'},
    {value: 'altro', label: 'Altro'}
  ];

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private ui: UiService,
    public spinner: NgxSpinnerService,
  ) {
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    const formObj: any = this.cancellationOptions.reduce((obj, item) => {
      obj[item.value] = null;
      return obj;
    }, {});
    formObj.reason = null;
    this.form = this.fb.group(formObj);
  }


  confirm() {

    if (!this.form.valid) {
      return false;
    }

    this.ui.spinnerText = 'Richiesta cancellazione in corso...';
    this.spinner.show();

    this.loader = true;
    const body = Object.assign({}, this.form.value);

    this.auth.requestDelete(body)
      .subscribe(() => {
        this.auth.logout();
        setTimeout(() => {
          this.ui.toast('Richiesta cancellazione effettuata con successo. Il tuo account sarà eliminato entro 48 ore.');
          this.spinner.hide();
        }, 4000);
      });
  }

}
