import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {IAuthor} from '../interfaces/IAuthor';
import {AuthService} from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorResolver implements Resolve<IAuthor> {

  constructor(private authService: AuthService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<IAuthor> {
    const id = route.paramMap.get('id');
    return this.authService.author(id);
  }
}
