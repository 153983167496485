<h1 mat-dialog-title class="text-center bolder">Scegli variante prodotti</h1>
<div mat-dialog-content>
  <div class="container">
    <div class="row" *ngIf="productVariations.length">
      <div class="col-12" *ngFor="let item of productVariations; let i = index;">
        <mat-form-field appearance="outline" color="accent">
          <mat-label class="white-text">
            Scegli {{(item | isMerchandising) ? 'taglia' : 'copertina'}} per {{item.name}}
          </mat-label>
          <mat-select class="black" [(ngModel)]="variants[i]" (ngModelChange)="checkChoose()">
            <mat-option class="white-text black-light " *ngFor="let variation of item.variations"
                        [value]="variation">{{variation.attributes[0]['option']}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<mat-dialog-actions align="end">
  <button class="btn btn-outline-jundo mr-20" [mat-dialog-close]="false">Annulla</button>
  <button class="btn btn-jundo"
          [disabled]="!enableForm"
          (click)="confirm()">Conferma
  </button>
</mat-dialog-actions>
