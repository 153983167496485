import {Component, Input, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {IComic} from '../../interfaces/IComic';
import {UiService} from '../../services/ui.service';
import * as _ from 'lodash';

import SwiperCore, {Keyboard, Mousewheel, Navigation, Pagination,} from 'swiper';
import {ICarousel} from '../../interfaces/ICarousel';
import {environment} from '../../../environments/environment';

SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);

@Component({
  selector: 'app-comic-slider-overlay',
  templateUrl: './comic-slider-overlay.component.html',
  styleUrls: ['./comic-slider-overlay.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ComicSliderOverlayComponent {

  @Input() carousel: ICarousel;

  @Input() api?: string;
  @Input() required: boolean | number = false;
  @Input() shop: boolean = false;
  @Input() soon: boolean | number = 0;
  @Input() marker: boolean = false;
  @Input() showPrice: boolean = false;
  @Input('big-order') bigOrder: boolean = false;
  @Input('alternative-chip') alternativeChips: boolean = false;
  @Input('from-chapter') fromChapter: any;
  @Input('first-slide') firstSlide = true;
  @Input() isNew: boolean;

  isImgLoaded: boolean = false;
  protected readonly environment = environment;

  values: IComic[] = [];

  constructor(
    public route: ActivatedRoute,
    public ui: UiService
  ) {

  }

  ngOnInit() {
    this.values = _.cloneDeep(this.carousel.comics);
  }

}
