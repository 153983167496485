<div *ngIf="carousel" [ngStyle]="carousel.hex ? {'background': (carousel.hex | carouselBackground)} : {}">

  <div class="title-box pt-20 mb-0 no-select ml-55">
    <img class="carousel-icon mr-20" *ngIf="carousel.filename" alt=""
         [src]="environment.media + carousel.original_path + carousel.filename"/>
    <span class="fw-600 text-22"
          *ngIf="(required && carousel.api) || (!required || !carousel.api)">
      <a *ngIf="required && carousel.api" class="no-decoration" [routerLink]="'/comics/' + carousel.api">
        {{carousel.title}}&nbsp;<i class="fa-solid fa-arrow-right-long pointer"></i>
      </a>
      <span *ngIf="!required || !carousel.api">{{carousel.title}}</span>
    </span>
    <p *ngIf="carousel.draft" class="grey-text text-12 m-0">draft</p>
  </div>

  <swiper
    [cssMode]="cssMode"
    [navigation]="true"
    [pagination]="false"
    [mousewheel]="false"
    [keyboard]="false"
    [initialSlide]="0"
    [slidesPerGroup]="3"
    [slidesPerView]="'auto'"
    [spaceBetween]="16"
    [freeMode]="true"
  >
    <ng-template swiperSlide class="spacer-slider" *ngIf="firstSlide"></ng-template>
    <ng-template swiperSlide *ngFor="let comic of comics;let i = index;"
                 class="slide mt-20 mb-20 no-select {{expand && hoverIdx[i] ? 'expand' : ''}}">

      <div class="comic-card text-14 {{expand && hoverIdx[i] ? 'expand' : ''}}"
           (mouseenter)="onMouseEnter(i)"
           (mouseleave)="onMouseLeave(i)"
           [ngStyle]="{'background-image': 'url(' + ui.mediaCoverUrl(comic, !(expand && hoverIdx[i])) + ')'}">
        <div class="tappable-wrap pointer" (click)="openComic(comic)"></div>
        <div class="overlay"></div>
        <div class="card-info">
          <h3 class="fw-600 text-16 mb-6 text-truncate">{{comic.title}}</h3>
          <p class="text-truncate text-12 mb-10"><span *ngFor="let chip of comic.genres; let i = index;">
              <span *ngIf="i > 0" class="yellow-text">&nbsp;&nbsp;•&nbsp;&nbsp;</span>
              <span class="white-text">{{chip.label}}</span>
          </span></p>
          <div class="row mt-0" style="max-width: 480px">
            <div class="col-9">
              <button class="btn btn-jundo small text-10 w-100"
                      (click)="openReader(comic)" style="left: 80px;">
                {{comic.progress && comic.progress.length ? 'Continua a leggere' : 'Inizia a leggere'}}
              </button>
            </div>
            <div class="col-3">
              <img alt="favorite" height="30" class="pointer" (click)="favorite(comic, i)"
                   [src]="isFavorite[i] ? 'assets/icon/jundo/bookmark-plus-active.svg' : 'assets/icon/jundo/bookmark-plus-grey.svg'">
            </div>
          </div>
          <div class="progress progress-card grey mt-10" *ngIf="comic.progress && comic.progress.length">
            <div class="progress-bar white" role="progressbar"
                 style="width: {{comic | progress}}%"
                 aria-valuenow="{{comic | progress}}"
                 aria-valuemin="{{comic | progress}}"
                 aria-valuemax="100"
                 aria-label="Progresso: {{comic | progress}}%"
            ></div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 small">
            <div class="badge-wrapper text-center" *ngIf="!comic.is_coin && comic.originals">
              <img class="badge-icon" src="assets/images/badge-yellow.svg" alt="badge"/>
            </div>
            <div class="badge-wrapper text-center" *ngIf="comic.is_coin">
              <img class="coin-icon" src="assets/icon/v2/coin.svg" alt="badge"/>
            </div>
          </div>
        </div>
        <!--<app-comic-expand [api]="carousel.api" [comic]="comic"></app-comic-expand>-->
        <div class="progress progress-card progress-preview grey" *ngIf="comic.progress && comic.progress.length">
          <div class="progress-bar yellow" role="progressbar"
               style="width: {{comic | progress}}%"
               aria-valuenow="{{comic | progress}}"
               aria-valuemin="{{comic | progress}}"
               aria-valuemax="100"
               aria-label="Progresso: {{comic | progress}}%"
          ></div>
        </div>
        <h3 class="comic-card-title text-truncate text-16 mb-0 pt-2">{{comic.title}}</h3>
      </div>
    </ng-template>
    <ng-template swiperSlide class="spacer-slider"></ng-template>
    <ng-template swiperSlide class="spacer-slider"></ng-template>
  </swiper>
</div>

