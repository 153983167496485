<div class="package-card pointer"
     (click)="selectPackage()"
     [ngClass]="[selected ? 'active' : '']">

  <div class="badge-card radius8" *ngIf="package.is_special_offer && package.special_offer_label">
    <p class="black-text text-center m-0">{{package.special_offer_label}}</p>
  </div>

  <div class="package-container">
    <div class="package-header">
      <div class="package-info">
        <div class="package-label">{{package.label}}</div>
        <div class="package-coins">
          <span class="lightyellow-text">{{package.coin_x}}</span>
          <span class="white-text" *ngIf="package.coin_y">&nbsp;+&nbsp;</span>
          <span class="green-text" *ngIf="package.coin_y">{{package.coin_y}}</span>
          <span class="lightyellow-text">&nbsp;Coins</span>
        </div>
      </div>
    </div>
    <img class="package-image"
         alt="" [src]="environment.media + package.image_url"/>
    <div class="package-footer" [ngClass]="[selected ? 'active' : '']">
      <div class="package-price" [ngClass]="[selected ? 'active' : '']">
        {{package.price}}€
      </div>
    </div>
  </div>
  <div class="special-offer-box"
       *ngIf="package.is_special_offer && package.special_offer_label">
    <div class="special-offer-label">{{package.special_offer_label}}</div>
  </div>

</div>
