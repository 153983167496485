import {Component, Input, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IProduct} from '../../interfaces/IProduct';
import {UiService} from '../../services/ui.service';
import * as _ from 'lodash';

import SwiperCore, {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
} from 'swiper';
import {AuthService} from '../../services/auth.service';
import {CartService} from '../../services/cart.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogBundleComponent} from '../dialog-bundle/dialog-bundle.component';
import {DialogAuthComponent} from '../dialog-auth/dialog-auth.component';

SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);

@Component({
  selector: 'app-bundle-slider',
  templateUrl: './bundle-slider.component.html',
  styleUrls: ['./bundle-slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BundleSliderComponent {

  @Input() data: IProduct[] = [];
  @Input() product: IProduct;
  @Input() title?: string;
  subtitle = 'Spesso comprati insieme';

  isImgLoaded: boolean = false;

  values: IProduct[] = [];
  amount = 0;
  percent = 0;

  constructor(
    public route: ActivatedRoute,
    public ui: UiService,
    public auth: AuthService,
    private cartService: CartService,
    private dialog: MatDialog,
    private router: Router
  ) {

  }

  ngOnInit() {
    if (this.data && this.product) {
      const products = this.data.filter(elem => elem.id !== this.product.id);
      this.values = _.cloneDeep(products);

      this.calculateSale();
    }
  }

  calculateSale() {
    this.data.forEach(product => {
      this.amount = this.amount + product.price;
    });

    if (this.amount !== this.product.bundle.price) {
      this.percent = ((this.amount - this.product.bundle.price) / this.amount) * 100;
      this.percent = Number(this.percent.toFixed(2));

      if (this.percent > 0) {
        this.subtitle = 'Hai uno sconto del ' + this.percent + '%';
      }
    }
  }

  addBundle() {

    const variations = [];

    if (
      this.product &&
      this.product.bundle &&
      this.product.bundle.bundled_items &&
      this.product.bundle.bundled_items.length
    ) {

      const bundled_items = this.product.bundle.bundled_items;
      bundled_items.forEach((items) => {
        variations.push(...items.allowed_variations);
      });

      if (variations.length) {
        this.variantDialog();
      } else {
        this.cartService.addShoppingItem({product: this.product.bundle, qty: 1});
        this.ui.toast(this.product.bundle.name + ' ' + 'aggiunto al carrello');
      }

    }
  }

  variantDialog() {
    const dialog = this.dialog.open(DialogBundleComponent, {
      data: {product: this.product}
    });
    dialog.afterClosed().subscribe(data => {
      if (data && data.variants) {
        this.cartService.addShoppingItem({product: this.product.bundle, variantId: null, variants: data.variants, qty: 1});
      }
    });
  }

}
