<h1 mat-dialog-title>{{title}}</h1>
<div mat-dialog-content [innerHTML]="content" class="pb-20"></div>
<section class="ion-padding-horizontal pb-20" *ngIf="isCoin">
  <mat-checkbox color="primary" class="white-text fw-400" slot="start"
                [value]="utility.inArrayPreventRequest(data.chapter.comic.id) ? 'true' : 'false'"
                (click)="utility.addInArrayPreventRequest(data.chapter.comic.id)">
    Non chiedere più per i prossimi {{data.chapter.comic.is_grouped ? 'volumi' : 'capitoli'}}
  </mat-checkbox>
</section>
<div mat-dialog-content align="end">
  <button *ngFor="let button of buttons;"
          (click)="button.action()"
          class="btn btn-jundo mr-20 {{button.class}}"
          [innerHtml]="button.label"></button>
</div>
