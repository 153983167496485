import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Observable} from "rxjs";
import {IProduct} from '../interfaces/IProduct';
import {ShopService} from '../services/shop.service';

@Injectable({
  providedIn: 'root'
})
export class ProductResolver implements Resolve<IProduct> {

  constructor(private shopService: ShopService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<IProduct> {
    const slug = route.paramMap.get('slug');
    return this.shopService.product(slug);
  }
}
