<div *ngIf="product && product.bundle">

  <div class="row">
    <div class="col-6">
      <h2 class="fw-700 text-22 mb-0 text-truncate no-select" *ngIf="title">{{title}}&nbsp;</h2>
      <p class="mb-5 text-truncate" *ngIf="subtitle">{{subtitle}}&nbsp;</p>
    </div>
    <div class="col-6 text-right">
      <button class="btn btn-outline-jundo" (click)="addBundle()">
        <img class="position-relative p-bottom-4 p-right-4" src="assets/icon/v2/add-shop-white.svg" alt="">
        <span class="white-text">{{product.bundle.price | number : '1.2-2'}}€</span>
      </button>
    </div>
  </div>
  <swiper
    [navigation]="true"
    [pagination]="false"
    [mousewheel]="false"
    [keyboard]="false"
    [initialSlide]="0"
    [slidesPerGroup]="3"
    [slidesPerView]="'auto'"
    [spaceBetween]="20"
  >
    <ng-template swiperSlide class="slide mt-20 mb-20 no-select {{(product | isMerchandising) ? 'merch' : ''}}">
      <div class="comic-card text-14 position-left">
        <div class="row">
          <div class="col-12 small">

            <div class="badge-wrapper text-center" *ngIf="(product | isOriginal) && !(product | isPreorder)">
              <img class="badge-icon" src="assets/images/badge-yellow.svg" alt="badge"/>
            </div>

            <div class="no-stock" *ngIf="product && (!product.variations.length && product.stock_quantity < 1)">
              <app-chip label="Esaurito" state="danger"></app-chip>
            </div>

            <div class="variant-marker yellow"
                 *ngIf="product && product.variations.length && !(product | isMerchandising)">
              <p class="black-text pt-4 pt-2 text-center text-14">Variant disponibile</p>
            </div>

            <a class="no-decoration white-text" href="javascript:void(0);">
              <img class="comic-card-img" alt=""
                   (load)="isImgLoaded = true"
                   [src]="isImgLoaded ? ui.productCoverUrl(product) : 'assets/images/sfondo.png'">
            </a>
            <h3 class="mt-2 mb-0 text-20">{{product.price}}€</h3>
            <p class="comic-card-title text-truncate text-15 fw-300 mb-0 pt-0">{{product.name}}</p>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template swiperSlide class="plus-box no-select text-center">
      <img src="assets/icon/v2/bundle-plus.svg" alt=""/>
    </ng-template>

    <ng-template swiperSlide
                 *ngFor="let item of values;let i = index;"
                 class="slide mt-20 mb-20 no-select {{(item | isMerchandising) ? 'merch' : ''}}">
      <div class="comic-card text-14"
           [ngClass]="[i === 0 ? 'position-left' : (i === values.length - 1 ? 'position-right' : ''), (item | isMerchandising) ? 'merch' : '']">
        <div class="row">
          <div class="col-12 small">

            <div class="badge-wrapper text-center" *ngIf="(item | isOriginal) && !(item | isPreorder)">
              <img class="badge-icon" src="assets/images/badge-yellow.svg" alt="badge"/>
            </div>

            <div class="no-stock" *ngIf="item && (!item.variations.length && item.stock_quantity < 1)">
              <app-chip label="Esaurito" state="danger"></app-chip>
            </div>

            <div class="variant-marker yellow"
                 *ngIf="item && item.variations.length && !(item | isMerchandising)">
              <p class="black-text pt-4 pt-2 text-center text-14">Variant disponibile</p>
            </div>

            <!--<div class="sub-title">
              <app-chip [label]="product.name | productTitleTag" state="dark round"></app-chip>
            </div>-->

            <a class="no-decoration white-text" href="javascript:void(0);"
               [routerLink]="'/shop/product/' + item.slug">
              <img class="comic-card-img" alt=""
                   (load)="isImgLoaded = true"
                   [src]="isImgLoaded ? ui.productCoverUrl(item) : 'assets/images/sfondo.png'">
            </a>
            <h3 class="mt-2 mb-0 text-20">{{item.price}}€</h3>
            <p class="comic-card-title text-truncate text-15 fw-300 mb-0 pt-0">{{item.name}}</p>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template swiperSlide class="spacer-slider"></ng-template>
  </swiper>
</div>
