import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';
import {IProduct} from '../interfaces/IProduct';
import {CategoryNameConstant} from '../constants/category-name.constant';

@Pipe({
  name: 'isMerchandising'
})
export class IsMerchandisingPipe implements PipeTransform {

  categoryNameConstant = CategoryNameConstant;

  transform(value: IProduct, args?: any): any {
    return !!(_.find(value.categories, item => item.name === this.categoryNameConstant.MERCHANDISING));
  }
}
