import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState, getAllItems, getItemById, getItemsQuantity, getItemsAmount} from '../reducers';
import * as CartActions from '../actions/cart.actions';
import {ICart} from '../interfaces/ICart';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  public cart: Observable<ICart[]>;

  constructor(
    private store: Store<AppState>
  ) {
    this.cart = this.store.select(getAllItems);
  }

  getShoppingItem(id: number, variantId = 0): Observable<ICart> {
    return this.store.select(getItemById, {id, variantId});
  }

  getShoppingItemQuantity(): Observable<number> {
    return this.store.select(getItemsQuantity);
  }

  getShoppingItemAmount(): Observable<number> {
    return this.store.select(getItemsAmount);
  }

  getShoppingItemAmountPromise() {
    return this.store.select(getItemsAmount).toPromise();
  }

  addShoppingItem(item: ICart): void {
    this.store.dispatch(new CartActions.AddShoppingItem({item}));
  }

  deleteShoppingItem(item: ICart): void {
    this.store.dispatch(new CartActions.DeleteShoppingItem({item}));
  }

  initializeCart(): void {
    this.store.dispatch(new CartActions.InitializeCart({}));
  }

  resetCart(): void {
    this.store.dispatch(new CartActions.ResetCart({}));
  }


}
