import {Component, Input} from '@angular/core';
import {ICarousel} from '../../interfaces/ICarousel';
import {ItemService} from '../../services/items.service';
import {ChannelsService} from '../../services/channels.service';
import {CarouselTypeConstant} from '../../constants/carousel-type.constant';
import {CarouselsService} from '../../services/carousels.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss'],
})
export class BoardComponent {

  @Input() carousels: ICarousel[] = [];

  carouselType = CarouselTypeConstant;
  HOOK_ID = '59f78159-f1b4-410f-8a49-38e4bd5f18dd'; // home hook id

  constructor(
    public carouselsService: CarouselsService,
    private channels: ChannelsService,
  ) {
    this.channels.subscribe('readLater:update', () => this.profilationList());
  }

  ngOnInit() {
    this.carouselData();
    this.profilationList();
  }

  carouselData() {
    if (!this.carouselsService.carouselsData.value || !this.carouselsService.carouselsData.value.length) {
      this.carouselsService.carouselsByHook(this.HOOK_ID).subscribe((data) => {
        this.carouselsService.carouselsData.next(data);
      });
    }
  }

  profilationList() {
    this.carouselsService.carouselsProfilation().subscribe((data) => {
      const idxReadAgain = this.carouselsService.carouselsData.value.findIndex(carousel => carousel.api === 'readAgain');
      this.carouselsService.carouselsData.value[idxReadAgain].comics = [..._.cloneDeep(data['readAgain'])];
      const idxContinueRead = this.carouselsService.carouselsData.value.findIndex(carousel => carousel.api === 'continueRead');
      this.carouselsService.carouselsData.value[idxContinueRead].comics = [..._.cloneDeep(data['continueRead'])];
    });
  }

  trackCarousel(index: number, item: ICarousel) {
    return item.id;
  }

}
