import {Injectable} from '@angular/core';
import {AngularFireAnalytics} from '@angular/fire/compat/analytics';

export interface IFirebaseEvent {
  name: string;
  params: {
    value?: string;
    content_type?: string;
    content_id?: string;
    items?: { name: string, value: any }[],
  };
}

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  url: string;
  analyticsEnabled = true;

  constructor(
    private analytics: AngularFireAnalytics
  ) {
  }

  enableAnalytics(enable: boolean) {
    this.analytics.setAnalyticsCollectionEnabled(enable).then();
  }

  setUser(userId: string) {
    this.analytics.setUserId(userId).then()
  }

  setProperty(name: string, value: string) {
    this.analytics.setUserProperties({name: name, value: value}).then();
  }

  setScreenName(screenName: string, nameOverride: string) {
    this.analytics.setCurrentScreen(screenName).then();
  }

  logEvent(event: IFirebaseEvent) {
    this.analytics.logEvent('custom_event', event).then();
  }
}
