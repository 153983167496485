import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';
import {PaymentIntent} from '@stripe/stripe-js';

@Injectable({
  providedIn: 'root'
})
export class PaymentIntentService {

  url: string;

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.url = environment.apiUrl;
  }

  create(body): Observable<PaymentIntent> {
    return this.http.post<PaymentIntent>(this.url + 'v2/payment-intent', body, this.getOptions());
  }

  retrieve(id: string): Observable<PaymentIntent> {
    return this.http.get<PaymentIntent>(this.url + 'v2/payment-intent/' + id, this.getOptions());
  }


  getOptions() {
    return {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.access_token.value),
    };
  }
}
