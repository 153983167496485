<div class="comic-card text-14 mt-10 mb-40">
  <div class="row">
    <div class="col-12 small">

      <div class="badge-wrapper text-center" *ngIf="comic.originals">
        <img class="badge-icon" src="assets/images/badge-yellow.svg" alt="badge"/>
      </div>

      <div class="badge-chapter text-center" *ngIf="fromChapter">
        <app-chip *ngIf="isNew" [label]="'Nuovo su Jundo'"
                  [state]="'round warning-flat p-6 ml-6 mr-6 cursor-default uppercase fw-300 text-12'"></app-chip>
      </div>

      <a class="no-decoration white-text"
         [routerLink]="'/home'"
         [queryParams]="{comic: comic.id}">
        <img class="comic-card-img radius4" alt=""
             (load)="isImgLoaded = true"
             [src]="isImgLoaded ? ui.mediaCoverUrl(comic, true) : 'assets/images/sfondo.png'">
      </a>
      <h3 class="comic-card-title text-truncate text-15 fw-300 mb-0 pt-2">{{comic.title}}</h3>
    </div>

  </div>
</div>
