import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'permissionsLabel'
})
export class PermissionsLabelPipe implements PipeTransform {

  transform(permissions: any[], args?: any): any {
    let roles = [];
    permissions.forEach(role => {
      roles.push(role.label);
    });
    return roles.join(', ');
  }

}
