<h1 mat-dialog-title>Inserisci codice coupon</h1>
<div mat-dialog-content>

  <form [formGroup]="form" (ngSubmit)="confirm()" novalidate>
    <div class="row">
      <div class="col-12">
        <app-input [label]="'Inserisci coupon'"
                   [model]="form.controls['coupon']"
        ></app-input>
      </div>
    </div>
  </form>


</div>
<mat-dialog-actions align="end">
  <button class="btn btn-outline-jundo mr-20" [mat-dialog-close]="false">Annulla</button>
  <button class="btn btn-jundo mr-10" (click)="confirm()">Conferma</button>
</mat-dialog-actions>
