<div class="mt-40" *ngIf="carousel" [ngStyle]="carousel.hex ? {'background': (carousel.hex | carouselBackground)} : {}">

  <div class="title-box pt-20 ml-55 mb-0 no-select"
       [ngClass]="soon ? 'ml-0' : 'ml-55'">
    <img class="carousel-icon mr-20" *ngIf="carousel.filename" alt="" style="width: 120px"
         [src]="environment.media + carousel.original_path + carousel.filename"/>
    <span class="fw-600 text-22">{{carousel.title}}</span>
  </div>

  <swiper
    [cssMode]="false"
    [navigation]="true"
    [pagination]="false"
    [mousewheel]="false"
    [keyboard]="false"
    [initialSlide]="0"
    [slidesPerView]="'auto'"
    [spaceBetween]="16"
    [freeMode]="true"
  >
    <ng-template swiperSlide class="spacer-slider" *ngIf="firstSlide && !soon"></ng-template>
    <ng-template swiperSlide class="slide mt-20 mb-20 no-select" *ngFor="let comic of values;let i = index;">

      <div class="comic-card text-14"
           [ngClass]="i === 0 ? 'position-left' : (i === values.length - 1 ? 'position-right' : '')">

        <div class="row">
          <div class="col-12 small">

            <div class="badge-wrapper text-center">
              <img *ngIf="comic.originals" class="badge-icon" src="assets/images/badge-yellow.svg" alt="badge"/>
              <h3 class="comic-card-title text-truncate text-18 fw-700 mb-0 pt-2"
                  [ngClass]="comic.originals ? 'mt-0' : 'mt-35'">{{comic.title}}</h3>
            </div>

            <div class="badge-chapter text-center" *ngIf="fromChapter">
              <app-chip *ngIf="!isNew" [label]="comic.attachments | newChaptersTag:fromChapter"
                        [state]="'round dark p-6 ml-6 mr-6 cursor-default'"></app-chip>
              <app-chip *ngIf="isNew"
                        [label]="'Nuovo su Jundo'"
                        [state]="'round warning-flat p-6 ml-6 mr-6 cursor-default uppercase fw-300 text-12'"></app-chip>
            </div>

            <a class="no-decoration white-text position-relative"
               [routerLink]="'/home'"
               [queryParams]="{comic: comic.id}">
              <img class="comic-card-img" alt="" (load)="isImgLoaded = true"
                   [src]="isImgLoaded ? ui.mediaCoverUrl(comic, true) : 'assets/images/sfondo.png'">
            </a>

          </div>
        </div>
      </div>
    </ng-template>
    <ng-template swiperSlide class="spacer-slider"></ng-template>
    <ng-template swiperSlide class="spacer-slider"></ng-template>
  </swiper>
</div>
