import {Pipe, PipeTransform} from '@angular/core';
import {CoverTypeConstant} from '../constants/cover-type.constant';
import {IVariation} from '../interfaces/IVariation';

@Pipe({
  name: 'isVariantRegular'
})
export class IsVariantRegularPipe implements PipeTransform {

  protected coverTypeConstants = CoverTypeConstant;

  transform(variations: IVariation[], variantId: number): boolean {

    const prod = variations.find(variation =>
      variation.id === variantId && variation &&
      variation.attributes.length &&
      variation.attributes[0].option &&
      variation.attributes[0].option === this.coverTypeConstants.CLASSIC
    );

    return !!prod

  }

}
