import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'map'
})
export class MapPipe implements PipeTransform {
  transform(values: any[], attribute: string): any {
    return _.map(values, attribute);
  }
}
