import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';
import {IAttribute} from '../interfaces/IAttribute';
import {ItemCategory} from 'ngx-paypal';
import {ICategory} from '../interfaces/ICategory';

@Pipe({
  name: 'category'
})
export class CategoryPipe implements PipeTransform {

  transform(categories: ICategory[], args: string): any {
    if (args && categories && categories.length) {
      const item = categories.find(data => _.lowerCase(data.name) === _.lowerCase(args));
      if (item) {
        return item;
      }
    }
    return null;
  }
}
