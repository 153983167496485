<mat-form-field appearance="outline">
  <mat-label class="neutral-grey-text">{{label}}</mat-label>
  <input class="c-input"
         [ngClass]="isCoupon || uppercase ? 'uppercase-input' : ''"
         matInput
         #searcher
         [type]="!toggleIcon ? type : 'text'"
         [formControl]="model"
         [autofocus]="autofocus"
         [disabled]="disabled"
         (blur)="onBlur.emit()"
         [placeholder]="placeholder">

  <!--<button *ngIf="model.value" mat-icon-button matSuffix type="button" (click)="model.setValue(null)">
    <img class="white-text jicon" src="assets/icon/jundo/close-icon.svg" alt="">
  </button>-->
  <button *ngIf="isFilter" mat-icon-button matSuffix type="button" (click)="openFilter()">
    <img height="30" class="jicon" src="assets/icon/filter-active.svg" alt="">
  </button>
  <button mat-icon-button matPrefix type="button" *ngIf="isSearch">
    <img class="white-text jicon" src="assets/icon/jundo/search-icon.svg" alt="">
  </button>
  <button mat-icon-button matSuffix type="button" *ngIf="isCoupon" (click)="confirmCoupon()">
    <img *ngIf="!loader" class="white-text jicon" src="assets/icon/jundo/arrow-right-active.svg" alt="">
    <app-loader *ngIf="loader" [small]="true"></app-loader>
  </button>

  <button *ngIf="isPassword" mat-icon-button matSuffix type="button" (click)="togglePassword()">
    <img *ngIf="type === 'text'" height="30" class="jicon" src="assets/icon/jundo/eye.svg" alt="">
    <img *ngIf="type === 'password'" height="30" class="jicon" src="assets/icon/jundo/pwd.svg" alt="">
  </button>

  <mat-hint class="yellow-text" *ngIf="hint">{{hint}}</mat-hint>
  <mat-error *ngIf="model.hasError('email') && !model.hasError('required')">
    Campo <strong>{{label}}</strong> non valido
  </mat-error>
  <mat-error *ngIf="model.hasError('required')">
    Campo <strong>{{label}}</strong> obbligatorio
  </mat-error>
  <mat-error *ngIf="model.hasError('pattern') && !model.hasError('email')">
    Campo <strong>{{label}}</strong> non valido
  </mat-error>

</mat-form-field>
