import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  title: string;
  message: string;
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {

  title: string = '';
  message: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    if(data){
      this.title = data.title;
      this.message = data.message;
    }
  }

}
