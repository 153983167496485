import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-preview-box',
  templateUrl: './preview-box.component.html',
  styleUrls: ['./preview-box.component.scss'],
})
export class PreviewBoxComponent {

  @Output('on-edit') onEdit: EventEmitter<void> = new EventEmitter<void>();
  @Output('on-open') onOpen: EventEmitter<void> = new EventEmitter<void>();

  @Input() width: string = '100%';
  @Input() title: string;
  @Input() messages: any[] = [];
  @Input() iconUrl: string;
  @Input() editable: boolean = false;
  @Input() openable: boolean = false;

  constructor(
    public auth: AuthService
  ) {
  }

}
