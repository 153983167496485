import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState, getFilters, getFiltersShop} from '../reducers';
import {IFilters} from "../interfaces/IFilters";
import * as FiltersActions from "../actions/filters.actions";

@Injectable({
    providedIn: 'root'
})
export class FiltersService {

    public filters: Observable<IFilters>;
    public filtersShop: Observable<IFilters>;

    constructor(
        private store: Store<AppState>,
    ) {
        this.filters = this.store.select(getFilters);
        this.filtersShop = this.store.select(getFiltersShop);
    }

    updateFilters(item): void {
        this.store.dispatch(new FiltersActions.UpdateFilters({item}));
    }

    updateFiltersShop(item): void {
        this.store.dispatch(new FiltersActions.UpdateFiltersShop({item}));
    }

    getFilters(): Observable<IFilters> {
        return this.store.select(getFilters);
    }

    getFiltersShop(): Observable<IFilters> {
        return this.store.select(getFiltersShop);
    }

    initializeFilters(): void {
        this.store.dispatch(new FiltersActions.InitializeFilters({}));
        this.store.dispatch(new FiltersActions.InitializeFiltersShop({}));
    }


}
