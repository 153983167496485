import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';
import {IAuthor} from '../interfaces/IAuthor';
import {PermissionsConstant} from '../constants/permissions.constant';

@Pipe({
  name: 'roleLabel'
})
export class RoleLabelPipe implements PipeTransform {

  transform(author: IAuthor, args?: any): any {
    const filtered = _.filter(author.permissions, (perm) => perm.permission_code !== PermissionsConstant.USER.permission_code && perm.permission_code !== PermissionsConstant.AUTHOR.permission_code);
    return _.map(filtered, 'label').join(', ');
  }

}
