import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';
import {IAttribute} from "../interfaces/IAttribute";

@Pipe({
  name: 'carouselBackground'
})
export class CarouselBgPipe implements PipeTransform {

  transform(hex: string, args?: string): any {
    if (hex) {
      return this.getLinearGradient(hex);
    }
    return null;
  }

  getLinearGradient(hex: string): string {
    const val = this.toRGBA(hex);
    return 'linear-gradient(118.85deg, rgba(' + val.toString() + ', 0.46) 0%, rgba(20, 20, 19, 0) 101.7%)';
  }

  toRGBA(cssColor) {
    let el = document.createElement('div');
    el.style.color = cssColor;
    el.style.display = 'none';
    document.body.appendChild(el);
    let rgba = window.getComputedStyle(el).getPropertyValue('color');
    el.remove();
    let [r, g, b, a] = rgba.match(/[0-9.]+/g).map(n => Number(n));
    if (a === undefined) {
      a = 1;
    } // <-- getPropertyValue returns rgb(...) if there is no transparency, so we add alpha if missing
    return [r, g, b]; // [r, g, b, a]
  }

}
