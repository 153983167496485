<footer class="black" *ngIf="!hide">
  <div class="container">
    <div class="row">
      <div class="col-md-8 box">
        <img alt="Jundo" src="assets/images/logo-compact.svg" [routerLink]="'/'"/>
        <span class="quick-links mb-0 ml-20 mr-5">
          <a class="no-decoration" target="_blank" href="https://www.iubenda.com/termini-e-condizioni/93467398">Termini & Condizioni</a>
        </span>
        <span class="spacer">&nbsp;•&nbsp;</span>
        <span class="quick-links mb-0 ml-5 mr-5">
          <a class="no-decoration" target="_blank" href="https://www.iubenda.com/privacy-policy/93467398/full-legal">Privacy Policy</a>
        </span>
        <span class="spacer">&nbsp;•&nbsp;</span>
        <span class="quick-links mb-0 ml-5 mr-5">
          <a class="no-decoration" target="_blank" href="https://www.iubenda.com/privacy-policy/93467398/cookie-policy">Cookies Policy</a>
        </span>
        <span class="spacer">&nbsp;•&nbsp;</span>
        <span class="quick-links mb-0 ml-5 mr-5">
          <a class="no-decoration" [routerLink]="'sitemap'">Mappa opere</a>
        </span>
        <span class="spacer">&nbsp;•&nbsp;</span>
        <span class="quick-links mb-0 ml-5 mr-5">
          <a class="no-decoration" target="_blank"
             href="https://play.google.com/store/apps/details?id=it.jundo.app&hl=it&gl=US">App Android</a>
        </span>
        <span class="spacer">&nbsp;•&nbsp;</span>
        <span class="quick-links mb-0 ml-5 mr-5">
          <a class="no-decoration" target="_blank" href="https://apps.apple.com/it/app/jundo/id1545759268">App iOS</a>
        </span>
        <span class="spacer">&nbsp;•&nbsp;</span>
        <span class="quick-links mb-0 ml-5 mr-5">
          <a class="no-decoration" target="_blank" href="https://accampamento.jundo.it/faq.php">FAQ</a>
        </span>
      </div>
      <div class="col-md-4 box text-right">
        <ul class="list-inline quick-links">
          <li class="list-inline-item">
            <span class="quick-links">© {{year}} Jundo SRL</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
