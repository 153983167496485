<div class="product-card text-14 mt-10 mb-40 auto-width" [ngClass]="[(product | isMerchandising) ? 'shop' : '']">
  <div class="row">
    <div class="col-12 small">

      <div class="badge-wrapper text-center" *ngIf="product | isOriginal">
        <img class="badge-icon" src="assets/images/badge-yellow.svg" alt="badge"/>
      </div>

      <div class="cover-box" [ngClass]="[(product | isMerchandising) ? 'shop' : '']" (click)="openProduct(product)">
        <img class="comic-cover"
             [ngClass]="[(product | isMerchandising) ? 'shop' : '']"
             [src]="ui.productCoverUrl(product)"
             [alt]="product.name"/>
        <div class="marker">
          <img *ngIf="(product | isOriginal) && !(product | isPreorder)"
               src="assets/images/shop/badge-yellow.svg" alt=""/>
        </div>
        <div class="variant-marker yellow"
             *ngIf="product.variations.length && !(product | isMerchandising)">
          <p class="black-text bolder m-0 pt-4 pt-2 text-center text-14">Variant disponibile</p>
        </div>
      </div>
      <h3 class="mt-10 mb-0 text-20 bolder">{{product.price | number : '1.2-2'}}€</h3>
      <p class="mt-2 mb-0 text-15 fw-300 text-truncate">{{product.name}}</p>
    </div>

  </div>
</div>
