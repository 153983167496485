import {Action} from '@ngrx/store';
import {ICart} from "../interfaces/ICart";

export enum ActionTypes {
    AddShoppingItem = '[Shopping Item Service] Add item',
    UpdateShoppingItem = '[Shopping Item Service] Update item',
    UpdateQuantityShoppingItem = '[Shopping Item Service] Update quantity item',
    DeleteShoppingItem = '[Shopping Item Service] Delete item',
    InitializeCart = '[Shopping Item Service] Initialize cart',
    ResetCart = '[Shopping Item Service] Reset cart'
}

export class AddShoppingItem implements Action {
    readonly type = ActionTypes.AddShoppingItem;

    constructor(public payload: { item: ICart }) {
    }
}

export class UpdateShoppingItem implements Action {
    readonly type = ActionTypes.UpdateShoppingItem;

    constructor(public payload: { item: ICart }) {
    }
}

export class UpdateQuantityShoppingItem implements Action {
    readonly type = ActionTypes.UpdateQuantityShoppingItem;

    constructor(public payload: { item: ICart, type: string }) {
    }
}

export class DeleteShoppingItem implements Action {
    readonly type = ActionTypes.DeleteShoppingItem;

    constructor(public payload: { item: ICart }) {
    }
}

export class InitializeCart implements Action {
    readonly type = ActionTypes.InitializeCart;

    constructor(public payload: {}) {
    }
}

export class ResetCart implements Action {
    readonly type = ActionTypes.ResetCart;

    constructor(public payload: {}) {
    }
}


export type ActionsUnion =
    AddShoppingItem |
    DeleteShoppingItem |
    UpdateShoppingItem |
    UpdateQuantityShoppingItem |
    InitializeCart |
    ResetCart;
