import {Pipe, PipeTransform} from '@angular/core';
import {IMedia} from '../interfaces/IMedia';
import {environment} from '../../environments/environment';
import * as moment from 'moment/moment';
import {IChapter} from '../interfaces/IChapter';

@Pipe({
  name: 'chapterMedia'
})
export class ChapterMediaPipe implements PipeTransform {
  constructor() {
  }

  transform(chapter: IChapter, admin = false): any {

    let isNew = false;

    if (chapter && chapter.published_at && !admin) {
      isNew = moment(chapter.published_at).diff(moment()) >= 0;
    }

    if (chapter && chapter.media && !isNew) {
      return environment.media + chapter.media.original_path + chapter.media.filename;
    }
    return environment.media + 'images/cover-preview.png';
  }

}
